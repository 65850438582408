import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-report-comments',
  templateUrl: './report-comments.component.html',
  styleUrls: ['./report-comments.component.css']
})
export class ReportCommentsComponent {
  public _comments: any[] = [];
  @Input() set comments(comments: any) {
    this._comments = comments;
  }
  @Output() onOpenImage = new EventEmitter();
  get comments() {
    return this._comments;
  }
  getUserTime(date: string) {
    var m1 = moment(date);
    return m1.fromNow();
  }
  doOpenImage(imagePath: string) { 
    this.onOpenImage.emit(imagePath)
  }
}
