import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ClientService } from '../../../services/client.service';
import { MultiSelectComponent } from '../../../shared/multi-select/multi-select.component';
import { getConverted12HourTime } from '../../../Utility/common-functions';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { cptdetails } from '../cpt-details';
import { CptDetailsService } from '../cpt-details.service';

@Component({
  selector: 'app-cpt-details-details',
  templateUrl: './cpt-details-details.component.html',
  styleUrls: ['./cpt-details-details.component.css']
})
export class CptDetailsDetailsComponent {

  public _cptdetails!: cptdetails;
  _lookupData: any = {};
  public selectedTab!: number;
  public _isLoading: boolean = false;
  public isEdit: boolean = false;
  public hasCPT_Edit: boolean = false;
  public _isFromInfo: boolean = false;
  public selectedClients: any[] = [];
  public selectedProviders: any[] = [];
  public _orgLookupData: any;
  public _filters: any = { practiceIds: [], owners: [], actions: [] };
  public ALL_CURRENCY_REGEXP = /^-?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})*(?:\.[0-9]{1})?$/;
  public ALL_NUMBER_REGEXP = /^[0-9]/;

  @ViewChild('clients') _client!: MultiSelectComponent;
  @ViewChild('cptTypes') _cptTypes!: MultiSelectComponent;

  formAdd: FormGroup = new FormGroup({
    cpt: new FormControl('', [Validators.required]),
    defaultvalue: new FormControl('', [Validators.required]),
    effectivestartdate: new FormControl('', [Validators.required]),
    effectiveenddate: new FormControl('', [Validators.required]),
    isActive: new FormControl(true),
    defaultamount: new FormControl('', [Validators.required]),
    modifiers: new FormControl('', [Validators.required]),
  });
  actionmasterService: any;

  @Input() set cptdetails(cptdetails: cptdetails) {
    if (cptdetails.cptdefaultvalueid != "- 1") {
      this.isEdit = true;
      console.log(this.isEdit)
    }
    else {
      this.isEdit = false
    }
    this._cptdetails = cptdetails;
    console.log(this._cptdetails)
    if (cptdetails?.cptdefaultvalueid)
      this.getCPTDetails();
  }

  @Input() set lookupData(lookupData: any) {
    this._lookupData = lookupData;
    this._orgLookupData = lookupData;
    if (this._cptdetails?.cptdefaultvalueid)
      this.getCPTDetails();
  }

  get action() {
    return this._cptdetails;
  }

  ngOnInit(): void {
    this.setPrivileges();

  }
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit(false);
  }
  constructor(private dialog: MatDialog, private cptdetailsservice: CptDetailsService, private auth: AuthService, private clientService: ClientService) { }
  @Output() doNextEdit = new EventEmitter();

  async doSave(type: string) {

    if (this.formAdd.valid) {
      let selectitems: any = this.filtervalue();
      if (selectitems.practiceIds.length > 0) {

        let CPTDetailsNew: any = {
          cptdefaultvalueid: this.action?.cptdefaultvalueid ?? -1,
          cpt: this.formAdd.controls.cpt.value ?? '',
          defaultvalue: this.formAdd.controls.defaultvalue.value?.toString() ?? '',
          modifiers: this.formAdd.controls.modifiers.value?.toString() ?? '',
          DefaultAmount: this.formAdd.controls.defaultamount.value?.toString() ?? '',
          cpttypeid: this._cptTypes.selectedItems[0]?.code?.toString() ?? '-1',
          effectivestartdate: this.formAdd.controls.effectivestartdate.value ?? '',
          effectiveenddate: this.formAdd.controls.effectiveenddate.value ?? '',
          uniquepracticeid: selectitems.practiceIds[0]?.code?.toString() ?? '-1',
          isactive: (this.action?.cptdefaultvalueid ?? "-1") == "-1" ? "1" : this.formAdd.controls.isActive.value ? 1 : 0,
          userid: this.auth.currentUser.username
        };



        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.title = 'Information!';
        data.showYes = true;
        data.noText = 'NO';
        data.yesText = 'YES';
        let update = true;
        if (CPTDetailsNew.isactive == 0 && this.action.isactive != CPTDetailsNew.isactive) {
          data.message = 'Are you sure you want to in active this action?';
          update = await Confirmation(this.dialog, data);
        }

        if (update) {
          this.onShowLoading.emit(true);
          this.cptdetailsservice.setCptDetails([CPTDetailsNew]).then(res => {
            if (res.length > 0) {
              //debugger;
              this.cptdetails = res as cptdetails;
              data.title = 'Information!';
              data.showYes = false;
              data.noText = 'OK';
              data.message = res[0].errormessage;
              //await Confirmation(this.dialog, data);
              Confirmation(this.dialog, data);
              //this.onUpdateEdit.emit();
              if (res.result != 0) {
                this.onCancelEdit.emit(true);
              }
              this._isLoading = false;
              this.onShowLoading.emit(false);

            }
          });
        }
        else {
          this.formAdd.markAllAsTouched();
          return;
        }
      }
    }
    else {
      this.formAdd.markAllAsTouched();
    }
  }

  getPractices() {

    this.clientService.getlookup([]).then(data => {

    });

  }



  getCPTDetails() {

    //debugger;
    console.log(this._cptdetails)
    this.formAdd.controls.cpt.setValue(this._cptdetails.cpt);
    this.formAdd.controls.defaultvalue.setValue(this._cptdetails.defaultvalue);
    this.formAdd.controls.effectivestartdate.setValue(this._cptdetails.effectivestartdate);
    this.formAdd.controls.effectiveenddate.setValue(this._cptdetails.effectiveenddate);
    this.formAdd.controls.isActive.setValue(this._cptdetails.isactive);
    this.formAdd.controls.defaultamount.setValue(this._cptdetails.defaultamount);
    this.formAdd.controls.modifiers.setValue(this._cptdetails.modifiers);

    this.selectedClients = this._lookupData.clients?.filter((w: any) => { return w.code == this._cptdetails.uniquepracticeid });
    //this.selectedCptTypes = this._lookupData.clients?.filter((w: any) => { return w.code == this._cptdetails.uniquepracticeid });    

  }
  @Output() onShowLoading = new EventEmitter();

  filtervalue() {
    return {
      practiceIds: (this._client) ? this._client.selectedItems : [],
    };
  }
  get filters() {
    return this._filters;
  }

  onSelectionChange(item: any) {
    let selectedItems = (this._cptdetails && item) ? item[0]?.code : '';
    //if (item.length > 0) {
    //  this._lookupData.serviceProviderFiltered = this._orgLookupData.serviceProvider.filter((w: any) => { return selectedItems == w.clientid })
    //}

  }


  allowNumberOnly(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0
    if (!this.ALL_CURRENCY_REGEXP.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  allowAmountOnly(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0;
    let newvalue = value.substring(0, event.currentTarget.selectionStart) + inputChar + value.substring(event.currentTarget.selectionStart);
    if ((inputChar == "-" && value.indexOf('-') > -1) ||
      (inputChar == "." && value.indexOf('.') > -1)) {
      // invalid character, prevent input
      event.preventDefault();
    }
    else if (inputChar == "-" || inputChar == ".") {

    }
    else if (!this.ALL_CURRENCY_REGEXP.test(newvalue)) {
      event.preventDefault();
    }
  }

  onInputChange(event: any, type: number = 0) {
    //
    let value = "";
    if (type == 0) {
      value = event.currentTarget.value
      if (!this.ALL_CURRENCY_REGEXP.test(value)) {
        value = "";
      }
    }
    else {
      value = event.currentTarget.value
      if (!this.ALL_NUMBER_REGEXP.test(value)) {
        value = "";
      }
    }

    event.currentTarget.value = value;
  }


  setPrivileges() {
    this.hasCPT_Edit = this.auth.hasPrivilege(PrivilegeValues.CPT_Edit);
  }

}
