import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {
  otherItems = ['Item 4', 'Item 5', 'Item 6'];
  drop(event: CdkDragDrop<string[]>) { // handle the drop event
    if (event.previousContainer === event.container) {
      // Handle moving within the same list
    } else {
      //debugger;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
