import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { LookupEntitySelect } from '../../../entities/lookup-entity';
import { Client } from '../../client-management/clientinfo';
import * as custom from '../../../Utility/CustomValidators.utilities'
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { MaturityCurve } from '../maturitycurveinfo';

@Component({
  selector: 'app-maturity-curve-edit',
  templateUrl: './maturity-curve-edit.component.html',
  styleUrls: ['./maturity-curve-edit.component.css']
})
export class MaturityCurveEditComponent {
  public _clientList: any[] = [];
  selectedPractice: any[] = [];
  waiting: boolean = false;
  numberOfMonths: number = 0;
  numberOfRows: any[] = [];
  formAdd!: FormGroup;
  displayedColumns: string[] = ['monthname', 'inputvalue'];
  totalPercentage: number = 0;
  @Output() formDataEmitter = new EventEmitter<any>();

  currentDate = new Date();
  minStartDate: Date = this.currentDate;
  minEndDate: Date = this.currentDate;
  minDateTypeEndDate: Date = this.currentDate;

  @ViewChild('practice') _practice!: any;
  @Input() set clientList(clients: any[]) {
    this._clientList = clients;
  }
  get clientList() {
    return this._clientList;
  }

  public _maturityCurve!: any[];
  @Input() set maturityCurve(maturityCurve: any[]) {
    this._maturityCurve = maturityCurve;
    this.rebindForm();
  }
  get maturityCurve() {
    return this._maturityCurve;
  }

  constructor(private auth: AuthService) {
    this.formAdd = new FormGroup({
      hiddenId: new FormControl('-1'),
      practice: new FormControl(this.selectedPractice),
      numberOfMonthsInput: new FormControl(this.numberOfMonths),
      dateTypeStartDate: new FormControl(this.currentDate, [custom.validateDate]),
      dateTypeEndDate: new FormControl(this.currentDate, [custom.validateDate])
    });
  }

  // form
  ngOnInit() {
    //this.formAdd = new FormGroup({
    //  hiddenId: new FormControl('-1'),
    //  practice: new FormControl(this.selectedPractice),
    //  numberOfMonthsInput: new FormControl(this.numberOfMonths),
    //  dateTypeStartDate: new FormControl(this.currentDate, [custom.validateDate]),
    //  dateTypeEndDate: new FormControl(this.currentDate, [custom.validateDate])
    //});
  }
  

  onSelectionChange(item: any) {
    try {
      // todo comes here
    } catch (exe) {
      console.log(exe);
    }
  }

  onSaveMaturityForm() {
    let P_Id = this.formAdd.controls.hiddenId.value;

    //this._practice.selectedItems
    let P_Code = this._practice.selectedItems[0].code;
    let P_SelectedClient = this.clientList.filter((obj: any) => {
      return (obj.code == P_Code)
    })

    //"monthId": "TotalPercentage"
    let P_SaveObj = [
      {
        "physicianmonthlypercentagerateid": P_Id,
        "practiceid": P_SelectedClient[0].practiceid,
        "warehouseuniqueidentifier": P_SelectedClient[0].warehouseuniqueidentifier,
        "uniquepracticeid": P_Code,
        "month1": (this.numberOfRows[0] != null && this.numberOfRows[0].monthId != "TotalPercentage") ? this.numberOfRows[0].inputValue : "0",
        "month2": (this.numberOfRows[1] != null && this.numberOfRows[1].monthId != "TotalPercentage") ? this.numberOfRows[1].inputValue : "0",
        "month3": (this.numberOfRows[2] != null && this.numberOfRows[2].monthId != "TotalPercentage") ? this.numberOfRows[2].inputValue : "0",
        "month4": (this.numberOfRows[3] != null && this.numberOfRows[3].monthId != "TotalPercentage") ? this.numberOfRows[3].inputValue : "0",
        "month5": (this.numberOfRows[4] != null && this.numberOfRows[4].monthId != "TotalPercentage") ? this.numberOfRows[4].inputValue : "0",
        "month6": (this.numberOfRows[5] != null && this.numberOfRows[5].monthId != "TotalPercentage") ? this.numberOfRows[5].inputValue : "0",
        "month7": (this.numberOfRows[6] != null && this.numberOfRows[6].monthId != "TotalPercentage") ? this.numberOfRows[6].inputValue : "0",
        "month8": (this.numberOfRows[7] != null && this.numberOfRows[7].monthId != "TotalPercentage") ? this.numberOfRows[7].inputValue : "0",
        "month9": (this.numberOfRows[8] != null && this.numberOfRows[8].monthId != "TotalPercentage") ? this.numberOfRows[8].inputValue : "0",
        "month10": (this.numberOfRows[9] != null && this.numberOfRows[9].monthId != "TotalPercentage") ? this.numberOfRows[9].inputValue : "0",
        "month11": (this.numberOfRows[10] != null && this.numberOfRows[10].monthId != "TotalPercentage") ? this.numberOfRows[10].inputValue : "0",
        "month12": (this.numberOfRows[11] != null && this.numberOfRows[11].monthId != "TotalPercentage") ? this.numberOfRows[11].inputValue : "0",
        "effectivestartdate": moment(this.formAdd.controls.dateTypeStartDate.value).format("YYYY-MM-DDTHH:mm:ssZ"),
        "effectiveenddate": moment(this.formAdd.controls.dateTypeEndDate.value).format("YYYY-MM-DDTHH:mm:ssZ"),
        "active": "1",
        "createdon": (P_Id == "-1") ? moment().format("YYYY-MM-DDTHH:mm:ssZ") : "",
        "createdby": (P_Id == "-1") ? this.auth.currentUser.username : "",
        "modifiedon": moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        "modifiedby": this.auth.currentUser.username
      }
    ];

    console.log("P_SaveObj: ", P_SaveObj);
    this.formDataEmitter.emit(P_SaveObj);
  }

  canSave() {
    let P_Flag = false;
    if (this.totalPercentage == 100) {
      P_Flag = true;
    }
    return P_Flag;
  }

  doReset() {
    //this.formAdd.controls.eventId.setValue("-1");
    this.numberOfMonths = 0;
    this.selectedPractice = [];
    this.numberOfRows = [];
    //this.formAdd.controls.practice.setValue([]);
    this.formAdd.controls.hiddenId.setValue("-1");
    this.formAdd.controls.numberOfMonthsInput.setValue(0);
    this.formAdd.controls.dateTypeStartDate.setValue("");
    this.formAdd.controls.dateTypeEndDate.setValue("");
  }

  addRows() {
    //this.numberOfRows = [];
    this.numberOfMonths = this.formAdd?.get('numberOfMonthsInput')?.value;

    if (this.numberOfMonths < 0) {
      this.formAdd.controls.numberOfMonthsInput.setValue(0);
      this.numberOfMonths = 0;
    } else if (this.numberOfMonths > 12) {
      this.formAdd.controls.numberOfMonthsInput.setValue(12);
      this.numberOfMonths = 12;
    } 

    let P_AddTotalPercentage = false;
    if (this.numberOfRows.length == 0) {
      P_AddTotalPercentage = true;
    }

    if (this.numberOfRows.length > 0 && this.numberOfRows.length > this.numberOfMonths) {
      // Keep only the top 3 objects
      this.numberOfRows = this.numberOfRows.slice(0, this.numberOfMonths);
      P_AddTotalPercentage = true;
    } else {
      this.numberOfRows = this.numberOfRows.slice(0, (this.numberOfRows.length - 1));
      P_AddTotalPercentage = true;
    }

    for (let i = this.numberOfRows.length; i < this.numberOfMonths; i++) {
      this.numberOfRows.push({
        "monthName": "Month " + (i + 1),
        "inputValue": "0",
        "monthId": "Month" + (i + 1)
      })
    }

    if (P_AddTotalPercentage && (this.numberOfMonths != null && this.numberOfMonths != 0)) {
      this.numberOfRows.push({
        "monthName": "Total",
        "inputValue": "0",
        "monthId": "TotalPercentage"
      });
      this.addTotalPercentage();
    }
  }

  removeRow(P_MonthId: string) {
    this.numberOfRows = this.numberOfRows.filter((obj) => {
      obj.monthId != P_MonthId;
    })
  }

  calculateTotalPercentage(event: any) {
    const input = event.target as HTMLInputElement;
    let P_InputId = input.id;
    let P_InputValue = input.value;
    this.setValueById(this.numberOfRows, P_InputId, P_InputValue);
    this.addTotalPercentage();
  }

  setValueById(items: any, monthId: string, newInputValue: any) {
    const index = items.findIndex((item: any) => item.monthId === monthId); if (index !== -1) { items[index].inputValue = newInputValue; }
  }

  addTotalPercentage() {
    this.totalPercentage = 0;
    for (let i = 0; i < this.numberOfRows.length; i++) {
      if (this.numberOfRows[i].monthId != "TotalPercentage") {
        this.totalPercentage += Number(this.numberOfRows[i].inputValue);
      }
    }
    this.setValueById(this.numberOfRows, "TotalPercentage", this.totalPercentage);
  }

  onDateTypeSTRDTChange() {
    let P_newStartDate = this.formAdd.controls.dateTypeStartDate.value;
    this.formAdd.controls.dateTypeEndDate.setValue(P_newStartDate);
    this.minDateTypeEndDate = new Date(P_newStartDate);
  }

  rebindForm() {
    console.log("maturityCurve: ", this.maturityCurve);

    if (this.maturityCurve.length > 0) {
      this.selectedPractice = this.clientList.filter((obj: any) => {
        return (obj.code == this.maturityCurve[0].uniquepracticeid);
      });

      let P_effectivestartdate = new Date(this.maturityCurve[0].effectivestartdate.split("T")[0]);
      let P_effectiveenddate = new Date(this.maturityCurve[0].effectiveenddate.split("T")[0]);
      console.log("P_effectiveenddate: ", P_effectiveenddate);
      console.log("this.maturityCurve[0].effectiveenddate: ", this.maturityCurve[0].effectiveenddate);

      if (this.maturityCurve[0].month12 != 0) {
        this.numberOfMonths = 12;
      } else if (this.maturityCurve[0].month11 != 0) {
        this.numberOfMonths = 11;
      } else if (this.maturityCurve[0].month10 != 0) {
        this.numberOfMonths = 10;
      } else if (this.maturityCurve[0].month9 != 0) {
        this.numberOfMonths = 9;
      } else if (this.maturityCurve[0].month8 != 0) {
        this.numberOfMonths = 8;
      } else if (this.maturityCurve[0].month7 != 0) {
        this.numberOfMonths = 7;
      } else if (this.maturityCurve[0].month6 != 0) {
        this.numberOfMonths = 6;
      } else if (this.maturityCurve[0].month5 != 0) {
        this.numberOfMonths = 5;
      } else if (this.maturityCurve[0].month4 != 0) {
        this.numberOfMonths = 4;
      } else if (this.maturityCurve[0].month3 != 0) {
        this.numberOfMonths = 3;
      } else if (this.maturityCurve[0].month2 != 0) {
        this.numberOfMonths = 2;
      } else if (this.maturityCurve[0].month1 != 0) {
        this.numberOfMonths = 1;
      }

      let P_TotalPercentage = 0;
      for (let i = 0; i < this.numberOfMonths; i++) {
        this.numberOfRows.push({
          "monthName": "Month " + (i + 1),
          "inputValue": this.maturityCurve[0]["month" + (i + 1)],
          "monthId": "Month" + (i + 1)
        });

        P_TotalPercentage += this.maturityCurve[0]["month" + (i + 1)];
      }

      this.numberOfRows.push({
        "monthName": "Total",
        "inputValue": P_TotalPercentage,
        "monthId": "TotalPercentage"
      });
      this.addTotalPercentage();

      this.formAdd.controls.hiddenId.setValue(this.maturityCurve[0].physicianmonthlypercentagerateid);
      this.formAdd.controls.numberOfMonthsInput.setValue(this.numberOfMonths);
      this.formAdd.controls.dateTypeStartDate.setValue(P_effectivestartdate);
      this.formAdd.controls.dateTypeEndDate.setValue(P_effectiveenddate);
    }
  }
}
