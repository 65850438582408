import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { CreateCalendarRules, getMonthName, getWeekName } from '../../../../Utility/common-functions';

@Component({
  selector: 'app-payroll-start',
  templateUrl: './payroll-start.component.html',
  styleUrls: ['./payroll-start.component.css']
})
export class PayrollStartComponent {
  public _lookupData: any;
  @Input() set data(data: any) {
    this._lookupData = data;    
    this.calculateValues();
  }

  @ViewChild('runAs') runAs!: MultiSelectComponent;
  get data() {
    return this._lookupData;
  }
  formAdd: FormGroup = new FormGroup({
    scheduleName: new FormControl(''),
    scheduleDetails: new FormControl(''),
    scheduleStartDate: new FormControl(''),
    scheduleStartTime: new FormControl(''),
    scheduleEndDate: new FormControl(''),
    scheduleEndTime: new FormControl(''),
    otherOptions: new FormControl(''),
    scheduleStatus: new FormControl(true)
  });
  @Output() onCancel = new EventEmitter();
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: this.timeZone, // Use the local time zone
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
  constructor(
    private datePipe: DatePipe,
  ) {

  }
  onSaveConnector() {
    var P_EndDate: any = new Date(this.formAdd.controls.scheduleEndDate.value).toLocaleDateString();
    let P_scheduleStartDate = this.formAdd.controls.scheduleStartDate.value;
    let P_scheduleEndDate = this.formAdd.controls.scheduleEndDate.value;
    let localDate = new Date(P_scheduleEndDate);
    let endDate = this.formatter.format(localDate);
    let startDate = this.formatter.format(localDate);
    let istStartDateString: any = startDate + " " + this.formAdd.controls.scheduleStartTime.value;
    let istEndDateString: any = endDate + " " + this.formAdd.controls.scheduleEndTime.value;

    istStartDateString = this.datePipe.transform(istStartDateString, "yyyy-MM-ddTHH:mm:ss.SSSZ", "UTC");
    istEndDateString = this.datePipe.transform(istEndDateString, "yyyy-MM-ddTHH:mm:ss.SSSZ", "UTC");
    if (this.isvalid()) {
      let item = {
        EventId: this.data.node.data.filters.eventId ?? -1,
        ScheduleInstanceId: this.data.node.data.filters.eventId ?? -1,
        CreatedById: -1,
        ScheduleTypeId: 4,
        EmailProfileId: 0,
        ScheduleName: this.formAdd.controls.scheduleName.value,
        ScheduleDetails: this.formAdd.controls.scheduleDetails.value,
        IsActive: (this.formAdd.controls.scheduleStatus.value) ? 1 : 0,
        StartTime: istStartDateString,
        EndTime: istEndDateString,
        OccurenceRule: CreateCalendarRules(this.formAdd.controls.otherOptions.value, false, this.futureDate, P_EndDate),
        RunAlways: false,
        ScheduleDescription: [],
      }
      this.onCancel.emit(item);
    }
  }
  doReset() {
    //this.formAdd.controls.tablename.setValue('');
  }
  doCancelEdit() {
    this.doReset();
    this.onCancel.emit(false);
  }
  castToFloat(item: any, control: string, type: string) {
    let val = parseFloat(this.formAdd.controls[control].value);
    if (type == 'int') {
      val = parseInt(this.formAdd.controls[control].value);
    }

    if (isNaN(val)) {
      this.formAdd.controls[control].setValue('0');
    }
    else if (val > 100) {
      this.formAdd.controls[control].setValue('0');
    }
    else {
      this.formAdd.controls[control].setValue(val);
    }
  }
  isRequired(item: string) {
    return this.data.node.data.inputdata[item] == 1;
  }
  canEdit(item: string) {
    return this.data.node.data.inputdata[item] == 1 || this.data.node.data.inputdata[item] == 0;
  }
  isvalid() {
    this.formAdd.markAllAsTouched();
    let inputdata = this.data.node.data.inputdata;
    let result = true;
    Object.keys(inputdata).forEach(s => {
      if (!this.isValidInput(s)) {
        result = false;
      }
    });
    return result;
  }
  isValidInput(input: string) {



    return true;
  }
  calculateValues() {
    if (this.data && this.data?.node?.data?.filters) {
      let filters = this.data?.node?.data?.filters;
      let lookups = this.data?.lookupData;
      this.data.node.data.filters.runAs = [this.data?.lookupData.runAs[1]];
      if (filters.OccurenceRule == "") {
        this.data.node.data.filters.runAs = [this.data?.lookupData.runAs[0]];
      }
    }
  }

  onSelectionChange(item: any) {
    this.data.node.data.filters.runAs = item ?? []
  }
  currentDate = new Date();
  minutesToAdd = 10; // create a future date
  futureDate = new Date(this.currentDate.getTime() + this.minutesToAdd * 60000);
  weekDayName: string = getWeekName(this.futureDate.getDay());
  startDate: number = this.futureDate.getDate();
  monthNameDate: string = getMonthName(this.futureDate.getMonth()) + " " + this.startDate;
  minStartDate: Date = this.futureDate;
  minEndDate: Date = this.futureDate;
  minDateTypeEndDate: Date = this.futureDate;
  googleCalendarOptions: any = this.getOptions();
  onDateChange(event: any) {
    let P_newStartDate = this.formAdd.controls.scheduleStartDate.value;
    let P_fullDate = new Date(P_newStartDate).toLocaleDateString();
    let P_dateType = new Date(P_fullDate);
    this.weekDayName = P_dateType.toLocaleDateString('en-US', { weekday: 'long' });
    this.startDate = P_dateType.getDate();
    this.monthNameDate = P_dateType.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    this.googleCalendarOptions = this.getOptions();

    this.formAdd.controls.scheduleEndDate.setValue(P_newStartDate);
    this.minEndDate = new Date(P_newStartDate);
  }
  onBlurDate(dp: any) {
    var date = moment(this.formAdd.controls.scheduleStartDate.value);
    const status = date.isValid();
    if (!status) {
      this.formAdd.controls.scheduleStartDate.setValue("");
    }
    //this.currentValue = +dp.input.currentValue.split('/')[0];
  }
  getOptions() {
    return [
      {
        "id": 1,
        "label": "Does not repeat"
      },
      {
        "id": 2,
        "label": "Daily"
      },
      {
        "id": 3,
        "label": "Every weekday (Monday to Friday)"
      },
      {
        "id": 4,
        "label": "Every Monday, Wednesday and Friday"
      },
      {
        "id": 5,
        "label": "Every Tuesday and Thursday"
      },
      {
        "id": 6,
        "label": `Weekly on ${this.weekDayName}`
      },
      {
        "id": 7,
        "label": `Monthly on date ${this.startDate}`
      },
      {
        "id": 8,
        "label": `Annually on ${this.monthNameDate}`
      }
    ];
  }
}
