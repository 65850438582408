
<table mat-table [dataSource]="sortedData" class="mat-elevation-z8">
    <ng-container matColumnDef="block">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
        </td>
    </ng-container>
    <ng-container matColumnDef="condition">
        <th mat-header-cell *matHeaderCellDef>Condition </th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field style="width:120px !important">
                <mat-select required [(value)]="element.condition" [disabled]="element.isStart">
                    <mat-option value="AND">AND</mat-option>
                    <mat-option value="OR">OR</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <div class="task-title">{{element.condition}}</div> -->
        </td>
    </ng-container>

    <ng-container matColumnDef="fromColumn">
        <th mat-header-cell *matHeaderCellDef>From Column </th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field style="">
                <mat-select [(value)]="element.fromColumn" required>
                    <mat-option *ngFor="let column of table.columns" [value]="column.id">{{column.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="oprator">
        <th mat-header-cell *matHeaderCellDef> Oprator </th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field style="">
                <mat-select required [(value)]="element.oprator">
                    <mat-option value="1">Equal</mat-option>
                    <mat-option value="2">Not Equal</mat-option>
                    <mat-option value="3">Greater Than</mat-option>
                    <mat-option value="4">Greater Than Or Equal</mat-option>
                    <mat-option value="5">Less Than</mat-option>
                    <mat-option value="6">Less Than Or Equal</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="columnValue">
        <th mat-header-cell *matHeaderCellDef> Column Value</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field style="">
                <input matInput autocomplete="off" [(value)]="element.value">
            </mat-form-field>
        </td>
    </ng-container>
    <ng-container matColumnDef="groupHeader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let group" [attr.colspan]="displayedColumns.length">
            <div style="float:left;margin-right: 10px;">
                <button mat-button (click)="groupHeaderClick(group)">
                    <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                    <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
                </button>
                <b style="margin-right: 20px;">{{group.group}}</b>
            </div>
            <mat-form-field style=" width:120px !important; float:left">
                <mat-select required [(value)]="group.condition" [disabled]="group.isStart">
                    <mat-option value="AND">AND</mat-option>
                    <mat-option value="OR">OR</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-condition-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="block-Row" [ngClass]="{'hidden-mat-row':!visible(row)}"></tr>
    <!-- (click)="groupHeaderClick(row)" -->
    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" [ngClass]="{'inner-group-class':row.groupId!=-1,'hidden-mat-row':!visible(row)}" (contextmenu)="onContextMenu($event, row)"> </tr>
</table>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="onContextAddCondition(item)">Add Condition</button>
    </ng-template>
</mat-menu>
