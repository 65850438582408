import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MultiSelectComponent } from '../multi-select/multi-select.component';

@Component({
  selector: 'app-relative-date',
  templateUrl: './relative-date.component.html',
  styleUrls: ['./relative-date.component.css']
})
export class RelativeDateComponent {
  public lookupData: any = {
    relativeDateOperators: [{ code: '0', description: 'Last', active: 1 }, { code: '1', description: 'This', active: 1 }],
    dateFilterTimeUnits: [
      { code: '0', description: 'Days', active: 1 },
      { code: '1', description: 'Weeks', active: 1 },
      { code: '2', description: 'Calendar Weeks', active: 1 },
      { code: '3', description: 'Months', active: 1 },
      { code: '4', description: 'Calendar Months', active: 1 },
      { code: '5', description: 'Years', active: 1 },
      { code: '6', description: 'Calendar Years', active: 1 },
      //{ code: '7', description: 'Minutes', active: 1 },
      //{ code: '8', description: 'Hours ', active: 1 },
    ],
    value:12
  }
  public _filters: any = {
    "timeUnit": [{
      "code": "12",
      "description": "Months",
      "active": 1
    }],
    "operator": [{
      "code": "0",
      "description": "Last",
      "active": 1
    }],
    "value": 12
  };

  @ViewChild('perator') _perator!: MultiSelectComponent;
  @ViewChild('timeUnit') _timeUnit!: MultiSelectComponent;
  @Input() set filters(filters: any) {
    this._filters = filters 
    this.CalendarDateValue.setValue(filters.value); 
  }
  get filters() {
    return this._filters;
  }
  get filterData() {
    if (this._perator.selectedItems.length == 1 || this._timeUnit.selectedItems.length==1) {
      return {
        operator: this._perator.selectedItems.length == 1 ? this._perator.selectedItems : this._perator.dropdownList[0],
        timeUnit: this._timeUnit.selectedItems.length == 1 ? this._timeUnit.selectedItems : this._timeUnit.dropdownList[0],
        value: ( (this.CalendarDateValue.value??"") == "" ? 12: this.CalendarDateValue.value)
      }

    }
  }
  get valid() {
    return false;
  }
  public CalendarDateValue = new FormControl('')
}
