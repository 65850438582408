<section class="table-container">
    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="action" sticky>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>
            </td>
        </ng-container>

        <ng-container matColumnDef="practicename">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="description-cell">
                practice
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
                {{element.practicename}}
            </td>
        </ng-container>
        <ng-container matColumnDef="accountingmonth">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">
                Fiscal Year
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
                {{element.fiscalyear}}
            </td>
        </ng-container>

        <ng-container matColumnDef="netrevenue">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-date">Amount</th>
            <td mat-cell *matCellDef="let element" [usd-money]="(!element.revenueamount)?0:element.revenueamount" [usd-money-cell]="true">
            </td>
        </ng-container>

        <ng-container matColumnDef="addition">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">addition </th>
            <td mat-cell *matCellDef="let element" [usd-money]="(!element.addition)?0:element.addition" [usd-money-cell]="true">
            </td>
        </ng-container>+

        <ng-container matColumnDef="subtraction">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>subtraction</th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital" [usd-money]="(!element.netrevenue)?0:element.netrevenue" [usd-money-cell]="true">
            </td>
        </ng-container>


        <ng-container matColumnDef="isactive">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Active </th>
            <td mat-cell *matCellDef="let element" [app-entity-status]="element.active" [app-entity-status-cell]="true">
            </td>
        </ng-container>
        <ng-container matColumnDef="createdby">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">
                Created By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-created-by ellipses-on-line-3" [title]="element.CreatedBy">
                {{element.createdby }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="createdon">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-on" style="width:45%">Created On </th>
            <td mat-cell *matCellDef="let element" class="cell-created-on text-right" style="width:45%">
                {{element.createdon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="modifiedby">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-by" style="width:45%">
                Modified By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-modified-by ellipses-on-line-3" [title]="element.modifiedby">
                {{element.modifiedby }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="modifiedon">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-on"> Modified On </th>
            <td mat-cell *matCellDef="let element" class="cell-modified-on"> {{element.modifiedon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>
        </tr>
    </table>
</section>
<mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngIf="hasnetrevenues_Edit" (click)="doEditnetrevenue(item)">Edit</button>
        <button mat-menu-item *ngIf="hasnetrevenues_Delete" (click)="doDeletenetrevenue(item)"> Delete</button>
    </ng-template>
</mat-menu>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menuFilter" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #menuFilter="matMenu">
    <ng-template matMenuContent let-item="item">
        <div mat-dialog-title (click)="$event.stopPropagation();">
            <app-custom-filter [masterData]="item" (onFilterApply)="onFilterApply($event)"></app-custom-filter>
        </div>
    </ng-template>
</mat-menu>
