import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import * as custom from '../../../Utility/common-functions';
import { getSafeDateReturnMoment } from '../../../Utility/common-functions'; 
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYYMM',
  },
  display: {
    dateInput: 'YYYYMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css'],
   
})
export class ReportFilterComponent {
  public _lookupData: any;
  public _orgLookupData: any;
  public _filterData: any;
  public showFilter: string[] = [];
  public _reportid: string = '';
  public _filters: any = { practiceIds: [], owners: [], actions: [] };
  public _isFromInfo: boolean = false;

  formAdd: FormGroup = new FormGroup({
    calenderstartDate: new FormControl('', [Validators.required]),
    calenderendDate: new FormControl('', [Validators.required]),
    checkstartDate: new FormControl('', [Validators.required]),
    checkendDate: new FormControl('', [Validators.required]),
    transactionstartDate: new FormControl('', [Validators.required]),
    transactionendDate: new FormControl('', [Validators.required]),
    transactionDate: new FormControl(''),
    checkDateValue: new FormControl('', [Validators.required]),
    transactionDateValue: new FormControl('', [Validators.required]),
    fiscalyear: new FormControl('', [Validators.required]),
    fiscalyearhidden: new FormControl('', [Validators.required])

  });

  @ViewChild('clients') _client!: MultiSelectComponent;
  @ViewChild('selectors') _selectors!: MultiSelectComponent;
  @ViewChild('serviceLocations') _serviceLocations!: MultiSelectComponent;
  @ViewChild('serviceProvider') _serviceProvider!: MultiSelectComponent;
  @ViewChild('payorGroups') _payorGroups!: MultiSelectComponent;
  @ViewChild('checkDateOperator') _checkDateOperator!: MultiSelectComponent;
  @ViewChild('checkDateFilterTimeUnit') _checkDateFilterTimeUnit!: MultiSelectComponent;
  @ViewChild('transactionDateOperator') _transactionDateOperator!: MultiSelectComponent;
  @ViewChild('transactionDateTimeUnit') _transactionDateTimeUnit!: MultiSelectComponent;
  @ViewChild('payors') _payors!: MultiSelectComponent;
  @Input() set lookupData(lookupData: any) {

    this._orgLookupData = lookupData;
    this._lookupData = lookupData;
  }
  @Input() set filterData(filterData: any) {
    this._filterData = filterData;
    console.log("this._filterData: ", this._filterData);
    this.onSelectionChange(null);
  }

  @Input() set isFromInfo(isFromInfo: any) {
    this._isFromInfo = isFromInfo;
  }

  get filterData() {
    return this._filterData;
  }
  @Input() set reportid(reportid: any) {
    this._reportid = reportid;
    
  }

  get reportid() {
    return this._reportid;
  }

  get lookupData() {
    return this._lookupData;
  }



  @Input() set filters(filters: any) {
    this._filters = filters;
    if (filters) {
      if (filters.calenderStartDate)
        this.formAdd.controls.calenderstartDate.setValue(filters.calenderStartDate);
      this.formAdd.controls.calenderendDate.setValue(filters.calenderEndDate);
      this.formAdd.controls.checkstartDate.setValue(filters.checkstartDate);
      this.formAdd.controls.checkendDate.setValue(filters.checkendDate);
      this.formAdd.controls.transactionstartDate.setValue(filters.transactionstartDate);
      this.formAdd.controls.transactionendDate.setValue(filters.transactionendDate);
      this.formAdd.controls.transactionDate.setValue(filters.transactionDate);
      this.formAdd.controls.transactionDateValue.setValue(filters.transactionDates?.value ?? 0);
      this.formAdd.controls.checkDateValue.setValue(filters.checkDate?.value ?? 0);
      this.formAdd.controls.fiscalyear.setValue(filters.fiscalyear ?? moment().format("YYYYMM"));
      this.formAdd.controls.fiscalyearhidden.setValue(filters.fiscalyear ?? moment().format("MM/01/YYYY"));
    }
  }
  get filters() {
    return this._filters;
  }
  doFilter() {

    this._filters = this.filtervalue;
    console.log(this._filters);
    this.onFilter.emit(this._filters);
  };
  @Output() onFilter = new EventEmitter();
  get filtervalue() {
    return {
      practiceIds: (this._client) ? this._client.selectedItems : [],
      selectors: (this._selectors) ? this._selectors.selectedItems : [],
      serviceLocations: (this._serviceLocations) ? this._serviceLocations.selectedItems : [],
      serviceProviderIds: (this._serviceProvider) ? this._serviceProvider.selectedItems : [],
      payorGroupsIds: (this._payorGroups) ? this._payorGroups.selectedItems : [],
      payorsIds: (this._payors) ? this._payors.selectedItems : [],
      calenderStartDate: getSafeDateReturnMoment(this.formAdd.controls.calenderstartDate.value, true)?.format("YYYY-MM-DD") ?? "",
      calenderEndDate: getSafeDateReturnMoment(this.formAdd.controls.calenderendDate.value, true)?.format("YYYY-MM-DD") ?? "",
      checkstartDate: getSafeDateReturnMoment(this.formAdd.controls.checkstartDate.value, true)?.format("YYYY-MM-DD") ?? "",
      checkendDate: getSafeDateReturnMoment(this.formAdd.controls.checkendDate.value, true)?.format("YYYY-MM-DD") ?? "",
      transactionstartDate: getSafeDateReturnMoment(this.formAdd.controls.transactionstartDate.value, true)?.format("YYYY-MM-DD") ?? "",
      transactionendDate: getSafeDateReturnMoment(this.formAdd.controls.transactionendDate.value, true)?.format("YYYY-MM-DD") ?? "",
      transactionDate: getSafeDateReturnMoment(this.formAdd.controls.transactionDate.value, true)?.format("YYYY-MM-DD") ?? "",
      checkDate: (this._checkDateOperator) ? { operator: this._checkDateOperator.selectedItems, timeUnit: this._checkDateFilterTimeUnit.selectedItems, value: this.formAdd.controls.checkDateValue.value } : null,
      transactionDates: (this._transactionDateOperator) ? { operator: this._transactionDateOperator.selectedItems, timeUnit: this._transactionDateTimeUnit.selectedItems, value: this.formAdd.controls.transactionDateValue.value } : null,
      fiscalyear: this.formAdd.controls.fiscalyear.value
    };
  }
  doCancelFilter() {
    this._filters = { practiceIds: [], owners: [], actions: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
    //this.practiceIds = [];
    //this.owners = [];
    //this.selectedActions = []
    this.onFilter.emit(this._filters);
    return this._filters;
  }
  onSelectionChange(item: any) {
    try {
      let selectedItems = (this._client && item) ? this._client?.selectedItems : this._filters.practiceIds
      if (selectedItems != null && selectedItems != undefined && selectedItems.length > 0) {
        this._lookupData.serviceLocationFiltered = this._orgLookupData.serviceLocation.filter((w: any) => { return selectedItems.findIndex((s: any) => { return s.code == w.clientid }) >= 0 })
        this._lookupData.serviceProviderFiltered = this._orgLookupData.serviceProvider.filter((w: any) => { return selectedItems.findIndex((s: any) => { return s.code == w.clientid }) >= 0 })
        this._lookupData.payorGroupsFiltered = this._orgLookupData.payorGroups.filter((w: any) => { return selectedItems.findIndex((s: any) => { return s.code == w.clientid }) >= 0 })
        this._lookupData.payorsFiltered = this._orgLookupData.payors.filter((w: any) => { return selectedItems.findIndex((s: any) => { return s.code == w.clientid }) >= 0 })
      }
      else {
        this._lookupData.serviceLocationFiltered = this._orgLookupData.serviceLocation
        this._lookupData.serviceProviderFiltered = this._orgLookupData.serviceProvider
        this._lookupData.payorGroupsFiltered = this._orgLookupData.payorGroups
        this._lookupData.payorsFiltered = this._orgLookupData.payors

      }
    } catch (exe) {
      console.log(exe);
    }
  }
  getRangeValue() {
    let sd = getSafeDateReturnMoment(this.formAdd.controls.calenderstartDate.value, true);
    let ed = getSafeDateReturnMoment(this.formAdd.controls.calenderendDate.value, true);
    var val = `${sd ? sd?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'} - ${ed ? ed?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'}`;
    return val;
  }
  getCheckDateRangeValue() {
    let sd = getSafeDateReturnMoment(this.formAdd.controls.checkstartDate.value, true);
    let ed = getSafeDateReturnMoment(this.formAdd.controls.checkendDate.value, true);
    var val = `${sd ? sd?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'} - ${ed ? ed?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'}`;
    return val;
  }

  getTransactionDateRangeValue() {
    let sd = getSafeDateReturnMoment(this.formAdd.controls.transactionstartDate.value, true);
    let ed = getSafeDateReturnMoment(this.formAdd.controls.transactionendDate.value, true);
    var val = `${sd ? sd?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'} - ${ed ? ed?.format("YYYY-MM-DD") ?? 'YYYY-MM-DD' : 'YYYY-MM-DD'}`;
    return val;
  }
  getFiltersDetails() {
    this.formAdd.controls.calenderstartDate.setValue(this.getRangeValue().split(",")[0]);
    this.formAdd.controls.calenderendDate.setValue(" - " + this.getRangeValue().split(",")[1]);

    this.formAdd.controls.checkstartDate.setValue(this.getCheckDateRangeValue().split(",")[0]);
    this.formAdd.controls.checkendDate.setValue(" - " + this.getCheckDateRangeValue().split(",")[1]);

    this.formAdd.controls.transactionstartDate.setValue(this.getTransactionDateRangeValue().split(",")[0]);
    this.formAdd.controls.transactionendDate.setValue(" - " + this.getTransactionDateRangeValue().split(",")[1]);
  }


  displayFilters(filterId: any) {

    return this._filterData.findIndex((w: any) => { return w.reportId == this.reportid && w.filterId == filterId }) > -1;

  }
  setMonthAndYear(normalizedMonthAndYear: any, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.getMonth());
    ctrlValue.year(normalizedMonthAndYear.getFullYear());
    ctrlValue.date(1);
    this.formAdd.controls.fiscalyear.setValue(ctrlValue.format("YYYYMM"))
    this.formAdd.controls.fiscalyearhidden.setValue(ctrlValue.format("MM/01/YYYY"))
    //this.formAdd.controls.fiscalyear.setValue(ctrlValue.format("YYYYMM"))
    datepicker.select(ctrlValue);
    datepicker.close();
  }
}

function provideMomentDateAdapter(MY_FORMATS: { parse: { dateInput: string; }; display: { dateInput: string; monthYearLabel: string; dateA11yLabel: string; monthYearA11yLabel: string; }; }): import("@angular/core").Provider {
    throw new Error('Function not implemented.');
}
