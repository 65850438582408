<div class="row" style="max-width: 200px;">
    <div class="col-lg-4" style="padding: 0px !important;">
        <button mat-icon-button (click)="setNextValue($event,'hr',38)" style="margin-left: 10px;">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <input matInput type="text" class="form-control" [formControl]="hourValue" (keypress)="allowNumberOnly($event)" (keydown)="setNextValue($event,'hr')" (change)="onInputChange($event,'hr')">
        <button mat-icon-button (click)="setNextValue($event,'hr',40)" style="margin-left: 10px;">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </div>
    <div class="col-lg-4" style="padding: 0px !important;">
        <button mat-icon-button (click)="setNextValue($event,'min' ,38)" style="margin-left: 10px;">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <input matInput type="text" class="form-control" [formControl]="minuteValue" (keypress)="allowNumberOnly($event)" (change)="onInputChange($event,'min')" (keydown)="setNextValue($event,'min')">
        <button mat-icon-button (click)="setNextValue($event,'min' ,40)" style="margin-left: 10px;">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </div>
    <div class="col-lg-4" style="padding: 0px !important;">
        <button mat-flat-button style="margin-top: 40px;" color="primary" (click)="onInputChange($event,'meridiem')">{{meridiemValue}}</button>
    </div>
</div>
