<div>

    <input matInput type="text" class="form-control" id="namestarttext" placeholder="0" [value]='selected?.format("MM/DD/YYYY hh:mm A")' (click)="onShowCalander()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">

    <ng-template cdkConnectedOverlay
                 [cdkConnectedOverlayOrigin]="trigger"
                 [cdkConnectedOverlayOpen]="showCalander"
                 [cdkConnectedOverlayHasBackdrop]="true"
                 (detach)="showCalander = false"
                 (backdropClick)="showCalander = false">
        <div class="filter-pane" style="width: 550px; padding: 10px;">
            <div class="row">
                <div style="" class="col-lg-8">
                    <mat-calendar [(selected)]="selectedDate" (selectedChange)="onCalendarSelect($event)" #calendar></mat-calendar>
                </div>
                <div style="" class="col-lg-4">
                    <div class="col-lg-4 TimePicker" fxLayout="column" fxLayoutGap=".2em">
                        <button *ngFor="let hour of hours" mat-flat-button class="btn" (click)="selectedHour=hour;onChanges('hour',hour)" [ngClass]="{'selectedtime selectedHour':selectedHour==hour}">{{hour}}</button>
                    </div>
                    <div class="col-lg-4 TimePicker" fxLayout="column" fxLayoutGap=".2em">
                        <button *ngFor="let minute of minutes" mat-flat-button class="btn" (click)="selectedMinute=minute;onChanges('minute',minute)" [ngClass]="{'selectedtime selectedMinute':selectedMinute==minute}">{{minute}}</button>
                    </div>
                    <div class="col-lg-4 TimePicker" fxLayout="column" fxLayoutGap=".2em">
                        <button class="btn" (click)="onChanges('meridiem','AM')" [ngClass]="{'selectedtime selectedMeridiem':selectedMeridiem=='AM'}">AM</button>
                        <button class="btn" (click)="onChanges('meridiem','PM')" [ngClass]="{'selectedtime selectedMeridiem':selectedMeridiem=='PM'}">PM</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
