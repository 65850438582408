<div>

    <input matInput type="text" class="form-control" id="namestarttext" placeholder="0" [value]="selectedValue" (click)="onShowCalander()" cdkOverlayOrigin #trigger="cdkOverlayOrigin" style="width: calc(100% - 6px) !important;">

        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayOrigin]="trigger"
                     [cdkConnectedOverlayOpen]="showCalander"
                     [cdkConnectedOverlayHasBackdrop]="true"
                     (detach)="showCalander = false"
                     (backdropClick)="showCalander = false">
    <div class="filter-pane" style="width: 200px; padding: 10px;">
        <div class="row">
            <div fxLayout="column" fxLayoutGap=".2em">
                <button *ngFor="let Range of customRanges" mat-flat-button class="btn btn-customRange" (click)="selectedRange=Range;onChanges('range',Range)" [ngClass]="{'selectedRange':selectedRange==Range}">{{Range.description}}</button>
                <button mat-flat-button class="btn btn-customRange" (click)="selectedRange=cust;picker.open()" [ngClass]="{'selectedRange':selectedRange==cust}">Custom Range</button>
            </div>
        </div>
        <div class="row" style="visibility:hidden;height:0px">
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate class="form-control" [formControl]="start" placeholder="Start date">
                <input matEndDate class="form-control" [formControl]="end" placeholder="End date">
            </mat-date-range-input>
            <mat-date-range-picker #picker (closed)="onClose($event)"></mat-date-range-picker>
        </div>
    </div>
    </ng-template>
</div>
