<h2 mat-dialog-title>Delete recurring event</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
    <mat-radio-group aria-labelledby="example-radio-group-label"
                     class="example-radio-group"
                     [(ngModel)]="option">

        <mat-radio-button *ngFor="let option of options" class="example-radio-button" [value]="option.code">{{option.describe}}</mat-radio-button>

    </mat-radio-group>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button class="btn btn-btn-primary" (click)="doYes()" mat-dialog-close>Ok</button>
    <button mat-button class="btn" (click)="doNo()" cdkFocusInitial>Cancel</button>
</mat-dialog-actions>
