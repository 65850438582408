import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
//import { CalendarOptions } from '@fullcalendar/angular'; // Import CalendarOptions from @fullcalendar/angular
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, DatesSetArg } from '@fullcalendar/core'; // Import the types from @fullcalendar/core
import { BotDashboardService } from '../bot-dashboard.service';
import { AutomationSummary } from '../bot-dashboard';
import { MatDialog } from '@angular/material/dialog';
import { SchedulingActionComponent } from '../scheduling-action/scheduling-action.component';
import { UserService } from '../../../components/user/user.service';
import { getConverted12HourTime, GetFormattedDate } from '../../../Utility/common-functions';
import { ClientService } from '../../../services/client.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { FullCalendarComponent } from '@fullcalendar/angular'; // Import FullCalendar component
import { ReportFilters } from '../../report/report-filter/ReportFilters';
import * as custom from '../../../Utility/CustomValidators.utilities'
import * as moment from 'moment-timezone';
import { MatMenuTrigger } from '@angular/material/menu';
import { DeleteEventDialogComponent } from '../delete-event-dialog/delete-event-dialog.component';
import { PayrollService } from '../../payroll/payroll.service';
import { PayrollPreviewDialogComponent } from '../../payroll/payroll-preview-dialog/payroll-preview-dialog.component';

@Component({
  selector: 'app-summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.css']
})
export class SummaryListComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private botDashboardService: BotDashboardService,
    private clientService: ClientService,
    private userService: UserService,
    private payrollService: PayrollService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  @ViewChild('calendarRef') calendarRef!: FullCalendarComponent; // Reference to FullCalendar

  //ngAfterViewInit() {
  //  if (this.calendarRef) {
  //    this.calendarRef.getApi().refetchEvents();
  //  }
  //}

  public P_showCalendar: boolean = false;
  public data: any[] = [];
  public distictServers: any;
  public distictInstances: any;
  public distictSteps: any;
  public distinctMap: any;
  public distinctFilterInstances: any;
  public distinctFilterSteps: any;

  // get summary data in child
  public _scheduleType: number = 0;
  public _summaryList: AutomationSummary[] = [];
  calenderHeight!: number;
  lastviewName!: string;
  calendarOptions!: CalendarOptions;
  contextMenuVisible: boolean = false;
  contextMenuPosition: { [key: string]: string } = {};
  selectedEvent: any;
  summaryInputJSON: any = {};
  serverIps: string = "";
  input1: boolean = false;
  input2: boolean = false;
  input3: boolean = false;
  input4: boolean = false;
  P_ScheduleAddonText = "_ARCalytics";
  lookup1 = false;
  lookup2 = false;
  _lookupListEvents!: any[];

  @Input() set scheduleType(scheduleType: number) {
    this._scheduleType = scheduleType;
    this.input1 = true;
    this.resetSummaryInputJSON();
    this.setCalendarOption();
  }
  @Input() set summaryList(summaryList: AutomationSummary[]) {
    if (summaryList) {
      this._summaryList = summaryList;
      this.input2 = true;
      this.setCalendarOption();
      this.P_showCalendar = true;
    }
  }
  @Input() set automationStepsList(automationStepsList: AutomationSummary[]) {
    if (automationStepsList) {
      this.automationsteps = automationStepsList;
      this.input3 = true;
      this.setCalendarOption();
    }
  }

  //@Input() set lookupListEvents(lookupListEvents: any[]) {
  //  this.P_showCalendar = true;
  //  this._lookupListEvents = lookupListEvents;
  //  this.input4 = true;
  //  this.setCalendarOption();
  //}

  @Output() getAutomationDetails = new EventEmitter<any>(); // sent json for details
  @Output() getAutomationSummary = new EventEmitter<any>();

  counter: number = 1;
  background_color: string = "#2e8bc0";
  colorList: string[] = [
    "#9F2B68",
    "#BF40BF",
    "#800020",
    "#702963",
    "#AA336A",
    "#301934",
    "#483248",
    "#5D3FD3",
    "#915F6D",
    "#770737",
    "#DA70D6",
    "#A95C68",
    "#800080",
    "#51414F",
    "#953553",
    "#630330",
    "#7F00FF",
    "#722F37"
  ];
  timeList: string[] = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
    "12 AM"
  ];
  widthPercentage: number = 100;
  marginPercentage: number = 0;
  time12Hour: string = "";
  timeLiesIndex: number = 0;
  dialogueWidth: string = '920px';
  isLoading: boolean = true;
  lookups!: any;
  automationsteps!: any;

  public lookupData: any = {};
  public filterData: any[] = [];
  public filters: any = { practiceIds: [], selectors: [{ code: 'DOE', description: 'DOE', active: 1 }] };
  public reportId: string = '';

  public isLoadinglookupsData: boolean = false;

  ngOnInit() {
    this.bindCalendarData();
    if (this._scheduleType == 4)
      this.getLookupDataPayroll();
  }

  async bindCalendarData() {
    if (this._scheduleType == 1) {
      this.lookups = await Promise.all([
        this.botDashboardService.getServers([{ id: "-1" }]),
        this.botDashboardService.getAutomation([{ id: "-1" }])
      ]);
    }
    else if (this._scheduleType == 2) {

      //this.isLoading = false;
    }
    else if (this._scheduleType == 3) {
      this.lookups = await Promise.all([
        this.clientService.getPracticeManagementLookup([]),
        this.clientService.getlookup([])
      ]);
      //this.isLoading = false;
    }

    this.isLoading = false;

    //if (this.scheduleType != 2) {
    //  this.hideLoader();
    //}

    //this.lookups = await Promise.all([
    //  this.clientService.getPracticeManagementLookup([]),
    //  this.clientService.getlookup([]),
    //  this.botDashboardService.getServers([{ id: "-1" }]),
    //  this.botDashboardService.getAutomation([{ id: "-1" }]),
    //  this.botDashboardService.getEmailProfile([{ id: "-1" }]),
    //  this.userService.getMenus(menuRequestJson),
    //  this.clientService.getreportfilter(reportFilterRequestJson)
    //]);

    //this.bindCurrentMonthData("", "");

    //this.summaryList = data;
    //this._summaryList = data;
    //this.distictServers = this.getDistinct(this._summaryList, "servername").distinctElements;
    //this.distictInstances = this.getDistinct(this._summaryList, "processid").distinctElements;

    //for (let i = 0; i < this.distictServers.length; i++) {
    //  this.data.push({
    //    "id": this.distictServers[i]["uniqueserverid"],
    //    "title": this.distictServers[i]["servername"],
    //    "start": this.distictServers[i]["starttime"],
    //    "end": this.distictServers[i]["endtime"],
    //    "description": this.distictServers[i]["serverusername"],
    //    "isactive": 1,
    //    //"created_by": 87,
    //    "display": "block",
    //    "assignee_name": "",
    //    "createdby_name": this.distictServers[i]["createdby"],
    //    //"eventsummary": "Weekly on Monday, Friday,6 times",
    //    //"is_createdby": 1,
    //    "background_color": "#2e8bc0",
    //    "border_color": "#2e8bc0",
    //    "event_priority_id": 1,
    //    "is_server": 1
    //    //"slotDuration": "00:30:00"
    //    //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //  });

    //  this.distinctFilterInstances = this.distictInstances.filter((obj: any) => {
    //    return (obj.uniqueserverid == this.distictServers[i]["uniqueserverid"])
    //  });

    //  for (let i = 0; i < this.distinctFilterInstances.length; i++) {
    //    this.data.push({

    //      "id": this.distinctFilterInstances[i]["processid"],
    //      "title": this.distinctFilterInstances[i]["automationname"],
    //      "start": this.distinctFilterInstances[i]["starttime"],
    //      "end": this.distinctFilterInstances[i]["endtime"],
    //      "description": this.distinctFilterInstances[i]["serverusername"],
    //      "isactive": 1,
    //      //"created_by": 87,
    //      "display": "block",
    //      "assignee_name": "",
    //      "createdby_name": this.distinctFilterInstances[i]["createdby"],
    //      //"eventsummary": "Weekly on Monday, Friday,6 times",
    //      //"is_createdby": 1,
    //      "background_color": "#2e8bc0",
    //      "border_color": "#2e8bc0",
    //      "event_priority_id": 2,
    //      "is_server": 0
    //      //"slotDuration": "00:30:00"
    //      //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //    });

    //    this.distinctFilterSteps = this._summaryList.filter((obj: any) => {
    //      return (obj.processid == this.distinctFilterInstances[i]["processid"])
    //    });

    //    for (let i = 0; i < this.distinctFilterSteps.length; i++) {
    //      this.data.push({
    //        "id": this.distinctFilterSteps[i]["automationstepid"],
    //        "title": this.distinctFilterSteps[i]["automationstep"],
    //        "start": this.distinctFilterSteps[i]["automationstepstarttime"],
    //        "end": this.distinctFilterSteps[i]["automationstependtime"],
    //        "description": this.distinctFilterSteps[i]["serverusername"],
    //        "isactive": 1,
    //        //"created_by": 87,
    //        "display": "block",
    //        "assignee_name": "",
    //        "createdby_name": this.distinctFilterSteps[i]["createdby"],
    //        //"eventsummary": "Weekly on Monday, Friday,6 times",
    //        //"is_createdby": 1,
    //        "background_color": "#2e8bc0",
    //        "border_color": "#2e8bc0",
    //        "event_priority_id": 3,
    //        "is_server": 0
    //        //"slotDuration": "00:30:00"
    //        //"scheduleddate": "2024-08-05T11:15:00+00:00"
    //      })
    //    }

    //  }
    //}
  }

  randomNumber() {
    let num: number = Math.random() * (this.colorList.length - 1) + 1;
    return parseInt(num.toString());
  }

  getDistinct(arr: any = [], field: any) {
    this.distinctMap = {};

    arr.forEach((item: any) => {
      if (!this.distinctMap[item[field]]) {
        this.distinctMap[item[field]] = item;
      }
    });
    const distinctFields = Object.keys(this.distinctMap);
    const distinctElements = Object.values(this.distinctMap);

    return { distinctFields, distinctElements }
  }

  handleDateClick(arg: DateSelectArg) {
    //alert('Date clicked: ' + arg.dateStr);
  }

  async onAddInstance() {
    await this.getLookupDataReport();
    await this.getLookupDataPayroll();
    let dlgRef = this.dialog.open(SchedulingActionComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          scheduleType: this._scheduleType,
          lookups: this.lookups,
          lookupData: this.lookupData,
          filters: this.filters,
          filterData: this.filterData,
          reportId: this.reportId,
          statusId: 0

        },
        height: ((this._scheduleType == 4) ? '95vh' : 'auto'),
        panelClass: 'confirm-dialog-container',
        width: ((this._scheduleType == 4) ? '90%' : this.dialogueWidth)
      });

    var Action_result = await dlgRef.afterClosed().toPromise();
    if (Action_result) {
      //this.summaryInputJSON.resetData = 1;
      this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 1 });
    }
  }

  async onEditInstance(arg: EventClickArg) {
    await this.getLookupDataPayroll();
    await this.getLookupDataReport();

    var P_data = this._summaryList.filter((obj) => {
      return (arg.event.id == obj.id.toString() &&
        arg.event.extendedProps.sub_event_id == obj.sub_event_id.toString())
    });
    if (P_data.length != 0 && arg.event.extendedProps.is_server != 1 //&& arg.event.extendedProps.event_status_id == 0

    ) { // event must be not started, if it is in inprogress, succeed or failed //  && arg.event.extendedProps.event_status_id == 0
      P_data[0].event_status_id = arg.event.extendedProps.event_status_id;
      const dlgRef = this.dialog.open(SchedulingActionComponent,
        {
          disableClose: true,
          autoFocus: false,
          data: {
            scheduleType: this._scheduleType,
            dataToRebind: P_data,
            lookups: this.lookups,
            lookupData: this.lookupData,
            filters: this.filters,
            filterData: this.filterData,
            reportId: this.reportId,
            statusId: arg.event.extendedProps.event_status_id
          },
          panelClass: 'confirm-dialog-container',
          width: ((this._scheduleType == 4) ? '90%' : this.dialogueWidth)
          /*height: '500px',*/
        });

      var Action_result = await dlgRef.afterClosed().toPromise();
      if (Action_result) {
        //this.summaryInputJSON.resetData = 1;
        this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 1 });
      }
    }
  }

  onCancelEdit(item: any) {
    //this.dialog.close(item);
  }

  eventDidMount(info: { el: HTMLElement; event: EventApi }) {
    const eventEl = info.el;
    const event = info.event;
    // Attach context menu listener
    info.el.addEventListener('contextmenu', (e: MouseEvent) => {
      e.preventDefault(); // Prevent the default context menu

      // Position your custom context menu
      this.showContextMenu(e, info.event.extendedProps);
    });
  }

  renderEventContent(info: { el: HTMLElement; event: EventApi }) {
    try {

      let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let headerDiv = "";
      headerDiv += `<div class="header-calendar-div position-relative" style="display: none;">`;
      headerDiv += `<div class="first-cell position-absolute left-0">${this.timeList[0]}</div>`;
      for (let i = 1; i < this.timeList.length; i++) {
        headerDiv += `<div class="other-cell float-left"><span>${this.timeList[i]}</span></div>`;
      }
      headerDiv += `</div>`;

      let utcDate = new Date(info.event.extendedProps.start_string);
      let localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
      let P_startTime = localDate.toISOString().slice(0, 19);

      P_startTime = moment(info.event.extendedProps.start_string).tz(userTimeZone).format();

      let P_separator = ".";
      if (P_startTime.indexOf("+") != -1) {
        P_separator = "+";
      }
      let startTime = P_startTime.split('T')[1].split(P_separator)[0];

      utcDate = new Date(info.event.extendedProps.end_string);
      localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
      let P_endTime = localDate.toISOString().slice(0, 19);

      P_endTime = moment(info.event.extendedProps.end_string).tz(userTimeZone).format();

      P_separator = ".";
      if (P_endTime.indexOf("+") != -1) {
        P_separator = "+";
      }
      let endTime = P_endTime.split('T')[1].split(P_separator)[0];
      this.time12Hour = getConverted12HourTime(startTime.split(":")[0], startTime.split(":")[1]);
      this.timeLiesIndex = this.getTimeLiesIndex(this.time12Hour);
      this.marginPercentage = this.getMarginPercentage(this.timeLiesIndex, this.time12Hour.split(" ")[1]);

      startTime = startTime.split(":")[0] + ":" + startTime.split(":")[1];
      endTime = endTime.split(":")[0] + ":" + endTime.split(":")[1];

      //if (info.event.extendedProps.schedule_details == "VP_1") {
      //  console.log("info.event.extendedProps.end_string: ", info.event.extendedProps.end_string);
      //  console.log("utcDate: ", utcDate);
      //  console.log("localDate: ", localDate);
      //  console.log("P_endTime: ", P_endTime);
      //  console.log("endTime: ", endTime);
      //  ////debugger;
      //}

      this.widthPercentage = this.getTimeDifference(startTime, endTime);
      this.widthPercentage = this.getWidthPercentage(this.widthPercentage);

      if (info.event.extendedProps.is_server != null &&
        info.event.extendedProps.is_server != undefined &&
        info.event.extendedProps.is_server == 1) {
        this.background_color = this.colorList[this.randomNumber()];
        this.widthPercentage = 100;
        this.marginPercentage = 0;
      }

      let P_schedule_details = "";
      if (info.event.extendedProps.schedule_details != "") {
        P_schedule_details = `(${info.event.extendedProps.schedule_details})`;
      }

      let priorityLabel = "";
      let P_customWidthClass = "";
      if (this.widthPercentage < 20) {
        P_customWidthClass = "low-width";
      }

      // Commenting Priority
      //if (info.event.extendedProps.event_priority_id == 1) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#34A853;" title="Low"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      //} else if (info.event.extendedProps.event_priority_id == 2) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style ="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#E1AD01;" title="Medium"></span>`/*'<i class="fa fa-circle" style="color:#E1AD01;"></i>'*/;
      //} else if (info.event.extendedProps.event_priority_id == 3) {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:#B3261E;" title="High"></span>`/*'<i class="fa fa-circle" style="color:#800000;"></i>'*/;
      //}

      // Showing Status - as of now showing only for automation
      //if (info.event.extendedProps.schedule_type_id == 1) {
      //  if (info.event.extendedProps.event_status_id == 0) {
      //    priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:grey;" title="Not Started"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      //  } else if (info.event.extendedProps.event_status_id == 1) {
      //    priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:blue;" title="In Progress"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      //  } else if (info.event.extendedProps.event_status_id == 2) {
      //    priorityLabel = `<div class="main-div ${P_customWidthClass}" style ="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:green;" title="Succeed"></span>`/*'<i class="fa fa-circle" style="color:#E1AD01;"></i>'*/;
      //  } else if (info.event.extendedProps.event_status_id == 3) {
      //    priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:red;" title="Failed"></span>`/*'<i class="fa fa-circle" style="color:#800000;"></i>'*/;
      //  }
      //} else {
      //  priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:blue;" title="In Progress"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      //}

      if (info.event.extendedProps.event_status_id == 0) {
        priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:grey;" title="Not Started"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      } else if (info.event.extendedProps.event_status_id == 1) {
        priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:blue;" title="In Progress"></span>` /*'<i class="fa fa-circle" style="color:#009578;"></i>'*/;
      } else if (info.event.extendedProps.event_status_id == 2) {
        priorityLabel = `<div class="main-div ${P_customWidthClass}" style ="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:green;" title="Succeed"></span>`/*'<i class="fa fa-circle" style="color:#E1AD01;"></i>'*/;
      } else if (info.event.extendedProps.event_status_id == 3) {
        priorityLabel = `<div class="main-div ${P_customWidthClass}" style="background-color: ${this.background_color};border-color: ${this.background_color};width:${this.widthPercentage}%;margin-left:${this.marginPercentage}%;" data-attr="${this.background_color}"><span class="dot" style="background-color:red;" title="Failed"></span>`/*'<i class="fa fa-circle" style="color:#800000;"></i>'*/;
      }


      //var customHtml = headerDiv + priorityLabel + '&nbsp;' + startTime + "-" + endTime + '&nbsp; <span class="strip-description" title="' + info.event.title + '">' + info.event.title + '</span></div>';
      var customHtml = `${headerDiv} ${priorityLabel} <span class="strip-description" title="${info.event.title} ${P_schedule_details}">${info.event.title} <small>${P_schedule_details}</small></span></div>`;
      return { html: customHtml };
    } catch (ex) {
    }
  }

  getTimeDifference(startTime: string, endTime: string) {
    let startHour: any = startTime.split(":")[0];
    let endHour: any = endTime.split(":")[0];
    let startMinute: any = startTime.split(":")[1];
    let endMinute: any = endTime.split(":")[1];

    startHour = Number(startHour);
    endHour = Number(endHour);
    startMinute = Number(startMinute);
    endMinute = Number(endMinute);

    if (startHour > 12 && endHour > 12) {
      startHour = startHour - 12;
      endHour = endHour - 12;
    }

    let totalStartMinutes = (startHour * 60) + startMinute;
    let totalEndMinutes = (endHour * 60) + endMinute;
    let totalMinuteDifference = Math.abs(totalStartMinutes - totalEndMinutes);

    let totalDifference = totalMinuteDifference / 60;
    return totalDifference;
  }

  getWidthPercentage(widthPercentage: number) {
    let width = (widthPercentage * 100) / 24;
    return width;
  }

  getTimeLiesIndex(time12Hour: string) {
    let timeSplit = time12Hour.split(" ");
    for (let i = 0; i < this.timeList.length; i++) {
      if (this.timeList[i] == (timeSplit[0] + " " + timeSplit[2])) {
        return i;
      }
    }
    return 0;
  }

  getMarginPercentage(timeLiesIndex: number, minutes: string) {
    let P_margin = 0;

    if (timeLiesIndex == 0) {
      P_margin = 0;
    } else {
      P_margin = timeLiesIndex * 4.166 + ((Number(minutes) * 4.166) / 60);
    }
    return P_margin;
  }

  setCalendarOption() {

    if (this.input1 && this.input2 && this.input3) {


      //if (!this.input1 || !this.input3 || !this.input4) {
      //  return;
      //}

      //if (!this.P_showCalendar) {
      //  this.P_showCalendar = false;
      //}
      this.data = [];
      //this.calendarOptions = {};
      this.serverIps = "";
      if (this._scheduleType == 1) {
        for (let i = 0; i < this._summaryList.length; i++) {

          let P_serverIp = "";
          try {
            P_serverIp = JSON.parse(this._summaryList[i].description)[0].ServerIP;
          } catch (exe) {
            console.log(this._summaryList[i].description);
          }

          let P_StartDate = this._summaryList[i]["start"].split('T')[0];
          if (this.serverIps.indexOf(P_serverIp + P_StartDate) == -1) {

            this.serverIps += P_serverIp + P_StartDate + ",";

            let P_instances = this.getInstanceDetails(this._summaryList, this._summaryList[i], P_serverIp);
            this.data.push({
              "id": this._summaryList[i]["id"],
              "title": P_instances[0].servername + "(" + P_instances[0].serverip + ")",
              "start": this._summaryList[i]["start"],
              "end": this._summaryList[i]["new_end"],
              "new_end": this._summaryList[i]["end"],
              "description": "",
              "isactive": 1,
              "display": "block",
              "assignee_name": "",
              "createdby_name": this._summaryList[i]["createdby_name"],
              "background_color": "#2e8bc0",
              "border_color": "#2e8bc0",
              "event_priority_id": 2,
              "event_status_id": 2,
              "is_server": 1,
              "start_string": this._summaryList[i]["start"],
              "end_string": this._summaryList[i]["end"],
              "event_id": this._summaryList[i]["event_id"],
              "schedule_instance_id": this._summaryList[i]["id"],
              "schedule_details": "",
              "created_by_id": this._summaryList[i]["created_by_id"],
              "schedule_type_id": this._summaryList[i]["schedule_type_id"],
              "email_profile_id": this._summaryList[i]["email_profile_id"],
              "sub_event_id": this._summaryList[i]["sub_event_id"],
              "start_datetime_string": this._summaryList[i]["start_datetime_string"],
              "end_datetime_string": this._summaryList[i]["end_datetime_string"]
            });

            for (let k = 0; k < P_instances[0].records.length; k++) {
              let P_starttime = "";
              let P_endtime = "";
              let P_AutomationDuration = 0;
              let P_schedulelogdetails: any = "";
              let P_AutomationStatus = 0;

              // TODO
              let P_SubEventId = P_instances[0].records[k].sub_event_id
              let P_automationsteps = [];
              if (this.automationsteps != undefined && this.automationsteps.length > 0) {
                P_automationsteps = this.automationsteps.filter((obj: any) => {
                  return (obj.scheduleid == P_instances[0].records[k].id &&
                    obj.eventid_google == P_instances[0].records[k].event_id &&
                    obj.scheduletypeid == P_instances[0].records[k].schedule_type_id);
                });
              }

              if (P_automationsteps.length > 0) {
                P_automationsteps = P_automationsteps.filter((obj: any) => {
                  var P_json = JSON.parse(obj.schedulelogdetails);
                  return (P_json[0].processid != null &&
                    P_json[0].processid != undefined &&
                    P_json[0].processid != "" &&
                    P_json[0].SubEventId == P_SubEventId);
                });
              }

              P_instances[0].automationsteps = P_automationsteps;

              var P_flag = false;
              if (P_instances[0].automationsteps.length > 0) {
                P_schedulelogdetails = JSON.parse(P_instances[0].automationsteps[0].schedulelogdetails);
                P_AutomationStatus = Number(P_schedulelogdetails[0].AutomationStatus);

                if (P_schedulelogdetails[0].AutomationDuration == 315) {
                  console.log("P_schedulelogdetails[0].AutomationDuration: ", P_schedulelogdetails[0].AutomationDuration);
                  P_flag = true;
                }

                P_AutomationDuration = Number(P_schedulelogdetails[0].AutomationDuration);
                if (P_AutomationDuration == 0) { // in case of abnormal termination of bot there will be chances that duration comes 0, in that case we will merge all steps duration
                  for (let k = 0; k < P_schedulelogdetails[0].InstanceDetails.length; k++) {
                    P_AutomationDuration += Number(P_schedulelogdetails[0].InstanceDetails[k].StepDuration);
                  }
                }
              }

              P_starttime = P_instances[0].records[k]["start"];
              P_endtime = this.calculateStepEndTime(P_starttime, P_AutomationDuration);

              if (P_flag) {
                console.log("P_starttime: ", P_starttime);
                console.log("P_endtime: ", P_endtime);
              }

              let P_title = P_instances[0].records[k]["title"];
              if (P_title.indexOf(this.P_ScheduleAddonText) > -1) {
                P_title = P_title.split(this.P_ScheduleAddonText)[0];
              }
              this.data.push({
                "id": P_instances[0].records[k]["id"],
                "title": P_title,
                "start": P_starttime,
                "end": P_endtime,
                "new_end": P_instances[0].records[k]["end"],
                "description": P_instances[0].records[k]["description"],
                "isactive": 1,
                "display": "block",
                "assignee_name": "",
                "createdby_name": P_instances[0].records[k]["createdby_name"],
                "background_color": "#2e8bc0",
                "border_color": "#2e8bc0",
                "event_priority_id": 2,
                "event_status_id": P_AutomationStatus,
                "is_server": 0,
                "start_string": P_instances[0].records[k]["start"],
                "end_string": P_instances[0].records[k]["end"],
                "event_id": P_instances[0].records[k]["event_id"],
                "schedule_instance_id": P_instances[0].records[k]["id"],
                "schedule_details": P_instances[0].records[k]["schedule_details"],
                "created_by_id": P_instances[0].records[k]["created_by_id"],
                "schedule_type_id": P_instances[0].records[k]["schedule_type_id"],
                "email_profile_id": P_instances[0].records[k]["email_profile_id"],
                "sub_event_id": P_instances[0].records[k]["sub_event_id"],
                "start_datetime_string": P_instances[0].records[k]["start_datetime_string"],
                "end_datetime_string": P_instances[0].records[k]["end_datetime_string"]
              });

              // TODO:
              if (P_instances[0].automationsteps.length > 0 &&
                P_schedulelogdetails[0].InstanceDetails.length > 0) {
                for (let j = 0; j < P_schedulelogdetails[0].InstanceDetails.length; j++) {
                  let P_StepDuration = Number(P_schedulelogdetails[0].InstanceDetails[j].StepDuration);
                  if (P_starttime == "") {
                    P_starttime = P_instances[0].records[k]["start"];
                    P_endtime = this.calculateStepEndTime(P_starttime, P_StepDuration);
                  } else {
                    P_starttime = P_endtime;
                    P_endtime = this.calculateStepEndTime(P_endtime, P_StepDuration);
                  }

                  this.data.push({
                    "id": P_schedulelogdetails[0].InstanceDetails[j].StepSerial,
                    "title": P_schedulelogdetails[0].InstanceDetails[j].StepName,
                    "start": P_starttime,
                    "end": P_endtime,
                    "new_end": "",
                    "description": P_schedulelogdetails[0].InstanceDetails[j].StepDescription,
                    "isactive": 1,
                    "display": "block",
                    "assignee_name": "",
                    "createdby_name": "",
                    "background_color": "#2e8bc0",
                    "border_color": "#2e8bc0",
                    "event_priority_id": 2,
                    "event_status_id": P_schedulelogdetails[0].InstanceDetails[j].StepStatus,
                    "is_server": 2,
                    "start_string": P_starttime,
                    "end_string": P_endtime,
                    "event_id": this._summaryList[i]["event_id"],
                    "schedule_instance_id": this._summaryList[i]["id"],
                    "schedule_details": P_schedulelogdetails[0].InstanceDetails[j].StepDescription,
                    "created_by_id": this._summaryList[i]["created_by_id"],
                    "schedule_type_id": this._summaryList[i]["schedule_type_id"],
                    "email_profile_id": this._summaryList[i]["email_profile_id"],
                    "sub_event_id": this._summaryList[i]["sub_event_id"],
                    "start_datetime_string": this._summaryList[i]["start_datetime_string"],
                    "end_datetime_string": this._summaryList[i]["end_datetime_string"]
                  });
                }
              }
            }
          }

        }
      } else {
        /*
        for (let i = 0; i < this._summaryList.length; i++) {
  
          let P_StartTime: any = this._summaryList[i]["start"];
          let P_NewEnd: any = this._summaryList[i]["new_end"];
          let P_EndTime: any = this._summaryList[i]["end"];
          let P_title = this._summaryList[i]["title"]??"";
          if (P_title.indexOf(this.P_ScheduleAddonText) > -1) {
            P_title = P_title.split(this.P_ScheduleAddonText)[0];
          }
          if (!P_title) {
            //debugger;
          }
          let P_automationstatus = 0;
  
          let P_automationsteps = [];
  
          if (this.automationsteps.length > 0) {
            P_automationsteps = this.automationsteps.filter((obj: any) => {
              return (obj.scheduleid == this._summaryList[i]["id"] &&
                obj.eventid_google == this._summaryList[i]["event_id"] &&
                obj.scheduletypeid == this._summaryList[i]["schedule_type_id"] &&
                obj.subeventid == this._summaryList[i]["sub_event_id"]);
            });
          }
          if (P_automationsteps.length > 0) {
            P_automationstatus = P_automationsteps[0].status;
          }
  
          this.data.push({
            "id": this._summaryList[i]["id"],
            "title": P_title,
            "start": P_StartTime,
            "end": P_NewEnd,
            "new_end": P_EndTime,
            "description": this._summaryList[i]["description"],
            "isactive": 1,
            "display": "block",
            "assignee_name": "",
            "createdby_name": this._summaryList[i]["createdby_name"],
            "background_color": "#2e8bc0",
            "border_color": "#2e8bc0",
            "event_priority_id": 2,
            "event_status_id": P_automationstatus,
            "is_server": 0,
            "start_string": this._summaryList[i]["start"],
            "end_string": this._summaryList[i]["end"],
            "event_id": this._summaryList[i]["event_id"],
            "schedule_instance_id": this._summaryList[i]["id"],
            "schedule_details": this._summaryList[i]["schedule_details"],
            "created_by_id": this._summaryList[i]["created_by_id"],
            "schedule_type_id": this._summaryList[i]["schedule_type_id"],
            "email_profile_id": this._summaryList[i]["email_profile_id"],
            "sub_event_id": this._summaryList[i]["sub_event_id"],
            "start_datetime_string": this._summaryList[i]["start_datetime_string"],
            "end_datetime_string": this._summaryList[i]["end_datetime_string"]
          });
        }
        */
        this.data = this._summaryList;
      }

      this.calenderHeight = window.innerHeight - 180;
      this.lastviewName = 'dayGridMonth';

      if (this.calendarOptions == undefined)
      {
        this.calendarOptions = {
          timeZone: 'local',
          initialView: this.lastviewName,
          height: this.calenderHeight,
          themeSystem: 'standard',
          plugins: [dayGridPlugin, interactionPlugin],
          dayMaxEvents: 3,
          navLinks: true,
          expandRows: true,
          dayMaxEventRows: true,
          fixedWeekCount: false,
          showNonCurrentDates: false,
          nowIndicator: true,
          //allDay: false,
          defaultTimedEventDuration: '00:01',
          nextDayThreshold: '0:00:00',
          //allDaySlot: false,
          scrollTime: '10:00:00',
          headerToolbar: {
            start: 'prev,next today addInstanceCustomButton',
            center: 'title',
            end: 'dayGridDay,dayGridWeek,dayGridMonth'
          },
          views: {
            dayGridMonth: {
              titleFormat: { year: 'numeric', month: 'long' }
            },
            timeGridWeek: {
              titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
            },
            timeGridDay: {
              titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
            }
          },
          buttonText: {
            today: 'Today',
            dayGridMonth: 'Month',
            timeGridWeek: 'Week',
            timeGridDay: 'Day'
          },
          datesSet: this.onChangeDayWeekMonth.bind(this),
          customButtons: {
            addInstanceCustomButton: {
              text: 'Add Instance',
              click: this.onAddInstance.bind(this)
            }
          },
          eventClick: this.onEditInstance.bind(this),
          events: this.data,
          eventContent: this.renderEventContent.bind(this),
          eventOrder: "default",
          eventDidMount: this.eventDidMount.bind(this),
          displayEventTime: true,
          displayEventEnd: true,
          eventTimeFormat: {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          }
        };
      }

     else {
        //this.calendarOptions = {
        //  ...this.calendarOptions,
        //  initialView: this.lastviewName,
        //  events: this.data
        //};
        //this.changeDetectorRef.detectChanges();

        // Update only the events
        //this.calendarOptions.initialView = this.lastviewName;
        //this.calendarOptions.events = this.data;
        //this.calendarOptions = { ...this.calendarOptions }; // Trigger change detection
        //this.changeDetectorRef.detectChanges();

        // Only update events
        const calendarApi = this.calendarRef.getApi();
         
        // Clear existing events
        let curEvents = calendarApi.getEvents();
        let sub_event_ids = curEvents.map(w => { return w._def.extendedProps.sub_event_id });

        let newEvent = this.data.filter(function (item) {
          return sub_event_ids.indexOf(item.sub_event_id) === -1;
        });

        for (let i = 0; i < newEvent.length; i++) {
          calendarApi.addEvent(newEvent[i]);
        }
        console.log("Length: " + newEvent.length)

        // Add new events
        //this.data.forEach((event: any, indx: number) => {           
        //  calendarApi.addEvent(event);           
        //});
      }
    }
  }

  showContextMenu(event: MouseEvent, calendarEventInfo: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': calendarEventInfo };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  async onAdhocRun(item: any) {
    //var P_data = this._summaryList.filter((obj) => {
    //  return (this.selectedEvent.schedule_instance_id == obj.id.toString())
    //});
    //let P_dataDescription = JSON.parse(P_data[0].description);

    this.contextMenuVisible = false;

    // for start date and start time
    //let dlgRef = this.dialog.open(AdhocrunActionComponent,
    //  {
    //    disableClose: true,
    //    autoFocus: false,
    //    data: {
    //      "schedule_instance_id": this.selectedEvent.schedule_instance_id,
    //      "event_id": this.selectedEvent.event_id,
    //      "schedule_type_id": this.selectedEvent.schedule_type_id,
    //      "server_ip": P_dataDescription[0].ServerIP,
    //      "server_name": P_dataDescription[0].ServerName,
    //      "automation_id": P_dataDescription[0].AutomationId,
    //      "automation_name": P_dataDescription[0].AutomationName,
    //      "automation_location": P_dataDescription[0].AutomationLocation
    //    },
    //    panelClass: 'confirm-dialog-container',
    //    width: '470px'
    //  }
    //);
    //var Action_result = await dlgRef.afterClosed().toPromise();
    //if (Action_result) {
    //  this.getAutomationSummary.emit(this.summaryInputJSON);
    //}
    this.selectedEvent = item;
    this.contextMenuVisible = false;
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = this.getActionInputJSON(1, null);
    data.message = 'Are you sure you want to adhoc run?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.isLoading = true;

      this.botDashboardService.setScheduleLog(request).then((res: any) => {
        this.isLoading = false;
        data.message = res[0]?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        //this.summaryInputJSON.resetData = 1;
        this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 1 });
      })
    }
  }

  async onForceQuite(item: any) {
    this.selectedEvent = item;
    this.contextMenuVisible = false;
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = this.getActionInputJSON(2, null);
    data.message = 'Are you sure you want to force quit?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.isLoading = true;

      this.botDashboardService.setScheduleLog(request).then((res: any) => {
        this.isLoading = false;
        data.message = res[0]?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        //this.summaryInputJSON.resetData = 1;
        this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 1 });
      })
    }
  }

  getActionInputJSON(type: number, Action_result: any) {

    var P_data = this._summaryList.filter((obj) => {
      return (this.selectedEvent.schedule_instance_id == obj.id.toString())
    });
    let P_dataDescription = JSON.parse(P_data[0].description);

    let request: any = [];

    let P_AdhocRun = false;
    let P_ForceQuit = false;
    if (type == 1) {
      P_AdhocRun = true;
      P_ForceQuit = false;
    } else if (type == 2) {
      P_AdhocRun = false;
      P_ForceQuit = true;
    }

    request = [{
      "ScheduleLogID": -1,
      "ScheduleID": this.selectedEvent.schedule_instance_id,
      "EventID_google": this.selectedEvent.event_id,
      "Status": 0,
      "ScheduleLogDetails": JSON.stringify([
        {
          "AdhocRun": P_AdhocRun,
          "ForceQuit": P_ForceQuit,
          "ServerIP": P_dataDescription[0].ServerIP,
          "ServerName": P_dataDescription[0].ServerName,
          "AutomationId": P_dataDescription[0].AutomationId,
          "AutomationName": P_dataDescription[0].AutomationName,
          "AutomationLocation": P_dataDescription[0].AutomationLocation
        }
      ]),
      "ScheduleTypeID": this.selectedEvent.schedule_type_id,
      subeventid: this.selectedEvent.sub_event_id
    }];

    return request;
  }

  getInstanceDetails(fulldata: any, rowdata: any, P_serverIp: string) {
    let P_servername = JSON.parse(rowdata.description)[0].ServerName;
    let P_StartDate = rowdata.start.split('T')[0];
    let P_automationid = JSON.parse(rowdata.description)[0].AutomationId;
    let P_SubEventId = rowdata.sub_event_id;
    let P_records = fulldata.filter((obj: any) => {
      return (obj.description.indexOf(P_serverIp) > -1 &&
        P_StartDate == obj.start.split('T')[0]);
    });


    let P_automationsteps = [];
    if (this.automationsteps != null && this.automationsteps != undefined && this.automationsteps.length > 0) {
      P_automationsteps = this.automationsteps.filter((obj: any) => {
        return (obj.scheduleid == rowdata.id &&
          obj.eventid_google == rowdata.event_id &&
          obj.scheduletypeid == rowdata.schedule_type_id);
      });
    }

    return [
      {
        "servername": P_servername,
        "serverip": P_serverIp,
        "records": P_records,
        "automationsteps": P_automationsteps
      }
    ]

  }

  calculateStepEndTime(P_starttime: any, P_automationsteps: any) {
    // Original ISO 8601 date-time string
    const isoString = P_starttime;

    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Add 60 seconds to the time
    date.setSeconds(date.getSeconds() + P_automationsteps);

    //date.setMinutes(date.getMinutes() + P_automationsteps);

    // Convert the Date object back to an ISO string
    //const newIsoString = date.toISOString();
    const newIsoString = this.formatDate(date);

    return newIsoString;
  }

  GetSystemTimeZone(P_DateTime: any) {
    const utcDate = new Date(P_DateTime);
    const systemTimezoneOffset = utcDate.getTimezoneOffset() * 60000; // Offset in milliseconds
    const systemTimestamp = utcDate.getTime() + systemTimezoneOffset;
    const systemDate = new Date(systemTimestamp);
    //const formattedSystemDate = this.datePipe.transform(systemTimestamp, "yyyy-MM-ddTHH:mm:ss.SSS", "IST");
    const formattedSystemDate = systemDate.toISOString();
    return formattedSystemDate;
  }

  async onChangeDayWeekMonth(arg: any) {
    if (!this.input4) {
      this.input4 = true;
      return;
    }

    //this.P_showCalendar = false;

    let startDate: any = new Date(arg.start);
    let utcDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    startDate = utcDate.toISOString().slice(0, 10);
    let endDate: any = new Date(arg.end);
    utcDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
    endDate = utcDate.toISOString().slice(0, 10);
    this.bindCurrentMonthData(startDate, endDate);
  }

  async bindCurrentMonthData(startDate: any, endDate: any) {
    //this.P_showCalendar = false;
    if (startDate == "") {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      startDate = GetFormattedDate(firstDay);
      endDate = GetFormattedDate(lastDay);
    }
    this.resetSummaryInputJSON(startDate, endDate);
    this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 0 });

    //if (this.scheduleType != 1) {
    //  return;
    //}

    //const scheduleLogsRequestJson = [{
    //  ScheduleID: -1,
    //  EventID_google: -1,
    //  Status: -1,
    //  ScheduleTypeID: this.scheduleType,
    //  pagesze: 1000,
    //  pagestartrecord: 0,
    //  fromdate: startDate + ' 01:01:01',
    //  todate: endDate + ' 01:01:01',
    //  SubEventId: '-1'
    //}];
    //this.automationsteps = await this.botDashboardService.getScheduleLog(scheduleLogsRequestJson)
    this.setCalendarOption();
  }

  resetCalendarOptions() {
    this.calendarOptions = {
      ...this.calendarOptions,
      // Reset or update your options here
    };
  }

  formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    const timezoneOffset = -date.getTimezoneOffset();
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;
  }

  resetSummaryInputJSON(P_fromdate: string = "", P_todate: string = "") {

    let startDate = "";
    let endDate = "";

    if (P_fromdate != "" &&
      P_todate != "") {
      startDate = P_fromdate;
      endDate = P_todate;
    } else {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      startDate = GetFormattedDate(firstDay);
      endDate = GetFormattedDate(lastDay);
    }

    this.summaryInputJSON = {
      schedule_instance_id: "-1",
      eventid_google: "-1",
      scheduletypeid: this._scheduleType,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: startDate,
      todate: endDate
    }
  }

  updateCalendarEvents() {
    //const calendarApi = this.calendarComponent.getApi();
    //calendarApi.removeAllEvents(); // Clear existing events
    //calendarApi.addEventSource(this.data); // Add new events
  }


  async getLookupData() {
    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    if (this.filterData.length == 0) {
      let res = await this.clientService.getreportfilter(request);
      this.filterData = res;
    }

    const locationRequest = [{
      clientid: '',
      uniqueservicelocationid: -1,
      pagesize: 10000,
      pagestartrecord: 0,
    }]
    const providerRequest = [{
      clientid: '',
      uniqueservicingproviderid: -1,
      pagesize: 10000,
      pagestartrecord: 0,
    }]
    let loopupRequest: any[] = [];
    //const lookups = await Promise.all([
    //this.clientService.getlookup([]),
    //this.clientService.getselectors([]),
    //this.clientService.getServiceLocations(locationRequest),
    //this.clientService.getServicingProviders(providerRequest),
    //this.clientService.GetPrimaryPayorGroups([{clientid: '-1', uniqueprimarypayorgroupid: -1,pagesize: 10000, pagestartrecord: 0}]),
    //this.clientService.GetPrimaryPayors([{clientid: '-1', uniqueprimarypayorid: -1,pagesize: 10000 , pagestartrecord: 0}]),
    //]);

    let filter: number[] = [6, 7, 8, 9];

    [3, 4, 6, 7, 8, 9].forEach(w => {
      switch (w) {
        case ReportFilters.Practice: {
          if ((this.lookupData.clients?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.getlookup([]))
          }
          else {
            loopupRequest.push(this.lookupData.clients ?? [])
          }
          break;
        }
        case ReportFilters.Selector: {
          if ((this.lookupData.selector?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.getselectors([]))
          }
          else {
            loopupRequest.push(this.lookupData.selector ?? [])
          }
          break;
        }
        case ReportFilters.Servicelocation: {
          if ((this.lookupData.serviceLocation?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.getServiceLocations(locationRequest))
          }
          else {
            loopupRequest.push(this.lookupData.serviceLocation ?? [])
          }
          break;
        }
        case ReportFilters.ServiceProvider: {
          if ((this.lookupData.serviceProvider?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.getServicingProviders(providerRequest));
          }
          else {
            loopupRequest.push(this.lookupData.serviceProvider ?? [])
          }
          break;
        }
        case ReportFilters.PayorGroups: {
          if ((this.lookupData.payorGroups?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.GetPrimaryPayorGroups([{ clientid: '-1', uniqueprimarypayorgroupid: -1, pagesize: 10000, pagestartrecord: 0 }]));
          }
          else {
            loopupRequest.push(this.lookupData.payorGroups ?? [])
          }
          break;
        }
        case ReportFilters.Payor: {
          if ((this.lookupData.payors?.length ?? 0) == 0) { // && this.displayFilters(w.toString())) {
            loopupRequest.push(this.clientService.GetPrimaryPayors([{ clientid: '-1', uniqueprimarypayorid: -1, pagesize: 10000, pagestartrecord: 0 }]));
          }
          else {
            loopupRequest.push(this.lookupData.payors ?? [])
          }
          break;
        }
      }
    });

    const lookups = await Promise.all(loopupRequest);
    this.lookupData = {
      clients: lookups[0]?.map((s: any) => { return { code: s.clientid ?? s.code, description: s.clientname ?? s.description, active: true } }),
      selector: lookups[1],
      serviceLocation: lookups[2]?.filter((s: any) => { return s.servicelocationname ?? s.description })?.map((s: any) => { return { code: s.uniqueservicelocationid ?? s.code, description: s.servicelocationname ?? s.description, active: true, clientid: s.clientid } }),
      serviceProvider: lookups[3]?.filter((s: any) => { return s.servicingprovidername ?? s.description })?.map((s: any) => { return { code: s.uniqueservicingproviderid ?? s.code, description: s.servicingprovidername ?? s.description, active: true, clientid: s.clientid } }),
      payorGroups: lookups[4]?.filter((s: any) => { return s.primarypayorgroupname ?? s.description })?.map((s: any) => { return { code: s.uniqueprimarypayorgroupid ?? s.code, description: s.primarypayorgroupname ?? s.description, active: true, clientid: s.clientid } }),
      payors: lookups[5]?.filter((s: any) => { return s.primarypayorname ?? s.description })?.map((s: any) => { return { code: s.uniqueprimarypayorid ?? s.code, description: s.primarypayorname ?? s.description, active: true, clientid: s.clientid } }),
      relativeDateOperators: [{ code: '0', description: 'Last', active: 1 }, { code: '1', description: 'This', active: 1 }],
      dateFilterTimeUnits: this.getDateFilterTimeUnit()
    }

    this.lookupData.clients = (this.lookupData.clients ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.serviceLocation = (this.lookupData.serviceLocation ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.serviceProvider = (this.lookupData.serviceProvider ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.payorGroups = (this.lookupData.payorGroups ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });
    this.lookupData.payors = (this.lookupData.payors ?? []).sort((a: any, b: any) => {
      return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
    });

    //this.lookup1 = true;
    //this.hideLoader();
  }

  displayFilters(id: string) {
    if (this.filterData) {
      if (!id)
        return this.filterData?.findIndex((w: any) => { return w.reportId == this.reportId }) > -1;
      else
        return this.filterData?.findIndex((w: any) => { return w.reportId == this.reportId && w.filterId == id }) > -1;
    }
    return true;

  }

  getDateFilterTimeUnit() {
    let data: any[] = [
      { code: '0', description: 'Days', active: 1 },
      { code: '1', description: 'Weeks', active: 1 },
      { code: '2', description: 'Calendar Weeks', active: 1 },
      { code: '3', description: 'Months', active: 1 },
      { code: '4', description: 'Calendar Months', active: 1 },
      { code: '5', description: 'Years', active: 1 },
      { code: '6', description: 'Calendar Years', active: 1 },
      //{ code: '7', description: 'Minutes', active: 1 },
      //{ code: '8', description: 'Hours ', active: 1 },
    ];
    return data;
  }

  //hideLoader() {
  //  if (this.lookup1 &&
  //    this.lookup2 &&
  //    this.scheduleType == 2) {
  //    this.isLoading = false;
  //  } else if (this.scheduleType != 2) {
  //    //this.isLoading = false;
  //  }
  //
  //}
  async getLookupDataPayroll() {
    if (!this.isLoadinglookupsData) {
      if ((this.lookupData?.practices?.length ?? 0) == 0 && this._scheduleType == 4) {
        this.isLoadinglookupsData = true;
        //this.isLoading = true;
        const request = [{
          id: -1,
          statusid: -1,
          pagesize: 500,
          pagestartrecord: 0
        }]
        const locationRequest = [{
          clientid: '',
          uniqueservicelocationid: -1,
          pagesize: 10000,
          pagestartrecord: 0,
        }]
        const providerRequest = [{
          clientid: '',
          uniqueservicingproviderid: -1,
          pagesize: 10000,
          pagestartrecord: 0,
        }]
        const lookups = await Promise.all([
          this.clientService.getlookup([]),
          this.clientService.getServiceLocations(locationRequest),
          this.clientService.getServicingProviders(providerRequest),
          this.payrollService.GetCptTypesLookup([]),
        ]);
        this.lookupData = {
          practices: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
          dateType: [{ code: '1', description: 'Accounting' }, { code: '2', description: 'DOS' }, { code: '3', description: 'Transaction Date' }, { code: '4', description: 'DOE' }],
          runOn: [{ code: 'last30', description: 'Last 30 Days' }, { code: 'lastmonth', description: 'Last Month' }, { code: 'lastweek', description: 'Last week' }, { code: 'last7', description: 'Last 7 days' }],
          runAs: [{ code: '0', description: 'Manual' }, { code: '1', description: 'Schedule' }],
          excludeCpts: [{ code: '63052', description: '63052', isCustom: false }, { code: '63051', description: '63051', isCustom: false }],
          asacodes: [{ code: 'addnew', description: 'Add New', isCustom: true }],
          modifiers: this.modifiers,
          workingDays: [{ code: 'addnew', description: 'Add New', isCustom: true }],
          providerType: [{ code: '0', description: 'Servicing' }, { code: '1', description: 'Directing' }],
          occurances: [{ code: '1', description: '1' }, { code: '2', description: '2' }, { code: '3', description: '3' }, { code: '4', description: '4' }, { code: '5', description: '5' }],
          serviceLocation: lookups[1]?.filter((s: any) => { return s.servicelocationname ?? s.description })?.map((s: any) => { return { code: s.uniqueservicelocationid, description: s.servicelocationname, active: true, clientid: s.clientid } }),
          serviceProvider: lookups[2]?.filter((s: any) => { return s.servicingprovidername ?? s.description })?.map((s: any) => { return { code: s.uniqueservicingproviderid ?? s.code, description: s.servicingprovidername ?? s.description, active: true, clientid: s.clientid } }),
          CPTtypes: lookups[3]?.filter((s: any) => { return s.cpttypedescription ?? s.description })?.map((s: any) => { return { code: s.cpttypeid?.toString() ?? s.code, description: s.cpttypedescription?.toString() ?? s.description, active: true} }),
        }
        this.isLoadinglookupsData = false;
        //this.isLoading = false;
      }
    }
    do {

    } while (this.isLoadinglookupsData);
  }
  get modifiers() {
    return ["22", "24", "25", "26", "27", "33", "50", "51", "59", "95"].map(s => { return { code: s, description: s, isCustom: false } })
  }

  async getLookupDataReport() {
    if ((this.lookupData?.clients?.length ?? 0) == 0 && this._scheduleType == 2) {
      this.isLoading = true;
      let menuRequestJson = [{ // get reports menu
        menuids: -1,
        loginuserid: -1,
        portalid: 1
      }];
      const reportFilterRequestJson = [{
        id: -1,
        statusid: -1,
        pagesize: 500,
        pagestartrecord: 0
      }];

      await this.getLookupData();
      this.lookups = await Promise.all([
        this.botDashboardService.getEmailProfile([{ id: "-1" }]),
        this.userService.getMenus(menuRequestJson),
        this.clientService.getreportfilter(reportFilterRequestJson)
      ]);

      this.isLoading = false;
    }
  }
  onFreeze(item: any) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    data.message = 'Are you sure you want to freeze?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    Confirmation(this.dialog, data).then(res => {
      if (res) {
        this.isLoading = true;
        this.payrollService.freezePayrollData(item.sub_event_id, []).then(result => {
          this.isLoading = false;
          data.message = result.data[0].errormessage;
          data.title = 'Information!';
          data.showYes = false;
          data.noText = 'OK';
          Confirmation(this.dialog, data);
        })
      }
    });
  }
  async onPreview(item: any) {
    await this.getLookupDataPayroll();
    this.isLoading = true;
    let requestObject = {
      pagesize: 10,
      pagestartrecord: 0,
      flowid: item.sub_event_id
    }
    this.payrollService.getPayrollData(requestObject).then(res => {
      let dlgRef = this.dialog.open(PayrollPreviewDialogComponent,
        {
          disableClose: true,
          autoFocus: false,
          data: {
            scheduleType: this._scheduleType,
            lookups: this.lookups,
            lookupData: this.lookupData,
            filters: this.filters,
            filterData: this.filterData,
            reportId: this.reportId,
            data: res?.data ?? []
          },
          height: ((this._scheduleType == 4) ? '80vh' : 'auto'),
          panelClass: 'confirm-dialog-container1',
          width: ((this._scheduleType == 4) ? '90%' : this.dialogueWidth)
        });
      this.isLoading = false;
    })
  }


  async onDelete(item: any) {
    this.selectedEvent = item;
    this.contextMenuVisible = false;
    let data: ConfirmDialogData = {} as ConfirmDialogData;

    data.message = 'Are you sure you want to delete event(s)?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    const dlgRef = this.dialog.open(DeleteEventDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: data,
        panelClass: 'confirm-dialog-container',
        width: '400px',
        height: '230px',
      });
    console.log(data)
    let res = await dlgRef.afterClosed().toPromise();
    //debugger;
    if (res.result == true) {
      this.isLoading = true;
      let request = [{
        "ScheduleLogID": -1,
        "ScheduleID": this.selectedEvent.schedule_instance_id,
        "EventID_google": this.selectedEvent.event_id,
        "Status": 0,
        "ScheduleLogDetails": JSON.stringify([]),
        "ScheduleTypeID": this.selectedEvent.schedule_type_id,
        subeventid: this.selectedEvent.sub_event_id,
        deletionType: res.data,
        untill: ((res.data == "3") ? moment(this.selectedEvent.end_string).format("yyyyMMDDTHHmmss") : moment(this.selectedEvent.start_string).format("yyyyMMDDTHHmmss")) + "Z"
      }];
      this.botDashboardService.deleteEvenet(request).then((res: any) => {
        this.isLoading = false;
        data.message = res.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        //this.summaryInputJSON.resetData = 1;
        this.getAutomationSummary.emit({ "requestData": this.summaryInputJSON, "reset": 1 });
      })
    }
  }


}
