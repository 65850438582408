import { Component, EventEmitter, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { DatePipeSafePipe } from '../../../../pipes/date-pipe-safe.pipe';
import { AuthService } from '../../../../services/auth.service';
import { getSafeDateReturnMoment } from '../../../../Utility/common-functions';
import { compare, FormatDate } from '../../../../Utility/CustomValidators.utilities';
import { ConfirmDialogData } from '../../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../../confirm-dialog/confirm-dialog.component';
import { PayrollService } from '../../payroll.service';
@Component({
  selector: 'app-payroll-preview-list',
  templateUrl: './payroll-preview-list.component.html',
  styleUrls: ['./payroll-preview-list.component.css']
})
export class PayrollPreviewListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  public sortedData = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['action', 'encounterid',
    'patientaccountnumber', 'patientname', 'dob', 'pm_system_providername',
    'pm_system_servicelocationname', 'modifiers', 'cpt', 'cptordersequence',
    'dos', 'starttime', 'endtime', 'units', 'unit_charge', 'total_charges',
    'baseunits', 'timeunits', 'providerbaseunits', 'providertimeunits', 'comments'];
  //, 'createdBy', 'Createdon', 'modifiedBy', 'modifiedOn'
  public pageSize = 10;
  public sort: Sort = {
    active: 'encounterid',
    direction: 'asc'
  }
    ;
  public _payrollList: any[] = [];
  public _data: any[] = [];
  public haspayroll_Edit: boolean = false;
  public haspayroll_Delete: boolean = false;
  public pageData: any = {
    "previousPageIndex": -1,
    "pageIndexStart": 0,
    "pageIndex": 0,
    "pageSize": 50,
    "length": 0
  }
  ngOnInit() {
    this.setPrivileges();
  }

  @Output() onEditpayroll = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @ViewChildren('clients') _clients!: MultiSelectComponent[];
  @Input() set payrollList(data: any) {
    this._payrollList = data.data;
    this._data = data;
    //this.mp.pageIndex = this.pageData.pageIndex;
    this.sortAndFilterData();
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  get payrollList() {

    return this._payrollList;
  }

  @ViewChild(MatPaginator) mp!: MatPaginator;
  //@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //  //this.sortedData.paginator = mp;

  //}

  constructor(
    private dialog: MatDialog, private auth: AuthService
    , private payrollService: PayrollService
  ) {
    this.sortedData.data = this.payrollList;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortAndFilterData() {
    let filteredData = this.payrollList.filter((w: any) => { return !w.isDeleted; })
    //skip(this.pageData.pageIndex * this.pageSize).take(this.pageSize);

    if (!this.sort) {
      this.sortedData.data = filteredData;
      return;
    }
    else if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: any, b: any) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name':
          return compare(a.practicename.toLowerCase(), b.practicename.toLowerCase(), isAsc);
        case 'encounterid':
          return compare(a.encounterid as number, b.encounterid as number, isAsc);
        case 'patientaccountnumber':
          return compare(a.patientaccountnumber, b.patientaccountnumber, isAsc);
        case 'patientname':
          return compare(a.patientname.toLowerCase(), b.patientname.toLowerCase(), isAsc);
        case 'pm_system_providername':
          return compare(a.pm_system_providername.toLowerCase(), b.pm_system_providername.toLowerCase(), isAsc);
        case 'dob':
          return compare(a.dob, b.dob, isAsc);
        default:
          return 0;
      }
    })
      .slice(this.pageData.pageIndexStart, this.pageData.pageIndexStart + 10);
  }
  DateFormate(DateString: string, formateString: string) {
    return FormatDate(DateString, formateString);
  }
  doEditUser(payroll: any) {
    this.onEditpayroll.emit(payroll);
  }

  async doDeleteUser(payroll: any) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: payroll._id,
    }]
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.payrollService.deletepayroll(payroll._id).then((res: any) => {
        data.message = res?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  async doDeactivate(payroll: any, active: number) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    let request = [{
      id: payroll._id,
    }]
    data.message = `Are you sure you want to make this queue ${((active == 0) ? "deactivate" : "activate")}?`;
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.payrollService.deactivatepayroll(payroll._id, active).then((res: any) => {
        data.message = res?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }
  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();

  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.menuFilter.openMenu();
  }

  doShowDetails(item: any) {
    if (this.haspayroll_Edit) {
      this.onEditpayroll.emit(item);
    }
  }
  setPrivileges() {
    //this.haspayroll_Edit = this.auth.hasPrivilege(PrivilegeValues.payrollManage_Edit);
    //this.haspayroll_Delete = this.auth.hasPrivilege(PrivilegeValues.payrollManage_Delete);
    this.haspayroll_Edit = true;
    this.haspayroll_Delete = true;

    if (!this.haspayroll_Edit && !this.haspayroll_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }

  public ALL_CURRENCY_REGEXP = /^-?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})*(?:\.[0-9]{1})?$/;
  public ALL_NUMBER_REGEXP = /^[0-9]/;
  onInputChange(event: any, item: any, column: string, type: string = 'txt') {
    //
    let value = event.currentTarget.value

    if (type == "number") {
      if (!this.ALL_CURRENCY_REGEXP.test(value)) {
        value = "";
      }
    }
    else if (column == "units")
      if (!this.ALL_NUMBER_REGEXP.test(value)) {
        value = "";
      }

    item[column] = value;

    item.isDirty = true;
  }
  keyPress(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0
    if (!(this.ALL_CURRENCY_REGEXP.test(inputChar) || /^(?:\.)$/.test(inputChar)) && !this.ALL_CURRENCY_REGEXP.test(value + inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
    else if (/^(?:\.)$/.test(inputChar) && value.indexOf('.') > -1) {
      event.preventDefault();
    }
    else if (!/^(?:\.)$/.test(inputChar) && !this.ALL_CURRENCY_REGEXP.test(value + inputChar)) {
      event.preventDefault();
    }
  }
  allowNumberOnly(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0
    if (!this.ALL_CURRENCY_REGEXP.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  getDateValue(date: any) {
    var sd: moment.Moment | null;
    sd = getSafeDateReturnMoment(date, true);
    if (sd) {
      return sd.toISOString();
    }
    return null;
  }
  setMonthAndYear(normalizedMonthAndYear: any, datepicker: MatDatepicker<Moment>, element: any, column: string) {
    element[column] = moment(normalizedMonthAndYear.value).format("MM/DD/YYYY");
    datepicker.close();
  }
  onSelectionChange(item: any[], element: any, column: string, ctrl: any) {
    switch (column) {
      case "practicename": {
        if (item && item.length > 0) {
          element.uniquepracticeid = item[0].code;
          element.practicename = item[0].description;
        }
        else {

        }
        break;
      }
      case "uniqueproviderid": {
        if (item && item.length > 0) {
          element.uniqueproviderid = item[0].code;
          element.pm_system_providername = item[0].description;
        }
        else {

        }
        break;
      }
      case "uniqueservicelocationid": {
        if (item && item.length > 0) {
          element.uniqueservicelocationid = item[0].code;
          element.pm_system_servicelocationname = item[0].description;
        }
        else {

        }
        break;
      }


    }
    element.isDirty = true;
  }

  castToFloat(item: any, control: string, type: string) {
    //debugger;
    //let val = parseFloat(this.formAdd.controls[control].value);
    //if (type == 'int') {
    //  val = parseInt(this.formAdd.controls[control].value);
    //}

    //if (isNaN(val)) {
    //  this.formAdd.controls[control].setValue('0');
    //}
    //else if (val > 100) {
    //  this.formAdd.controls[control].setValue('0');
    //}
    //else {
    //  this.formAdd.controls[control].setValue(val);
    //}
  }
  onAutoSelectionChange(item: any, element: any, column: string) {
    switch (column) {
      case "cpt": {
        if (item) {
          element.cpt = item.code;
          element.cptdescription = item.description;
        }
        else {

        }
        element.isDirty = true;
        break;
      }
      case "patientname": {
        if (item) {
          element.patientaccountnumber = item.code;
          element.patientname = item.description;
        }
        else {

        }
        element.isDirty = true;
        break;
      }
    }
  }
  onDateTimeSelectionChange(item: any, element: any, column: string) {
    element[column] = item;
  }
  doAction(item: any, type: string) {
    switch (type) {
      case "add": {
        this.payrollList.push({
          "practicename": item.practicename,
          "fiscalyeardatetype": item.fiscalyeardatetype,
          "fiscalyear": item.fiscalyear,
          "practiceid": item.practiceid,
          "warehouseuniqueidentifier": item.warehouseuniqueidentifier,
          "uniquepracticeid": item.uniquepracticeid,
          "encounterid": item.encounterid,
          "flowid": item.flowid,
          "patientaccountnumber": "",
          "patientname": "",
          "dob": "",
          "uniqueproviderid": -1,
          "pm_system_providerid": null,
          "pm_system_providername": "",
          "pm_system_providerordersequence": item.pm_system_providerordersequence,
          "uniqueservicelocationid": -1,
          "pm_system_servicelocationid": null,
          "pm_system_servicelocationname": null,
          "pm_system_charge_id": item.pm_system_charge_id,
          "cpt": "",
          "cptdescription": "",
          "cptordersequence": 0,
          "dos": item.dos,
          "starttime": "",
          "endtime": "",
          "modifiers": "",
          "unit_charge": null,
          "units": null,
          "total_charges": null,
          "baseunits": null,
          "timeunits": null,
          "providerbaseunits": null,
          "providertimeunits": null,
          "createdon": moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          rowtype: 1,
          isDirty: true
        })
        break;
      }
      case "copy": {

        let newItem = JSON.parse(JSON.stringify(item));
        newItem.rowtype = 1;
        newItem.isDirty = true;
        this.payrollList.push(JSON.parse(JSON.stringify(newItem)));
        break;
      }
      case "refund": {
        let newItem = JSON.parse(JSON.stringify(item));
        newItem.unit_charge = (item.unit_charge) ? item.unit_charge * -1 : item.unit_charge
        newItem.units = (item.units) ? item.units * -1 : item.units
        newItem.total_charges = (item.total_charges) ? item.total_charges * -1 : item.total_charges
        newItem.baseunits = (item.baseunits) ? item.baseunits * -1 : item.baseunits
        newItem.timeunits = (item.timeunits) ? item.timeunits * -1 : item.timeunits
        newItem.providerbaseunits = (item.providerbaseunits) ? item.providerbaseunits * -1 : item.providerbaseunits
        newItem.providertimeunits = (item.providertimeunits) ? item.providertimeunits * -1 : item.providertimeunits
        newItem.createdon = moment().format("YYYY-MM-DDTHH:mm:ssZ")
        newItem.rowtype = 1;
        newItem.isDirty = true;
        this.payrollList.push(newItem);
        break;
      }
      case "delete": {
        item.isDeleted = true;
        break;
      }

    }
    this.sortAndFilterData();
  }
  doPageChange(item: any) {
    this.pageData = item;
    this.pageData.pageIndexStart = this.pageData.pageIndex * this.pageSize;
    this.sortAndFilterData();
  }
}
