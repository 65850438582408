import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})

export class PayrollService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'payroll';
  constructor(private http: HttpClient) { }
  public async getNodes(request: any[]): Promise<any> {
    let result: any[] = [
      //{
      //  id: 0,
      //  name: 'Start',
      //  canDrag: true, allowMultiple: true,
      //  inputs: 0,
      //  outputs: 1,
      //  infos: { name: 'Start' },
      //  inputdata: {
      //    Run_Mode: 1,
      //    scheduleStart: 1,
      //    scheduleEnd: 1,
      //    ScheduleEvery: 1,
      //  },
      //  filters: {
      //    runAs: [{ code: '1', description: 'Manual' }]
      //  },
      //  active:0

      //},
      {
        id: 1,
        name: 'Payroll_FilterData',
        canDrag: true,
        allowMultiple: false,
        category: 1,
        inputs: 0,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Filter Data',
          desc: 'It will used to filter primary data and insert data in permanent table'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 1,
          datetype: 1,
          runOn: 1,
          fromdate: 1,
          todate: 1,
          flowId: 3,
        }
      },
      {
        id: 2,
        name: 'Payroll_ReduceBaseunits',
        canDrag: true, allowMultiple: true,
        category: 2,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Reduce Base Units',
          desc: 'It will reduce the base units based on the input filter'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          reducepercentage: 1,
          excludecptCodes: 0,
          providerOrderSequence: 0,  /* multiple input*/
          providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
          Isfirststep: 3, /* to identify if it firststep in flow  1 for first else 0 */
          comments: 0
        }
      },
      {
        id: 3,
        name: 'Payroll_UpdateCustomBaseunits',
        inputs: 1,
        outputs: 1,
        type: 1,
        canDrag: true, allowMultiple: true,
        infos: {
          name: 'Update Custom Base Units',
          desc: 'It will update  the custom base units based on input filter if charges are negative then it will be multiply by -1.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0,/* multiple input cptcode|units,cptcode|units*/
          providerOrderSequence: 0,
          providerTypeId: 0,/*servicing or directing*/
          isanesthesia: 1, /* 1 for anesthesia  and 0 for non anesthesia rows */
          defaultunits: 0, /* should be used when we need to update hardcode value */
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          /*usecptcodesfromform: 4,*/
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0,
          comments: 0
        }
      },
      {
        id: 23,
        name: 'Payroll_UpdateCustomBaseunitsbyPercentage',
        inputs: 1,
        outputs: 1,
        type: 1,
        canDrag: true, allowMultiple: true,
        infos: {
          name: 'Update Custom Base Units/Percentage',
          desc: 'It will update  the custom Time units percentage based on input filter if charges are negative then it will be multiply by -1.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0,/* multiple input cptcode|units,cptcode|units*/
          providerOrderSequence: 0,
          providerTypeId: 0,/*servicing or directing*/
          isanesthesia: 1, /* 1 for anesthesia  and 0 for non anesthesia rows */
          defaultpercentage: 0,
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          /*usecptcodesfromform: 4,*/
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0
          , comments: 0
        }
      },
      {
        id: 4,
        name: 'Payroll_NoBasePoints',
        inputs: 1,
        outputs: 1,
        type: 1,
        canDrag: true, allowMultiple: true,
        infos: {
          name: 'No Base Points',
          desc: 'It will update 0 to base units .'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          uniqueServicelocationid: 1, /* multiple input*/
          providerOrderSequence: 0,
          providerTypeId: 0,/*servicing or directing*/
          cptCodes: 0, /* 1 for anesthesia  and 0 for non anesthesia rows */
          asaCodes: 0, /* should be used when we need to update hardcode value */
          excludeoccurances: 0, /*multiple input*/
          includeoccurances: 0, /*multiple input*/
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0
          , comments: 0
        }
      },
      {
        id: 5,
        name: 'Payroll_DistributeProviderBaseUnitsEqually',
        inputs: 1,
        canDrag: true, allowMultiple: true,
        outputs: 1,
        category: 2,
        type: 1,
        infos: {
          name: 'Distribute Provider Base Units Equally',
          desc: 'Used to distribute base unite between provider equally.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          providerTypeId: 0,/*servicing or directing*/
          excludecptCodes: 0,
          Isfirststep: 3,
          comments: 0
        }
      },
      {
        id: 6,
        name: 'Payroll_DistributeProviderBaseUnitsBasedonPerMinutesSpend',
        canDrag: true, allowMultiple: true,
        category: 2,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Distribute Provider Base Units/Minutes',
          desc: 'used to distribute based on minutes spend using baseunits *(minutes spend /totalminutes on cpt).'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          excludecptCodes: 0, /*multiplt values*/
          uniqueServicelocationid: 0, /* multiple input*/
          Isfirststep: 3,
          comments: 0
        }
      },
      {
        id: 7,
        name: 'Payroll_ProviderNoBaseUnitsForBlackOutPeriod',
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Provider No Base Units For Blackout Period',
          desc: 'This will check the starttime and end time should lie between working hours if not then we will mark it as black out and don\'t give credit for that provider'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0,  /*multiplt values*/
          excludecptCodes: 0, /*multiplt values*/
          uniqueServicelocationid: 0, /* multiple input*/
          workingDayswithTime: 1 /*PM_System_UniqueProviderId|Daysofweek|starttime|endtime, example 0|07:00|17:00  days of week like for sunday 0, for monday 1, for tuesday 2 and so on */,
          isanesthesia: 1 /* 1 for anesthesia  and 0 for non anesthesia rows */,
          useWorkingdaysfromform: 1,
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          comments: 0
        }
      },
      {
        id: 8,
        name: 'Payroll_DistributeTimeUnits',
        category: 3,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Distribute Time Units.',
          desc: 'Used to distribute Time units based on the conversion factor as input.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          distributeTimeUnitsFactor: 1,
          providerTypeId: 0,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          Isfirststep: 3, /* to identify if it firststep in flow  1 for first else 0 */
          distributetimeunitsusingcapping: 0,
          comments: 0
        }
      },
      {
        id: 10,
        name: 'Payroll_NoTimePoints',
        category: 3,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'No Time Points',
          desc: 'It will update 0 to time  units.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          uniqueServicelocationid: 0, /* multiple input*/
          providerOrderSequence: 0,
          providerTypeId: 0,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0, /* should be used when we need to update hardcode value */
          excludeoccurances: 0, /*multiple input*/
          includeoccurances: 0, /*multiple input*/
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0,
          comments: 0
        }
      },

      {
        id: 11,
        name: 'Payroll_DistributeTimeUnitsForOverTime',
        category: 3,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 2,
        infos: {
          name: 'Distribute Time Units For OverTime',
          desc: ' Used to distribute Time units based on the conversion factor as input and over time.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          RegulardistributeTimeUnitsFactor: 1, /* value which need to be use for distribution factor */
          OverTimedistributeTimeUnitsFactor: 1, /* value which need to be use for distribution factor */
          OverTimeStart: 1,// --over time satrt time
          OvertimeEnd: 1, //--over time end time
          providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          p_Isfirststep: 3, /* to identify if it firststep in flow  1 for first else 0 */
          comments: 0
        }
      },
      {
        id: 12,
        name: 'Payroll_UpdateCustomTimeunits',
        category: 1,
        canDrag: true, allowMultiple: true,
        type: 1,
        inputs: 1,
        outputs: 1,
        infos: {
          name: 'Update Custom Time Units',
          desc: 'It will update  the custom base units based on input filter if charges are negative then it will be multiply by -1.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          defaultunits: 0, /* should be used when we need to update hardcode value */
          providerOrderSequence: 0,  /* multiple input*/
          providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
          isAnesthesia: 1, /* 1 for anesthesia  and 0 for non anesthesia rows */
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          usecptcodesfromform: 4,
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0,
          comments: 0
        }
      },
      {
        id: 24,
        name: 'Payroll_UpdateCustomTimeunitsbyPercentage',
        category: 1,
        canDrag: true, allowMultiple: true,
        type: 1,
        inputs: 1,
        outputs: 1,
        infos: {
          name: 'Update Custom Time Units/Percentage',
          desc: 'It will update  the custom base units based on input filter if charges are negative then it will be multiply by -1.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          defaultpercentage: 0,
          providerOrderSequence: 0,  /* multiple input*/
          providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
          isAnesthesia: 1, /* 1 for anesthesia  and 0 for non anesthesia rows */
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          usecptcodesfromform: 4,
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0,
          comments: 0
        }
      },
      {
        id: 13,
        name: 'Payroll_DistributeProviderTimeUnitsBasedonPerMinutesSpend',
        category: 3,
        type: 1,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        infos: {
          name: 'Distribute Provider Time Units Based On Minutes Spend',
          desc: 'Used to distribute time units between provider based on percentage spend.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          excludecptCodes: 0, /*multiplt values*/
          uniqueServicelocationid: 0, /* multiple input*/
          Isfirststep: 3,
          comments: 0
        }
      },
      {
        id: 14,
        name: 'Payroll_ProviderNoTimePoints',
        category: 3,
        type: 1,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        infos: {
          name: 'Provider No Time Points',
          desc: 'It will update 0 to time  units.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          uniqueServicelocationid: 0, /* multiple input*/
          providerOrderSequence: 0,
          providerTypeId: 0,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          asaCodes: 0, /* should be used when we need to update hardcode value */
          excludeoccurances: 0, /*multiple input*/
          includeoccurances: 0, /*multiple input*/
          excludeModifiers: 0, /*multiple input*/
          includeModifiers: 0, /*multiple input*/
          excludecpttypeid: 0,
          includecpttypeid: 0,
          usecpttypeidasfilter: 0,
          comments: 0
        }
      },
      {
        id: 15,
        name: 'Payroll_DistributeProviderTimeUnitsForOverTime',
        category: 3,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 2,
        infos: {
          name: 'Provider Time Units For Over Time',
          desc: 'Used to distribute provider Time units based on the conversion factor as input and over time.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          RegulardistributeTimeUnitsFactor: 1, /* value which need to be use for distribution factor */
          OverTimedistributeTimeUnitsFactor: 1, /* value which need to be use for distribution factor */
          OverTimeStart: 1,// --over time satrt time
          OvertimeEnd: 1, //--over time end time
          providerTypeId: 0, /*servicing or directing*/ /* multiple input*/
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          p_Isfirststep: 3, /* to identify if it firststep in flow  1 for first else 0 */
          comments: 0
        }
      },
      {
        id: 16,
        name: 'Payroll_DistributeProviderTimeUnits',
        category: 3,
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Distribute Provider Time Units.',
          desc: 'Used to distribute Time units based on the conversion factor as input.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          distributeTimeUnitsFactor: 1,
          providerTypeId: 0,
          cptCodes: 0, /* multiple input cptcode|units,cptcode|units*/
          Isfirststep: 3, /* to identify if it firststep in flow  1 for first else 0 */
          distributetimeunitsusingcapping: 0,
          comments: 0
        }
      },
      {
        id: 17,
        name: 'Payroll_RemoveProviderTimeUnitsForBlackOutPeriod',
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Remove Provider No Base Units For Blackout Period',
          desc: 'This will check for black out and partial blackout and remove those minutes from provider time units.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          cptCodes: 0,  /*multiplt values*/
          excludecptCodes: 0, /*multiplt values*/
          uniqueServicelocationid: 0, /* multiple input*/
          workingDayswithTime: 1 /*PM_System_UniqueProviderId|Daysofweek|starttime|endtime, example 0|07:00|17:00  days of week like for sunday 0, for monday 1, for tuesday 2 and so on */,
          isanesthesia: 1,/* 1 for anesthesia  and 0 for non anesthesia rows */
          distributeTimeUnitsFactor: 1,
          useWorkingdaysfromform: 1,
          comments: 0
        }
      },
      {
        id: 20,
        name: 'Payroll_roundingoff',
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Rounding Off',
          desc: 'Used to round of the values in colunms based on input.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          baseunitsroundoffvalue: 1,
          timeunitsroundoffvalue: 1,
          providerbaseunitsroundoffvalue: 1,
          providertimeunitsroundoffvalue: 1,
          comments: 0
        }
      },
      {
        id: 21,
        name: 'Payroll_DistributeMaxcpttypebaseunits',
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Distribute Max CPT type base units',
          desc: 'It will be distributed base units based on occurances of cpts attached to cpttype selected.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          includecpttypeid: 1,
          excludecpttypeid: 0,
          comments: 0
        }
      },
      {
        id: 22,
        name: 'Payroll_UpdateMincpttypebaseunits',
        canDrag: true, allowMultiple: true,
        inputs: 1,
        outputs: 1,
        type: 1,
        infos: {
          name: 'Distribute Min CPT type base units',
          desc: 'It will update base units to min units (if actual base units is less than minimum units )based on  cpts attached to cpttype selected.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          includecpttypeid: 1,
          excludecpttypeid: 0,
          comments: 0
        }
      },
      {
        id: 9,
        name: 'Payroll_setPayrollData',
        canDrag: true, allowMultiple: false,
        inputs: 1,
        outputs: 1,
        type: 4,
        infos: {
          name: 'Save/update Payroll',
          desc: 'It will used to save final data in payroll  table.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          flowId: 3
        },
        filters: {}
      }, {
        id: 18,
        name: 'payroll_setPayrollprovidersummary',
        canDrag: true,
        allowMultiple: false,
        inputs: 1,
        outputs: 1,
        type: 4,
        infos: {
          name: 'Set Payroll Provider Summary',
          desc: 'used to save the details for curve report.'
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          datetype: 2,
          runOn: 2,
          fromdate: 2,
          todate: 2,
          flowId: 3,
        },
        filters: {}
      },
      {
        id: 19,
        name: 'end',
        canDrag: true,
        allowMultiple: false,
        inputs: 1,
        outputs: 0,
        type: 4,
        infos: {
          name: 'End',
        },
        inputdata: {
          tablename: 2,
          uniquepracticeid: 2,
          flowId: 3
        },
        filters: {}
      }

    ];
    return result;
  }
  public async getPayrols(request: any[]): Promise<any> {

  }
  public async getPayrollData(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async freezePayrollData(id: string, request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/freeze/${id}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async setPayrollData(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  public async GetCptTypesLookup(request: any): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/cpttypes/lookup`,
      httpOptionsJSON).toPromise();
    return result;
  }
  public async deletepayroll(id: string): Promise<any> {

    return null;
  }
  public async deactivatepayroll(id: string, active: number): Promise<any> {

    return null;
  }
}
