import { AfterViewInit, Component, HostListener, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Activity } from '../../dashboard/dashboard-components/activity-timeline/activity-timeline-data';
import { CommentService } from '../../services/comment.service';
import * as custom from '../../Utility/CustomValidators.utilities'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnDestroy, AfterViewInit {

  ngOnDestroy(): void {
    clearInterval(this.checkLoginTimeer);
  }
  ngAfterViewInit() { }
  public activities: Activity[] = [];
  public checkLoginTimeer: any;
  public isLoading: boolean = false;
  public _isLoadingMore: boolean = false;
  public pagestartrecord: number = 0;
  public pagesize: number = 10;
  constructor(
    private commentService: CommentService) {
    this.getNotifications(false);
    this.checkLoginTimeer = setInterval(() => {
      this.getNotifications(true);
    }, 10000);
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.pagestartrecord = this.pagestartrecord + this.pagesize
      this.getNotifications(false);
    }
  }
  getNotifications(isAutoLoad: boolean) {
    let request = [
      {
        "createdBy": 1,
        "notificationtypeid": 1,
        "practiceid": 1,
        "warehouseuniqueidentifier": 1,
        "pagesize": this.pagesize,
        "pagestartrecord": ((isAutoLoad) ? 0 : this.pagestartrecord)
      }
    ]
    this._isLoadingMore = !isAutoLoad
    this.commentService.getNotifications(request).then(res => {

      res.filter((w: any) => {
        return this.activities.findIndex((s: any) => { return s.notificationdetailid == w.notificationdetailid }) < 0
      }).map((w: any) => {
        this.activities.push(w);
        return w;
      });
      this.activities = this.activities.sort((a: Activity, b: Activity) => {
        return custom.compare(a.notificationdetailid, b.notificationdetailid, false);
      });
      this._isLoadingMore = false;
    })
  }
  getUserTime(date: string) {
    var m1 = moment(date);
    return m1.fromNow();
  }

}
