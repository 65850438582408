import { Directive } from '@angular/core';

@Directive({
  selector: '[appImageViewer]'
})
export class ImageViewerDirective {

  constructor() { }

}
