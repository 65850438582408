import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { CptDetailsService } from '../../components/cpt-details/cpt-details.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})

export class AutocompleteComponent {
  myControl = new FormControl();
  options = [];
  filteredOptions: Observable<any[]>;
  public _display: string = "";
  public _value: any = {};
  @Input() set value(value: any) {
    this.myControl.setValue(value);
    this._value = value;
  }
  get value() {
    return this._value;
  }
  @Input() set display(value: any) {
    this._display = value;
  }

  get display() {
    return this._display;
  }
  @Input() set service(service: any) {
    this._service = service;
  }
  get service() {
    return this._service;
  }
  constructor(private _service: CptDetailsService) {

    this.myControl.setValue(this._value);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.filter(val || '')
      })
    )
  }
  ngOnInit() {

  }

  async filter(val: any): Promise<any[]> {
    if (val.constructor !== Object) {
      let request: any = {
        p_term: val.constructor === Object ? val.code : val,
        pagesize: 10,
        pagestartrecord: 0,
        practiceid: 3
      }
      if (request.p_term != "") {
        // call the service which makes the http-request
        let response = await this.service.getPatientLookup(request);
        response = response.data.table.map((w: any) => { return { code: w.patient_id, description: w.patientname, display: w.patientname } })
        return response;
      }
      return [];
    }
    return [];

  }
  displayFn(item: any) {
    this._value = item;
    if (this.display == "description")
      return item && item.description ? item.description : '';
    else return item && item.display ? item.display : '';
  }

  @Output() onSelectionChange = new EventEmitter();
  doSelectionChange(item: any) {
    this.onSelectionChange.emit(item?.source?._value ?? item);
  }

}
