import {
  AfterViewInit,
  Component, 
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild, 
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; 
import { ClientService } from '../../services/client.service';
import { BotDashboardService } from '../bot-dashboard/bot-dashboard.service'; 
import { PayrollDetailsComponent } from './payroll-details/payroll-details.component';
import { PayrollService } from './payroll.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit, AfterViewInit, OnChanges {
  public showEdit: boolean = true;
  public _isLoading: boolean = false;
  public filtervalue: string = ""; 
  @ViewChild("payrollDetails") payrollDetails!: PayrollDetailsComponent;
  constructor(
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private clientService: ClientService,
    private botDashboardService: BotDashboardService,
  ) {
    this.getPayrollData();
    this.getLookupData()

  }
  ngOnInit(): void {

  }
  ngAfterViewInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {     
  }

  public lookupData: any = {};
  public scheduleType: number = 4;
  public payrollList: any[] = [];
  public payroll: any = {};
  getPayrollData() {
    this._isLoading = true;
    let requestObject = [{
      schedule_instance_id: '-1',
      eventid_google: "-1",
      scheduletypeid: this.scheduleType,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate:  "01/01/1900",
      todate:  "01/01/1900"
    }]
    this.payrollService.getPayrollData(requestObject).then(res => { 
      this.payrollList = res;
      this._isLoading = false;
    })
     
  }
  onEditpayroll(item: any) {
    if (item) {
      this.payroll = item;     
      this.showEdit = true;
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
  }
  onAddNew() {
    this.showEdit = true;
    this.payroll = null;
  }
  //payrollDetails
  onClear() {
    this.payrollDetails.onClear();
  }
  runFlow() {
    this.payrollDetails.runFlow();
  }
  async getLookupData() {

    const request = [{
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }]
    const locationRequest = [{
      clientid: '',
      uniqueservicingproviderid: -1,
      pagesize: 100,
      pagestartrecord: 0,
    }]
    this._isLoading = true;
    const lookups = await Promise.all([
      this.clientService.getlookup([]),
      this.clientService.getServiceLocations(locationRequest),
    ]);
    this.lookupData = {
      practices: lookups[0]?.map((s: any) => { return { code: s.clientid, description: s.clientname, active: true } }),
      dateType: [{ code: '1', description: 'Accounting' }, { code: '2', description: 'DOS' }, { code: '3', description: 'Transaction Date' }, { code: '4', description: 'DOE' }],
      runOn: [{ code: 'last30', description: 'Last 30 Days' }, { code: 'lastmonth', description: 'Last Month' }, { code: 'lastweek', description: 'Last week' }, { code: 'last7', description: 'Last 7 days' }],
      runAs: [{ code: '0', description: 'Manual' }, { code: '1', description: 'Schedule' }],
      excludeCpts: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      asacodes: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      modifiers: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      workingDays: [{ code: 'addnew', description: 'Add New', isCustom: true }],
      providerType: [{ code: '0', description: 'Servicing' }, { code: '1', description: 'Directing' }],
      occurances: [{ code: '1', description: '1' }, { code: '2', description: '2' }, { code: '3', description: '3' }, { code: '4', description: '4' }, { code: '5', description: '5' }],
      serviceLocation: lookups[1]?.map((s: any) => { return { code: s.uniqueservicingproviderid, description: s.servicingprovidername, active: true } }),
    }     
    this._isLoading = false;
  }


}
