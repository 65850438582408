import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { physicianperiod } from '../physician-period';
import { PhysicianPeriodService } from '../physician-period.service';
import * as Custom from '../../../Utility/CustomValidators.utilities';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-physician-period-list',
  templateUrl: './physician-period-list.component.html',
  styleUrls: ['./physician-period-list.component.css']
})
export class PhysicianPeriodListComponent {
  public filterData: any = {};
  public _physicianperiodList: physicianperiod[] = [];
  public sortedData = new MatTableDataSource<physicianperiod>([]);
  public sort!: Sort;
  public filterColumn: string = '';
  public hasphysicianperiodMaster_Edit: boolean = false;
  public hasphysicianperiodMaster_Delete: boolean = false;
  displayedColumns: string[] = ['physicianperiod', 'practicename', 'pm_system_servicingprovidername','blackoutstarttime', 'blackoutendtime', 'isactive', 'createdby', 'createdon', 'modifiedby', 'modifiedon'];
  public pageSize = 10;
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  ngOnInit() {
    //this.setPrivileges();
  }

  @Input() set physicianList(physicianList: physicianperiod[]) {    
    this.sortedData.data = physicianList;
    this._physicianperiodList = physicianList;
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }

  constructor(private dialog: MatDialog, private physicianperiodservice: PhysicianPeriodService, private auth: AuthService) { }

  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();
  }
  sortAndFilterData() {
    let filteredData = this._physicianperiodList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;

    });

    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: physicianperiod, b: physicianperiod) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'Blackoutstarttime':
          return Custom.compare(a.blackoutstarttime, b.blackoutstarttime, isAsc);
        case 'Blackoutendtime':
          return Custom.compare(a.blackoutendtime, b.blackoutendtime, isAsc);
        case 'isactive':
          return Custom.compare(a.isactive, b.isactive, isAsc);
        case 'createdon':
          return Custom.compare(a.createdon, b.createdon, isAsc);
        case 'CreatedBy':
          return Custom.compare(a.createdby, b.createdby, isAsc);
        case 'modifiedby':
          return Custom.compare(a.modifiedby, b.modifiedby, isAsc);
        case 'modifiedon':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        default:
          return 0;
      }
    });
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    // if (this.hasClient_Edit) {
    this.onShowDetails.emit(item);
    //}
  }

  @Output() onEditPhysician = new EventEmitter();
  doEditPhysicianPeriod(physicianperiod: physicianperiod) {
    this.onEditPhysician.emit(physicianperiod);
  }

  @Output() onDelete = new EventEmitter<physicianperiod>();

  async doDeletePhysicianPeriod(physicianperiod: physicianperiod) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    //debugger;
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.physicianperiodservice.deletegetPhysicianPeriod(physicianperiod.physicianblackoutperiodid).then(res => {
        data.message = res[0].msg;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();
  }

  //setPrivileges() {
  //  this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
  //  this.hasActionMaster_Delete = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Delete);
  //  if (!this.hasActionMaster_Edit && !this.hasActionMaster_Delete) {
  //    let index = this.displayedColumns.indexOf('actions');
  //    let elementsToRemove = 1;
  //    this.displayedColumns.splice(index, elementsToRemove);
  //  }
  //}


}
