import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QueueLabel, Task } from '../task';
import * as Custom from '../../../Utility/CustomValidators.utilities'
import { AuthService } from '../../../services/auth.service';
import { PrivilegeValues } from '../../../entities/Privilege';




@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements AfterViewInit {
  public _taskList: any[] = [];
  public _queueFilter!: QueueLabel;
  @Input() set taskList(tasks: any[]) {

    /*this.sortedData.data = users;*/
    this._taskList = tasks;
    this.sortedData.data = tasks;
  }
  get taskList() {

    return this._taskList;
  }
  @Input() set queueFilter(queueLabel: QueueLabel) {
    this._queueFilter = queueLabel;
  }
  @Input() set totalCount(totalCount: number) {
    this.totalData = totalCount ?? 0;
  }
  get queueFilter() {

    return this._queueFilter;
  }
  public _pageData: any = {};
  @Input() set pageData(pageData: any) {
    this._pageData = pageData;
    if (this.mp) {
      this.mp.pageIndex = pageData.pageIndex;
    }
  }
  get pageData() {

    return this._pageData;
  }


  public sortedData = new MatTableDataSource<any>([]);
  //displayedColumns: string[] = ['checkBox', "Patient", "Clinics", "workqueuename", "Ticket", "patientaccountnumber", "TaskListStatus", "DOS", "DOSAge", "dosagingbucket", "dischargedate", "dischargedateage", "dischargedateageagingbucket", "primaryinsurnace", "primaryinsurancegroup", "currentinsurance", "currentinsurancegroup", "totalcharges", "totalpayments", "totaladjustments", "Insurancebalance", "PatientBalance", "totalbalance", "Notes", "lastactiondate", "lastactiondateage", "followupdate", "activetaskage"//, "AsigneeName", "action", "subaction", "log_lasttasklistactioncreatedby", "log_lastfollowupdate"];
  public totalData = 0;
  public pageSize = 50;
  displayedColumns: string[] = ['checkBox', "encounternumber", "UniquePracticeID", "CurrentQueueName", "PatientName", "patient_accountnumber", "issnoozed", "CurrentAssigneeName", "followupdate"];
  public charLimit = 50;
  public sort: Sort = {
    active: "encounterid",
    direction: "asc",
  };
  public selectAll: boolean = false;
  @ViewChild(MatPaginator) mp!: MatPaginator;
  constructor(
    private auth: AuthService) {

  }

  getUserName(user: any) {
    return `${user.firstname} ${user.lastname}`
  }
  DateFormate(DateString: string, formateString: string) {
    return Custom.FormatDate(DateString, formateString);
  }
  formateString(value: string) {
    console.log('sdfsdf');
    if (value.length > this.charLimit)
      return value.substr(0, this.charLimit);
    else return value;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.mp) {
        this.mp.pageIndex = this._pageData.pageIndex
      }
    }, 1000);
  }
  sortData(sort: Sort) {
    this.sort = sort;
    //this.sortAndFilterData();
    this.onShortingChange.emit(sort);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortAndFilterData() {
    let filteredData = this.taskList;

    if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }
    this.sortedData.data = filteredData.sort((a: any, b: any) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'Ticket':
          return this.compare(a.encounternumber as number, b.encounternumber as number, isAsc);
        case 'practice':
          return this.compare(a.practice, b.practice, isAsc);
        case 'workqueuename':
          return this.compare(a.currentQueueName, b.currentQueueName, isAsc);
        case 'Patient':
          return this.compare(a.patientName, b.patientName, isAsc);
        case 'patientaccountnumber':
          return this.compare(a.patient_accountnumber, b.patient_accountnumber, isAsc);
        case 'issnoozed':
          return this.compare(a.issnoozed, b.issnoozed, isAsc);
        case 'currentOwner':
          return this.compare(a.currentAssigneeName, b.currentAssigneeName, isAsc);
        case 'followupdate':
          return this.compare(a.followupdate, b.followupdate, isAsc);
        default:
          return 0;
      }
    });
  }
  @Output() onTaskSelection = new EventEmitter();
  @Output() onShortingChange = new EventEmitter();
  @Output() onPageChange = new EventEmitter();
  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    this.onShowDetails.emit(item);
  }
  onCheckboxChange(event: any, element: any) {
    let pageSize: number = this.mp?.pageSize ?? 0;
    let pageIndex: number = this.mp?.pageIndex ?? 0;
    let pageStart = (pageIndex * pageSize);
    let pageEnd = pageStart + pageSize - 1;
    if (element == null) {
      this.taskList.map((w, idx) => {
        //if (idx <= pageEnd && idx >= pageStart)
        w.selected = event.checked;
        // else
        //w.selected = false;
        return w
      });
      this.sortedData.data = this.taskList;
    }
    else {
      element.selected = event.checked;
    }
    this.selectAll = this.taskList.filter((w, idx) => { return w.selected && idx <= pageEnd && idx >= pageStart }).length == pageSize;
    this.onTaskSelection.emit(this.taskList.filter((w) => { return w.selected }));
  }
  doPageChange(item: any) {
    this.selectAll = false;
    this.taskList = this.taskList.map((w) => {
      w.selected = false;
      return w
    });
    this.sortedData.data = this.taskList;
    this.onPageChange.emit(item);
    this.onTaskSelection.emit([]);
  }
  get hasAction_Add() {
    const has_add = this.auth.hasPrivilege(PrivilegeValues.Task_Action_Add)
      || this.auth.hasPrivilege(PrivilegeValues.Task_Notes_Add);

    if (!has_add) {
      this.displayedColumns = this.displayedColumns.filter(w => { return w != 'checkBox' })
    }
    else {
      if (this.displayedColumns.findIndex(w => { return w != 'checkBox' }) < 0) {
        this.displayedColumns = ['checkBox', "Ticket", "Practice", "workqueuename", "Patient", "patientaccountnumber", "issnoozed", "currentOwner", "followupdate"];
      }
    }
    return has_add;
  }

  getTitle(item: any) {
    if (item.QueueDetails instanceof Array) {
      return item.QueueDetails.join(',')
    }
    return item.QueueDetails
  }
}
