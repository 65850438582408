<section class="edit-container-popup">
    <mat-card-title>
        <span>  Payroll Details  </span>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
        <app-payroll-preview-list [payrollList]="data" #payrollpreviewlist></app-payroll-preview-list>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end" style="margin-right: 1%;">
        <button mat-flat-button color="primary" class="btn" (click)="doSave()" [disabled]="isWaiting" cdkFocusInitial>Save</button>
        <button mat-flat-button extended class="btn" (click)="doNo()" [disabled]="isWaiting" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</section>
