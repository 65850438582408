import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { cptdetails } from '../cpt-details';

@Component({
  selector: 'app-cpt-details-edit',
  templateUrl: './cpt-details-edit.component.html',
  styleUrls: ['./cpt-details-edit.component.css']
})
export class CptDetailsEditComponent {

  public _cptdetails!: cptdetails;
  _lookupData: any = {};
  public selectedTab!: number;
  public hasCPT_View: boolean = false;


  ngOnInit() {
    this.setPrivileges();
  }

  @Input() set cptdetails(cptdetails: cptdetails) {    
    this._cptdetails = cptdetails;
  }
  @Input() set lookupData(lookupData: any) {    
    this._lookupData = lookupData;
  }
  constructor(private auth: AuthService) { }
  get physician() {
    return this._cptdetails;
  }

  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(action: cptdetails) {
    this.onUpdateEdit.emit(action);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(action: cptdetails) {
    this.onShowLoading.emit(action);
  }
  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }

  setPrivileges() {
    this.hasCPT_View = this.auth.hasPrivilege(PrivilegeValues.CPT_View);
  }

}
