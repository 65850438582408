import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { DateRangePickerComponent } from '../../../shared/date-range-picker/date-range-picker.component';
import { CappingListComponent } from './capping-list/capping-list.component';

@Component({
  selector: 'app-payroll-input',
  templateUrl: './payroll-input.component.html',
  styleUrls: ['./payroll-input.component.css']
})
export class PayrollInputComponent implements OnChanges {
  public _lookupData: any;
  @Input() set data(data: any) {
    this._lookupData = data;
    this.calculateValues();
  }
  get data() {
    return this._lookupData;
  }
  @ViewChild('practice') _practice!: MultiSelectComponent;
  @ViewChild('runOn') _runOn!: DateRangePickerComponent;
  @ViewChild('dateType') _dateType!: MultiSelectComponent;
  @ViewChild('providerType') _providerTypeId!: MultiSelectComponent;
  @ViewChild('cptcodes') _cptCodes!: MultiSelectComponent;
  @ViewChild('asacodes') _asaCodes!: MultiSelectComponent;
  @ViewChild('excludecpt') _excludecpt!: MultiSelectComponent;
  @ViewChild('excludemodifiers') _excludemodifiers!: MultiSelectComponent;
  @ViewChild('includemodifiers') _includemodifiers!: MultiSelectComponent;
  @ViewChild('excludeoccurances') _excludeoccurances!: MultiSelectComponent;
  @ViewChild('includeoccurances') _includeoccurances!: MultiSelectComponent;
  @ViewChild('serviceLocation') _serviceLocation!: MultiSelectComponent;
  @ViewChild('workingDays') _workingDays!: MultiSelectComponent;
  @ViewChild('includecpttypeid') _includecpttypeid!: MultiSelectComponent;
  @ViewChild('excludecpttypeid') _excludecpttypeid!: MultiSelectComponent;
  @ViewChild('timecapping') _timecapping!: CappingListComponent;
  formAdd: FormGroup = new FormGroup({
    tablename: new FormControl(''),
    // dayOfMonth: new FormControl(''),
    reducepercentage: new FormControl(''),
    providerordersequence: new FormControl(''),
    anesthesia: new FormControl(''),
    defaultunits: new FormControl(''),
    distributeTimeUnitsFactor: new FormControl(''),
    usecptcodesfromform: new FormControl(''),
    useWorkingdaysfromform: new FormControl(''),
    baseunitsroundoffvalue: new FormControl(''),
    timeunitsroundoffvalue: new FormControl(''),
    providertimeunitsroundoffvalue: new FormControl(''),
    providerbaseunitsroundoffvalue: new FormControl(''),
    usecpttypeidasfilter: new FormControl(''),
    comments: new FormControl(''),
    defaultpercentage: new FormControl(''),
  });
  @Output() onCancel = new EventEmitter();
  onSaveConnector() {

    if (this.isvalid()) {
      let item = {
        tablename: this.formAdd.controls.tablename.value,
        uniquepracticeid: this._practice?.selectedItems ?? [],
        datetype: this._dateType?.selectedItems ?? [],
        runOn: this._runOn?.selectedValue ?? [],
        reducepercentage: this.formAdd.controls.reducepercentage.value,
        cptCodes: this._cptCodes?.selectedItems ?? [],
        asaCodes: this._asaCodes?.selectedItems ?? [],
        providerOrderSequence: this.formAdd.controls.providerordersequence.value,
        providerTypeId: this._providerTypeId?.selectedItems ?? [],
        isanesthesia: this.formAdd.controls.anesthesia.value ? 1 : 0, /* 1 for anesthesia  and 0 for non anesthesia rows */
        defaultunits: this.formAdd.controls.defaultunits.value, /* should be used when we need to update hardcode value */
        excludeModifiers: this._excludemodifiers?.selectedItems ?? [], /*multiple input*/
        includeModifiers: this._includemodifiers?.selectedItems ?? [], /*multiple input*/
        excludeoccurances: this._excludeoccurances?.selectedItems ?? [], /*multiple input*/
        includeoccurances: this._includeoccurances?.selectedItems ?? [], /*multiple input*/
        excludecptCodes: this._excludecpt?.selectedItems ?? [], /*multiple input*/
        serviceLocation: this._serviceLocation?.selectedItems ?? [], /*multiple input*/
        workingDayswithTime: this._workingDays?.selectedItems ?? [], /*multiple input*/
        distributeTimeUnitsFactor: this.formAdd.controls.distributeTimeUnitsFactor.value,
        usecptcodesfromform: this.formAdd.controls.usecptcodesfromform.value ? 1 : 0,
        useWorkingdaysfromform: this.formAdd.controls.useWorkingdaysfromform.value ? 1 : 0,
        baseunitsroundoffvalue: this.formAdd.controls.baseunitsroundoffvalue.value,
        timeunitsroundoffvalue: this.formAdd.controls.timeunitsroundoffvalue.value,
        providertimeunitsroundoffvalue: this.formAdd.controls.providertimeunitsroundoffvalue.value,
        providerbaseunitsroundoffvalue: this.formAdd.controls.providerbaseunitsroundoffvalue.value,
        distributetimeunitsusingcapping: this._timecapping?.values ?? "",
        usecpttypeidasfilter: this.formAdd.controls.usecpttypeidasfilter?.value ? 1 : 0,
        includecpttypeid: this._includecpttypeid?.selectedItems ?? [],
        excludecpttypeid: this._excludecpttypeid?.selectedItems ?? [],
        comments: this.formAdd.controls.comments?.value ?? "",
        defaultpercentage: this.formAdd.controls.defaultpercentage?.value ?? ""
      }
      this.onCancel.emit(item);
    }
  }
  doReset() {
    this.formAdd.controls.tablename.setValue('');
  }
  doCancelEdit() {
    this.doReset();
    this.doCancel(false);
  }
  doCancel(item: any) {
    this.onCancel.emit(item);
  }
  castToFloat(item: any, control: string, type: string, maxvalue = 100) {
    let val = parseFloat(this.formAdd.controls[control]?.value ?? '');
    if (this.formAdd.controls[control]?.value ?? '') {
      if (type == 'int') {
        val = parseInt(this.formAdd.controls[control].value);
      }

      if (isNaN(val)) {
        this.formAdd.controls[control].setValue('0');
      }
      else if (val > maxvalue) {
        this.formAdd.controls[control].setValue('0');
      }
      else {
        this.formAdd.controls[control].setValue(val);
      }
    }
  }
  isRequired(item: string) {
    return this.data.node.data.inputdata[item] == 1;
  }
  canEdit(item: string) {
    return this.data.node.data.inputdata[item] == 1 || this.data.node.data.inputdata[item] == 0;
  }
  isvalid() {
    this.formAdd.markAllAsTouched();
    let inputdata = this.data.node.data.inputdata;
    let result = true;
    Object.keys(inputdata).forEach(s => {
      if (!this.isValidInput(s)) {
        result = false;
      }
    });

    return result;
  }
  isValidInput(input: string) {
    switch (input) {
      case 'tablename':
      case 'reducepercentage':
      case 'distributeTimeUnitsFactor': {
        return !this.isRequired(input) || this.formAdd.controls.distributeTimeUnitsFactor.disabled || (this.isRequired(input) && this.formAdd.controls[input].value != '');;
      }
      case 'defaultunits': {
        return this.formAdd.controls[input].value != '' ||
          (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._includecpttypeid?.selectedItems?.length ?? 0) > 0 ||
          (this._excludecpttypeid?.selectedItems?.length ?? 0) > 0 ||
          this.formAdd.controls.usecptcodesfromform.value
          ;
      }
      case 'defaultpercentage': {
        return this.formAdd.controls[input].value != '' ||
          (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._includecpttypeid?.selectedItems?.length ?? 0) > 0 ||
          (this._excludecpttypeid?.selectedItems?.length ?? 0) > 0 ||
          this.formAdd.controls.usecptcodesfromform.value
          ;
      }
      case 'occurances':
      case 'excludeoccurances': {
        if ((this.data?.node?.data?.id ?? 0) == 10) {
          return (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
            (this._asaCodes?.selectedItems?.length ?? 0) > 0 ||
            (this._serviceLocation?.selectedItems?.length ?? 0) > 0 ||
            (this._providerTypeId?.selectedItems?.length ?? 0) > 0 ||
            (this.formAdd.controls.providerordersequence.value ?? "") != "" ||
            (this._excludeoccurances?.selectedItems?.length ?? 0) > 0 ||
            (this._includecpttypeid?.selectedItems?.length ?? 0) > 0 ||
            (this._excludecpttypeid?.selectedItems?.length ?? 0) > 0 ||
            (this._includeoccurances?.selectedItems?.length ?? 0) > 0
            ;
        }
        return (this._cptCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._asaCodes?.selectedItems?.length ?? 0) > 0 ||
          (this._serviceLocation?.selectedItems?.length ?? 0) > 0 ||
          (this._excludeoccurances?.selectedItems?.length ?? 0) > 0 ||
          (this._includeoccurances?.selectedItems?.length ?? 0) > 0
          ;
      }
      case 'workingDayswithTime':
        {
          return !this.isRequired(input) || ((this._workingDays?.selectedItems?.length ?? 0) > 0) ||
            this.formAdd.controls.useWorkingdaysfromform.value;
        }
      case 'uniquepracticeid':
        {
          return !this.isRequired(input) || (this._practice?.selectedItems && this._practice?.selectedItems?.length > 0)
        }
      case 'datetype':
        {
          return !this.isRequired(input) || (this._dateType?.selectedItems && this._dateType?.selectedItems?.length > 0)
        }
      case 'runOn':
        {
          return !this.isRequired(input) || (this._runOn?.selectedValue)
        }
      case 'providertimeunitsroundoffvalue':
        {
          return !this.isRequired(input) || (
            this.formAdd.controls.baseunitsroundoffvalue.value ||
            this.formAdd.controls.timeunitsroundoffvalue.value ||
            this.formAdd.controls.providerbaseunitsroundoffvalue.value ||
            this.formAdd.controls.providertimeunitsroundoffvalue.value
          )
        }
      case 'distributetimeunitsusingcapping':
        {
          return !this.canEdit(input) || (this._timecapping.isValidData)
        }
      case 'includecpttypeid':
        {
          return !this.isRequired(input) || (this._includecpttypeid?.selectedItems?.length ?? 0)
            || (this._excludecpttypeid?.selectedItems?.length ?? 0)
        }

    }



    return true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes :>> ', changes);
  }
  calculateValues() {
    if (this.data && this.data?.node?.data?.filters) {
      let filters = this.data?.node?.data?.filters;
      let lookups = this.data?.lookupData;
      this.formAdd.controls.tablename.setValue(filters.tablename)
      this.formAdd.controls.distributeTimeUnitsFactor.setValue(filters.distributeTimeUnitsFactor)
      this.formAdd.controls.reducepercentage.setValue(filters.reducepercentage)
      this.formAdd.controls.providerordersequence.setValue(filters.providerOrderSequence)
      this.formAdd.controls.anesthesia.setValue(filters.isanesthesia == 1)
      this.formAdd.controls.defaultunits.setValue(filters.defaultunits)
      this.formAdd.controls.usecptcodesfromform.setValue(filters.usecptcodesfromform == 1)
      this.formAdd.controls.useWorkingdaysfromform.setValue(filters.useWorkingdaysfromform == 1)
      this.formAdd.controls.baseunitsroundoffvalue.setValue(filters.baseunitsroundoffvalue)
      this.formAdd.controls.timeunitsroundoffvalue.setValue(filters.timeunitsroundoffvalue)
      this.formAdd.controls.providerbaseunitsroundoffvalue.setValue(filters.providerbaseunitsroundoffvalue)
      this.formAdd.controls.providertimeunitsroundoffvalue.setValue(filters.providertimeunitsroundoffvalue)
      this.formAdd.controls.usecpttypeidasfilter.setValue(filters.usecpttypeidasfilter);
      this.formAdd.controls.comments.setValue(filters.comments);
      this.formAdd.controls.defaultpercentage.setValue(filters.defaultpercentage);

      if ((this.data?.node?.data?.filters?.includecpttypeid.length??0) > 0) {
        this.formAdd.controls.usecpttypeidasfilter?.enable();
      }
      else {
        this.formAdd.controls.usecpttypeidasfilter?.disable();
      }
      if (filters.cptCodes?.length > 0) {
        filters.cptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
      if (filters.excludecptCodes?.length > 0) {
        filters.excludecptCodes.map((w: any) => {
          if (lookups.excludeCpts.findIndex((s: any) => { return s.code == w.code }) < 0) {
            this.data?.lookupData.excludeCpts.push(w);
          }
        });
      }
    }
  }

  public digitsOnly = /^[0-9]*$/;
  allow_DigitsOnly(event: any) {
    const inputChar = String.fromCharCode(event.charCode);

    if (!this.digitsOnly.test(inputChar)) {
      event.preventDefault();
    }
  }
  onAddNewRow() {
    this._timecapping.onAddNewRow();
  }
  onTimeCappingChanges(event: any) {
    if ((this._timecapping?.ELEMENT_DATA?.length??0) > 0)
      this.formAdd.controls.distributeTimeUnitsFactor.disable();
    else {
      this.formAdd.controls.distributeTimeUnitsFactor.enable();
    }
  }
  onCptSelectionChange(item: any[]) {
    if (item.length > 0) {
      this.formAdd.controls.usecpttypeidasfilter?.enable();
    }
    else {
      this.formAdd.controls.usecpttypeidasfilter?.disable();
    }
  }
}
