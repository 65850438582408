<div class="row">
    <div class="col-lg-4">
        <app-multi-select [dropdownList]="lookupData.relativeDateOperators" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="_filters.operator" [placeholder]="'operator'" #perator></app-multi-select>
    </div>
    <div class="col-lg-4">
        <input matInput type="number" class="form-control" id="namestarttext" placeholder="0" [formControl]="CalendarDateValue">
    </div>
    <div class="col-lg-4">
        <app-multi-select [dropdownList]="lookupData.dateFilterTimeUnits" [singleSelection]="'true'" [allowSearchFilter]="'false'" [selectedItems]="_filters.timeUnit" [placeholder]="'Unit'" #timeUnit></app-multi-select>
    </div>
</div>
