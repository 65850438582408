<section class="edit-container-popup" style="margin: auto;width: 98%;">
    <form [formGroup]="formAdd" style="margin-bottom:0.5em">

        <div class="row" fxLayout="column" fxLayoutGap=".5em">
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Cpt Codes:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.excludeCpts" [selectedItems]="data?.node?.data?.filters?.cptCodes" [disbaleSelection]="formAdd?.controls?.usecptcodesfromform?.value??false" [placeholder]="'Cpt Codes'" #cptcodes></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="usecptcodesfromform">
                    <label class="form-check-label" for="flexSwitchCheckChecked">Use Default Value From Settings</label>
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Asa Codes</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.asacodes" [selectedItems]="data?.node?.data?.filters?.asacodes" [addNewOption]="'true'" [placeholder]="'Asa Codes'" #asacodes></app-multi-select>
                </div>
            </div>

            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <small id="name" class="form-text text-muted">Default Units:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="defaultunits" class="form-control" placeholder="0.00%" (change)="castToFloat($event,'defaultunits','int')">
                    <mat-error *ngIf="!isValidInput('defaultunits') && formAdd?.touched">
                        Enter defaultunits or Select CPT/Asa Codes
                    </mat-error>
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <small id="name" class="form-text text-muted">Provider Order Sequence:</small>
                <div>
                    <input type="text" maxlength="5" min="0" max="100" style="width: 100% !important;" matInput formControlName="providerordersequence" class="form-control" placeholder="0" (change)="castToFloat($event,'providerordersequence','int')">
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Provider Type:</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.providerType" [selectedItems]="data?.node?.data?.filters?.providerTypeId" [singleSelection]="'true'" [placeholder]="'Provider Type'" #providerType></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="anesthesia">

                    <label class="form-check-label" for="flexSwitchCheckChecked">Anesthesia</label>
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Exclude Modifiers</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.modifiers" [selectedItems]="data?.node?.data?.filters?.excludemodifiers" [addNewOption]="'true'" [placeholder]="'Modifiers'" #excludemodifiers></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-11 col-md-11 col-sm-11">
                <div class="form-group">
                    <small id="name" class="form-text text-muted">Include Modifiers</small>
                    <app-multi-select [dropdownList]="data?.lookupData?.modifiers" [selectedItems]="data?.node?.data?.filters?.includemodifiers" [addNewOption]="'true'" [placeholder]="'Modifiers'" #includemodifiers></app-multi-select>
                </div>
            </div>
            <div class="Connector-info col-lg-12 col-md-12 col-sm-12">
                <small class="form-text text-muted">Comments:</small>
                <div>
                    <input type="text" maxlength="5000" min="0" max="100" style="width: 100% !important;" matInput formControlName="comments" class="form-control" placeholder="Comments">
                </div>
            </div>

        </div>

    </form>

    <mat-divider></mat-divider>
    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" (click)="onSaveConnector()">OK</button>
        <!-- <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button> -->
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
    </div>
</section>
