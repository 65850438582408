import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class ActionMasterService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Action';

  constructor(private http: HttpClient) { }

  public async getActinMasterList(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getActionDetails(id: string): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }

  async setAction(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async deleteAction(id: string): Promise<any> {
    let result = await this.http.delete(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }
}
