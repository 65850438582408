import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { action } from '../action-master';
import { ActionMasterService } from '../action-master.service';


@Component({
  selector: 'app-action-master-details',
  templateUrl: './action-master-details.component.html',
  styleUrls: ['./action-master-details.component.css']
})
export class ActionMasterDetailsComponent {
  public _action!: action;
  public _isLoading: boolean = false;
  public isEdit: boolean = false;
  public hasActionMaster_Edit: boolean = false;

  formAdd: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    isActive: new FormControl(true),    
  });
    actionmasterService: any;

  @Input() set action(action: action) {
    if (action._id != "- 1") {
      this.isEdit = true;
      console.log(this.isEdit)
    }
    else {
      this.isEdit = false
    }    
    this._action = action;
    console.log(this._action)
    if (action?._id)
      this.getActionDetails();
  }

  get action() {
    return this._action;
  }

  ngOnInit(): void {
    this.setPrivileges();
  }
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {    
    this.onCancelEdit.emit(false);
  }
  constructor(private dialog: MatDialog, private actionservice: ActionMasterService, private auth: AuthService) { }
  @Output() doNextEdit = new EventEmitter();

  async doSave(type: string) {
   
    if (this.formAdd.valid) {
      let actionNew: any = {
        _id: this.action?._id ?? -1,
        Actionname: this.formAdd.controls.name.value,
        Actiondescription: this.formAdd.controls.description.value ?? '',
        isactive: (this.action?._id ?? "-1") == "-1" ? "1" : this.formAdd.controls.isActive.value ? 1 : 0,
        //createdby: this.auth.currentUser.username,
        //createdon: ,
        //modifiedby: this.auth.currentUser.username,
        //modifiedon: ,
      };
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.title = 'Information!';
      data.showYes = true;
      data.noText = 'NO';
      data.yesText = 'YES';
      let update = true;
      if (actionNew.isactive == 0 && this.action.isactive != actionNew.isactive) {
        data.message = 'Are you sure you want to in active this action?';
        update = await Confirmation(this.dialog, data);
      }

      if (update) {
        this.onShowLoading.emit(true);
         this.actionservice.setAction(actionNew).then(res => {
           if (res) {
             //debugger;
            this.action = res as action;
            data.title = 'Information!';
            data.showYes = false;
            data.noText = 'OK';
            data.message = res.message;
            //await Confirmation(this.dialog, data);
            Confirmation(this.dialog, data);
             //this.onUpdateEdit.emit();
             if (res.result != 0) {
               this.onCancelEdit.emit(true);
             }            
            this._isLoading = false;
            this.onShowLoading.emit(false);

          }
        });
      }
      else {
        this.formAdd.markAllAsTouched();
        return;
      }
    }

  }

  getActionDetails() {    
    console.log(this._action)
    this.formAdd.controls.name.setValue(this.action.Actionname);
    this.formAdd.controls.description.setValue(this.action.Actiondescription);
    this.formAdd.controls.isActive.setValue(this.action.isactive);
  }
  @Output() onShowLoading = new EventEmitter();



  setPrivileges() {
    this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
  }


}
