<div id="editorOptions">
  <button mat-flat-button id="btnBold" class="fa fa-bold" color="primary" (click)="executeCommand('bold')">
    <mat-icon>format_bold</mat-icon>
  </button>
  <button mat-flat-button id="btnUnderline" class="fa fa-underline" color="primary" (click)="executeCommand('underline')">
    <mat-icon>format_underlined</mat-icon>
  </button>
  <button mat-flat-button id="btnItalic" class="fa fa-italic" color="primary" (click)="executeCommand('italic')">
    <mat-icon>format_italic</mat-icon>
  </button>
  <button mat-flat-button class="fa fa-list-ul" color="primary" (click)="executeCommand('insertUnorderedList')">
    <mat-icon>format_list_bulleted</mat-icon>
  </button>
  <button mat-flat-button class="fa fa-list-ol" color="primary" (click)="executeCommand('insertOrderedList')">
    <mat-icon>format_list_numbered</mat-icon>
  </button>
  <!--<button class="">Aa</button>-->
  <button mat-flat-button class="fa fa-outdent" color="primary" (click)="executeCommand('outdent')">
    <mat-icon>format_indent_decrease</mat-icon>
  </button>
  <button mat-flat-button class="fa fa-indent" color="primary" (click)="executeCommand('indent')">
    <mat-icon>format_indent_increase</mat-icon>
  </button>
</div>
<div id="inputEmailBody" contenteditable (keydown)="keydown()" [innerHTML]="inputString"> </div>
