import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-client',
  templateUrl: './user-client.component.html',
  styleUrls: ['./user-client.component.css']
})
export class UserClientComponent {
  displayedColumns: string[] = ['checkbox', 'name', 'description'];

  Clients!: any[];
  dataSource = new MatTableDataSource<any>([]);
  public _user!: User;
  public assignAll: boolean = false;
  public _isLoading: boolean = false;
  public hasUserGroup_Edit: boolean = false;
  ngOnInit(): void {
    this.setPrivileges();
  }

  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getUserGroups(user.userid)
    }
  }
  get user() {
    return this._user;
  }
  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
    if (profileEdit == true) {
      this.dataSource.data = this.Clients.filter(w => { return w.assigned });
      this.displayedColumns = ['name', 'description'];
    }
  }
  get profileEdit() {
    return this._profileEdit;
  }
  constructor(private userService: UserService, private dialog: MatDialog,
    private auth: AuthService, private router: Router) {

  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
  }
  getUserGroups(userid: number) {
    const request = [{ clientid: -1,userid: userid }]
    this.userService.getUserClient(request).then(w => {
      this.Clients = w.map((s: any) => {
        return {
          clientid: s.clientid,
          userid: s.userid,
          name: s.clientname,
          description: s.clientdescription,
          assigned: s.isassigned == 1
        } as any
      })
      //debugger
      this.assignAll = this.Clients.filter(w => { return w.assigned }).length == this.Clients.length;
      this.dataSource.data = this.Clients;
    })
  }
  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  async doSave() {
    let request = this.Clients.filter(w => { return w.assigned }).map(w => {
      return {
        physicianid: -1,
        userid: this.user.userid, 
        clientid: w.clientid,
        isactive:1,
        loginuserid: -1
      }
    });
    //
    /*this._isLoading = true;*/
    this.onShowLoading.emit(true);
    this.userService.setUserClient(request).then(res => {
      this.onShowLoading.emit(false);

      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      this.onUpdate.emit();
      Confirmation(this.dialog, data);
    });
  }
  onCheckboxChange(event: any, element: any | null) {
    if (element == null) {
      this.Clients.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.Clients;
    }
    else {
      element.assigned = event.checked;
    }
    this.assignAll = this.Clients.filter(w => { return w.assigned }).length == this.Clients.length;
  }
  setPrivileges() {
    this.hasUserGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.UserGroup_Edit);
  }
}
