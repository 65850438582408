import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
@Component({
  selector: 'app-drag-drop-demo',
  templateUrl: './drag-drop-demo.component.html',
  styleUrls: ['./drag-drop-demo.component.css']
})
export class DragDropDemoComponent {
  otherItems: any[] = [];
  done: any[] = [];
  constructor() {
    this.otherItems = [];

    for (let i: number = 0; i < 10; i++) {
      this.otherItems.push({ "Name": 'Label ' + (i + 1), "Id": i, "count": i });
      this.done.push({ "Name": 'Row ' + (i + 1) });
    }

  }
  drop(event: CdkDragDrop<string[]>) { // handle the drop event
    console.log('Drop event in Component B:', event);
    if (event.previousContainer === event.container) {
      // Handle moving within the same list
    } else {

      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

  }
  dragStart(event: CdkDragStart<string[]>) { // handle the drop event
    console.log('Drag event in Component B:', event);
    //debugger;
  }
  allowDrop(event: any) { // handle the drop event
    console.log('Drag event in Component B:', event);
    //debugger;
  }
  public selectedItem: any[] = [];
  onCheckboxChange(event: any, item: any) {
    if (event.checked) {
      this.selectedItem.push(item);
    }
    else {
      this.selectedItem = this.selectedItem.filter(w => { return w != item })

    }

  }



  dropNew(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  dragStarted(event: any) { // handle the drop event
    console.log('dragStarted event in Component B:', event);
  }
  dragEnded(event: any) { // handle the drop event
    console.log('dragEnded event in Component B:', event);
  }

  dragMoved(event: any) { // handle the drop event
    console.log('dragMoved event in Component B:', event);
  }


}
