<section class="edit-container-popup" style="margin: auto;width: 98%;">
    <mat-card-title>
        Schedule Details
    </mat-card-title>
    <mat-divider></mat-divider>

    <form [formGroup]="formAdd">
        <div class="row">
            <div fxLayout="column" fxLayoutGap="1em">
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <small class="form-text text-muted">Schedule Name: <span class="required-fields">*</span></small>

                        <input type="text" formControlName="scheduleName" class="form-control" placeholder="first schedule">

                    </div>
                </div>
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <small class="form-text text-muted">Schedule Details:</small>
                        <input type="text" formControlName="scheduleDetails" class="form-control" placeholder="Schedule Details">

                    </div>
                </div>
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <small id="name" class="form-text text-muted">Run AS<span style="color:red">*</span>:</small>
                        <app-multi-select [dropdownList]="data?.lookupData?.runAs" [selectedItems]="data?.node?.data?.filters?.runAs" [singleSelection]="'true'" [placeholder]="'Run As'" #runAs (onSelectionChange)="onSelectionChange($event)"></app-multi-select>
                        <mat-error *ngIf="!isValidInput('runAs') && formAdd?.touched">
                            Select date range
                        </mat-error>
                    </div>
                </div>
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="data?.node?.data?.filters?.runAs[0]?.code==1">
                    <div class="form-group row" style="margin-right: 0px;">
                        <small class="form-text text-muted">Schedule Start: <span class="required-fields">*</span></small>

                        <div [class]="'col-lg-8 col-md-8 col-sm-8 mx-auto position-relative'">
                            <input name="startdate"
                                   [type]="'text'"
                                   class="form-control"
                                   placeholder="mm/dd/yyyy"
                                   (click)="schedulestartdatepicker.open()"
                                   formControlName="scheduleStartDate"
                                   [matDatepicker]="schedulestartdatepicker"
                                   [min]="minStartDate"
                                   (blur)="onBlurDate($event)"
                                   (dateChange)="onDateChange($event)"
                                   [disabled]="scheduleType == 3">
                            <div class="position-absolute" style="right: 18px;top: 8px;">
                                <mat-icon matSuffix class="form-input-date-icon" (click)="schedulestartdatepicker.open()">calendar_today</mat-icon>
                            </div>
                            <mat-datepicker class="form-group-text" style="float:right;" #schedulestartdatepicker></mat-datepicker>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
                            <input type="time" class="form-control p-8" id="scheduleStartTime" formControlName="scheduleStartTime" />
                        </div>

                    </div>
                </div>
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12" *ngIf="data?.node?.data?.filters?.runAs[0]?.code==1">
                    <div class="form-group row" style="margin-right: 0px;">
                        <small class="form-text text-muted">Schedule End: <span class="required-fields">*</span></small>

                        <div [class]="'col-lg-8 col-md-8 col-sm-8 mx-auto position-relative'">
                            <input name="enddate"
                                   [type]="'text'"
                                   class="form-control"
                                   placeholder="mm/dd/yyyy"
                                   (click)="scheduleenddatepicker.open()"
                                   formControlName="scheduleEndDate"
                                   [matDatepicker]="scheduleenddatepicker"
                                   [min]="minEndDate"
                                   (blur)="onBlurDate($event)"
                                   [disabled]="scheduleType == 3 || P_IsRunAlways">
                            <div class="position-absolute" style="right: 18px;top: 8px;">
                                <mat-icon matSuffix class="form-input-date-icon" (click)="scheduleenddatepicker.open()">calendar_today</mat-icon>
                            </div>
                            <mat-datepicker class="form-group-text" style="float:right;" #scheduleenddatepicker></mat-datepicker>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 mx-auto position-relative p-0" *ngIf="scheduleType != 3">
                            <input type="time" class="form-control p-8" id="scheduleEndTime" formControlName="scheduleEndTime" [disabled]="P_IsRunAlways" />
                        </div>
                    </div>
                </div>
                <div class="Connector-info col-lg-12 col-md-12 col-sm-12" style="margin-right: 0px;" *ngIf="data?.node?.data?.filters?.runAs[0]?.code==1">
                    <small class="form-text text-muted">Schedule Start: <span class="required-fields">*</span></small>

                    <select name="otherOptions"
                            id="otherOptions"
                            class="form-control"
                            formControlName="otherOptions">
                        <option *ngFor="let option of getOptions()" [value]="option.id" [selected]="selectedGoogleCalendarOption == option.id">{{ option.label }}</option>
                    </select>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <mat-checkbox formControlName="scheduleStatus" [checked]="this.formAdd.controls.scheduleStatus.value">
                            Status
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <mat-divider></mat-divider>
    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" (click)="onSaveConnector()">Save</button>
        <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button>
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
    </div>
</section>
