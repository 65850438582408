import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-task-action-dialog',
  templateUrl: './task-action-dialog.component.html',
  styleUrls: ['./task-action-dialog.component.css']
})
export class TaskActionDialogComponent {
  public tasks: any[] = [];
  public users: LookupEntityBase[] = [];
  public actions: LookupEntityBase[] = [];
  public tags: string[] = [];
   
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskActionDialogComponent>) {
    this.tasks = data.tasks;
    this.users = data.users;
    this.actions = data.actions;
    this.tags = data.tags;
  }
  onCancelEdit(item:any) {
    this.dialogRef.close(item);
  }
}
