// Validator utilities
import { HttpHeaders } from '@angular/common/http';
import { FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
 

const PHONE_FORMATTED_REGEXP = /^\(\d{3}\) \d{3}-\d{4}$/;
const PHONE_NUMBERS_REGEXP = /^\d{10}$/;
var PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
export function validatePhone(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.trim().length === 0) {
    return null;
  }  
  return (PHONE_FORMATTED_REGEXP.test(control.value) || PHONE_NUMBERS_REGEXP.test(control.value)) ? null : { 'phone': true };
}
 

export function formatPhoneNumberDisplay(s: string): string {
  if (s) {
    s = s.replace(/\D/g, '');
    if ((s.length === 11) && (s[0] === '1')) {
      s = s.substr(1);
    }
    const x = s.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return s;
  } else {
    return '';
  }
}

//export function validateTime(control: AbstractControl): ValidationErrors | null {
//  if (control.value == null || control.value.trim().length === 0) {
//    return null;
//  }
//  const m = moment(control.value, 'h:mm A');
//  return m.isValid() ? null : { 'time': true };
//}

const CURRENCY_REGEXP = /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
const ALL_CURRENCY_REGEXP = /^-?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;

export function validateCurrency(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.length === 0) {
    return null;
  }
  return CURRENCY_REGEXP.test(control.value) ? null : { 'amount': true };
}

export function validateAllCurrency(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.length === 0) {
    return null;
  }
  return ALL_CURRENCY_REGEXP.test(control.value) ? null : { 'amount': true };
}

export function formatCurrencyDisplay(s: string): string {
  if (s) {
    //const x = s.replace(/[^0-9.$]/g, '').match(/^(\$)?(?:(\d+)(\.\d{0,2})?)?/);
    //x.shift();
    //if (x[1]) {
    //  x[1] = x[1].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    //}
    //return x.join('');
    return s;
  } else {
    return '';
  }
}
  export function validateDate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value.length === 0) {
      return null;
    }
    var date = moment(control.value);
    const status = date.isValid();

    return status ? null : { 'date': true };
  }

 
export function validatePassword(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.length === 0) {
    return null;
  }
  const status = checkPassword(control.value, 1) && checkPassword(control.value, 2) && checkPassword(control.value, 3)
    && checkPassword(control.value, 4) && checkPassword(control.value, 5) 

  return status ? null : { 'password': true };
}
export function confirmPassword (control: AbstractControl): ValidationErrors | null{
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confirmNewPassword');
  if (confirmPassword?.value == '')
    return null;
  const status = newPassword?.value == confirmPassword?.value;
  return status ? null : { 'password': true };

}
export function checkPassword(value:string,type: number) {
  switch (type) {
    case 1:
      {
        return value.length >= 8;
      }
    case 2:
      {
        return /[a-z]/.test(value);
      }
    case 3:
      {
        return /[A-Z]/.test(value);
      }
    case 4:
      {
        return /[0-9]/.test(value);
      }
    case 5:
      {
        return /[\W]/.test(value);
      }
  }
}
export function matchOtherCtrlValue(otherCtrl: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value.length === 0) {
      return null;
    }

    return (otherCtrl != null && control.value === otherCtrl.value) ? null : { 'match': true };
  };
}

// Valid if both controls are empty, or if both controls have a value
export function requireBothOrNeither(otherCtrl: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value.length === 0) {
      return (otherCtrl == null || otherCtrl.value == null || otherCtrl.value.length === 0) ? null : { 'bothOrNeither': true };
    } else {
      return (otherCtrl == null || otherCtrl.value == null || otherCtrl.value.length === 0) ? { 'bothOrNeither': true } : null;
    }
  };
}

export function FormatDate(DateString: string, formateString: string) {
   
  if (DateString == "0001-01-01T00:00:00" || DateString == null || DateString == undefined || DateString=='')
    return "";
  var today = new Date(DateString);

  let finalDate = "";
  let showMeridiem = false;
  if (formateString.toString().indexOf('tt')) {
    showMeridiem = true;
  }
  let splitedstr = formateString.split(/[\s/:]+/);
  finalDate = formateString;
  for (let i = 0; i < splitedstr.length; i++) {
    const str3 = splitedstr[i];
    let str2 = getDateString(today, str3, showMeridiem);
    finalDate = finalDate.replace(str3, str2.toString())
    str2 = '';
  }
  if (finalDate.includes("NaN")) return "";
  return finalDate.trim();
}
function getDateString(today: Date, str3: string, showMeridiem: boolean) {
  let str2: number = 0;
  switch (str3.slice(0, 1)) {
    case "M":
      {
        str2 = today.getMonth() + 1;
        if (str2 < 10 && str3.length == 2) {
          return '0' + str2;
        }
        else {
          return str2;
        }
      }
      break;
    case "d":
      {
        str2 = today.getDate();
        if (str2 < 10 && str3.length == 2) {
          return '0' + str2;
        }
        else {
          return str2
        }
      }
      break;
    case "y":
      {
        str2 = today.getFullYear();
        if (str3.length == 2)
          return str2.toString().slice(2, 4);
        else return str2

      }
      break;
    case "m":
      {
        str2 = today.getMinutes();
        if (str2 < 10 && str3.length == 2) {
          return '0' + str2;
        }
        else return str2
      }
      break;
    case "h":
      {
        str2 = today.getHours();
        if (showMeridiem && str2 > 12) {
          str2 = str2 - 12;
        }

        if (str2 < 10 && str3.length == 2) {
          return '0' + str2;
        }
        else return str2
      }
      break;
    case "s":
      {
        str2 = today.getSeconds();
        if (str2 < 10 && str3.length == 2) {
          return '0' + str2;
        }
        else return str2
      }
      break;
    case "t":
      {
        str2 = today.getHours();
        if (str2 > 12) {
          return "PM";
        }
        else { return  "AM"; }
      }
      break;
  }
  return str2;
}

export function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
   
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
export function booleanLookups(filterData:any) {
  return [{
    "description": 'Yes',
    "code": "1",
    active: true,
    selected: filterData && (filterData as any[]).findIndex(w => w.code == "1") >= 0
  },
  {
    "description": 'No',
    "code": "0",
    active: true,
    selected: filterData && (filterData as any[]).findIndex(w => w.code == "0") >= 0
  }
  ];
}
export function sha256(ascii: any) {
  function rightRotate(value: number, amount: number) {
    return (value >>> amount) | (value << (32 - amount));
  };

  var mathPow = Math.pow;
  var maxWord = mathPow(2, 32);
  var lengthProperty = 'length'
  var i, j; // Used as a counter across the whole file
  var result = ''

  var words: any = {};
  var asciiBitLength = ascii[lengthProperty] * 8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  var hash =  [];
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  var k:any =  [];
  var primeCounter = k[lengthProperty];
  /*/
var hash = [], k = [];
var primeCounter = 0;
//*/

  var isComposite:any = {};
  for (var candidate = 2; primeCounter < 64; candidate++) {
    if (!isComposite[candidate]) {
      for (i = 0; i < 313; i += candidate) {
        isComposite[i] = candidate;
      }
      hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
      k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
    }
  }

  ascii += '\x80' // Append Ƈ' bit (plus zero padding)
  while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
  for (i = 0; i < ascii[lengthProperty]; i++) {
    j = ascii.charCodeAt(i);
    if (j >> 8) return; // ASCII check: only accept characters in range 0-255
    words[i >> 2] |= j << ((3 - i) % 4) * 8;
  }
  words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
  words[words[lengthProperty]] = (asciiBitLength)

  // process each chunk
  for (j = 0; j < words[lengthProperty];) {
    var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
    var oldHash = hash;
    // This is now the undefinedworking hash", often labelled as variables a...g
    // (we have to truncate as well, otherwise extra entries at the end accumulate
    hash = hash.slice(0, 8);

    for (i = 0; i < 64; i++) {
      var i2 = i + j;
      // Expand the message into 64 words
      // Used below if 
      var w15 = w[i - 15], w2 = w[i - 2];

      // Iterate
      var a:any = hash[0], e = hash[4];
      var temp1: any = hash[7]
        + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
        + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
        + k[i]
        // Expand the message schedule if needed
        + (w[i] = (i < 16) ? w[i] : (
          w[i - 16]
          + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
          + w[i - 7]
          + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
        ) | 0
        );
      // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
      var temp2: any = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
        + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

      hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
      hash[4] = (hash[4] + temp1) | 0;
    }

    for (i = 0; i < 8; i++) {
      hash[i] = (hash[i] + oldHash[i]) | 0;
    }
  }

  for (i = 0; i < 8; i++) {
    for (j = 3; j + 1; j--) {
      var b = (hash[i] >> (j * 8)) & 255;
      result += ((b < 16) ? 0 : '') + b.toString(16);
    }
  }
  return result;
};
export function httpOptions() {
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    })
  };
}

// validate time
export function validateTime(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.length === 0) {
    return null;
  }
  var date = moment(control.value);
  const status = date.isValid();

  return status ? null : { 'date': true };
}
