<div class="example-container">
    <form [formGroup]="formAdd">
        <div class="row" fxLayout="column" fxLayoutGap="1em">
            <div class="form-group col-lg-6 ">
                <div class="form-group col-lg-12" style="margin-top: 9px;">
                    <small id="name" class="form-text text-muted input-required">Practice</small>
                    <app-multi-select [dropdownList]="_lookupData.clients" (onSelectionChange)="onSelectionChange($event)" [selectedItems]="selectedClients" [singleSelection]="'true'" [placeholder]="'practice'" #clients></app-multi-select>
                    <mat-error *ngIf="formAdd.hasError('client') && formAdd.touched">
                        Practice is required
                    </mat-error>

                </div>
            </div>

            <div class="fiscalyear form-group col-lg-6" style="top: 9px;">
                <div class="form-group">
                    <small id="name" class="form-text text-muted input-required" style="width:100%">Accounting Month</small>
                    <div class="input-group col-6 mx-auto">
                        <input name="fiscalyear"
                               [type]="'text'"
                               class="form-control"
                               placeholder="MM/yyyy"
                               (click)="fiscalyearpicker.open()" formControlName="accountingMonth">
                    </div>
                    <input name="fiscalyear1"
                           style="width:0.1px;visibility:hidden;height: .00px;padding: 0px !important;"
                           type="text"
                           class="form-control" [formControl]="accountingMonthhidden"
                           placeholder="MM/yyyy" [matDatepicker]="fiscalyearpicker">
                    <mat-datepicker (monthSelected)="setMonthAndYear($event, fiscalyearpicker)" class="form-group-text" startView="multi-year" style="float:right;" #fiscalyearpicker></mat-datepicker>
                    <mat-error *ngIf="formAdd.controls.accountingMonth.hasError('required') && formAdd.controls.accountingMonth.touched">
                        Accounting Month is required
                    </mat-error>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <small id="name" class="form-text text-muted input-required" style="width:100%">Revenue</small>
                <input class="form-control" formControlName="accountingNetRevenue" type="text" autocomplete="off" (keypress)="keyPress($event)" (change)="onInputChange($event)">
                <mat-error *ngIf="formAdd.controls.accountingNetRevenue.hasError('required') && formAdd.controls.accountingNetRevenue.touched">
                    Net Revenue is required
                </mat-error>
            </div>
            <div class="form-group col-lg-6" *ngIf="false">
                <small id="name" class="form-text text-muted input-required" style="width:100%">Addition</small>
                <input class="form-control" formControlName="accountingAddition" type="text" autocomplete="off" (keypress)="keyPress($event)" (change)="onInputChange($event)">
                <mat-error *ngIf="formAdd.controls.accountingAddition.hasError('required') && formAdd.controls.accountingAddition.touched">
                    Addition is required
                </mat-error>
            </div>
            <div class="form-group col-lg-6" *ngIf="false">
                <small id="name" class="form-text text-muted input-required" style="width:100%">Subtraction</small>
                <input class="form-control" formControlName="accountingSubtraction" type="text" autocomplete="off" (keypress)="keyPress($event)" (change)="onInputChange($event)">
                <mat-error *ngIf="formAdd.controls.accountingSubtraction.hasError('required') && formAdd.controls.accountingSubtraction.touched">
                    Subtraction is required
                </mat-error>
            </div>
            <div class="form-group col-lg-6">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="isActive">
                    <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                </div>
            </div>



            <div class="form-group col-lg-6">
                <!--<button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasGroup_Edit">Save Changes</button>-->
                <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')">Save Changes</button>
                <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
            </div>
        </div>

    </form>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
