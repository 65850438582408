import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-event-dialog',
  templateUrl: './delete-event-dialog.component.html',
  styleUrls: ['./delete-event-dialog.component.css']
})
export class DeleteEventDialogComponent {
  options: any[] = [{ code: '1', describe: "This event" },
    { code: '2', describe: "This and following events" },
    { code: '3', describe: "following events" },
  ];
  option: any = '1';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeleteEventDialogComponent>) {

  }
  public doYes() {
    this.dialogRef.close({ result: true, data: this.option });
  }

  public doNo() {
    this.dialogRef.close({ result: false });
  }
}
