<section class="edit-container-popup">     
    <div class="popup-form">
        <img [src]="data"
             style="max-width:100%;cursor:pointer;max-height: 90vh;width: 100%;"
             class="modal-hover-opacity" />
    </div>
    <div class="button-group"> 
        <button mat-flat-button extended class="btn" (click)="doCancelEdit(false)">Cancel</button>
    </div>
</section>
