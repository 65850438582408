import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { compare } from '../../../Utility/CustomValidators.utilities';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { NetRevenueService } from '../net-revenue.service';

@Component({
  selector: 'app-net-revenue-list',
  templateUrl: './net-revenue-list.component.html',
  styleUrls: ['./net-revenue-list.component.css']
})
export class NetRevenueListComponent {
  public filterData: any = {};
  public _netrevenuesList: any[] = [];
  public sortedData = new MatTableDataSource<any>([]);
  public sort!: Sort;
  public filterColumn: string = '';
  public hasnetrevenues_Edit: boolean = false;
  public hasnetrevenues_Delete: boolean = false;
  displayedColumns: string[] = ['action', 'practicename', 'accountingmonth', "netrevenue", "isactive", 'createdby', 'createdon', 'modifiedby', 'modifiedon'];
  public pageSize = 10;
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  ngOnInit() {
    this.setPrivileges();
  }
  @Input() set netRevenueList(netrevenuesList: any[]) {
    this.sortedData.data = netrevenuesList;
    this._netrevenuesList = netrevenuesList;
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }

  constructor(private dialog: MatDialog, private netrevenuesservice: NetRevenueService, private auth: AuthService) { }

  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();
  }
  sortAndFilterData() {
    //debugger;
    let filteredData = this._netrevenuesList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;

    });

    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: any, b: any) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'practicename':
          return compare(a.practicename, b.practicename, isAsc);
        case 'netrevenue':
          return compare(a.netrevenue, b.netrevenue, isAsc);
        case 'defaultvalue':
          return compare(a.defaultvalue, b.defaultvalue, isAsc);
        case 'effectivestartdate':
          return compare(a.effectivestartdate, b.effectivestartdate, isAsc);
        case 'effectiveenddate':
          return compare(a.effectiveenddate, b.effectiveenddate, isAsc);
        case 'isactive':
          return compare(a.isactive, b.isactive, isAsc);
        case 'createdon':
          return compare(a.createdon, b.createdon, isAsc);
        case 'createdby':
          return compare(a.createdby, b.createdby, isAsc);
        case 'modifiedby':
          return compare(a.modifiedby, b.modifiedby, isAsc);
        case 'modifiedon':
          return compare(a.modifiedon, b.modifiedon, isAsc);
        default:
          return 0;
      }
    });
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    // if (this.hasClient_Edit) {
    this.onShowDetails.emit(item);
    //}
  }

  @Output() onEditNetRevenue = new EventEmitter();
  doEditnetrevenue(netrevenues: any) {
    this.onEditNetRevenue.emit(netrevenues);
  }

  @Output() onDelete = new EventEmitter<any>();

  async doDeletenetrevenue(netrevenues: any) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;

    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      let request =       [{
        "netrevenueid": netrevenues.netrevenueid ?? -1
          , "uniquepracticeid": -1
          , "accountingMonth": "011990"
          , "netRevenue": 0
          , "addition": 0
          , "subtraction": 0
          , "isactive":2
          , "loginuserid": this.auth.currentUser.username
        }]
      this.netrevenuesservice.SetNetRevenueList(request).then(res => {
        data.message = res[0].errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();
  }

  setPrivileges() {
    this.hasnetrevenues_Edit = this.auth.hasPrivilege(PrivilegeValues.netrevenue_Edit);
    this.hasnetrevenues_Delete = this.auth.hasPrivilege(PrivilegeValues.netrevenue_Delete);
    if (!this.hasnetrevenues_Edit && !this.hasnetrevenues_Delete) {
      let index = this.displayedColumns.indexOf('netrevenues');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }

  }

}
