import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User, UserDetails } from './user';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'user';
  constructor(private http: HttpClient) { }

  public async getUsers(request: any[]): Promise<any> {     
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }  
  
  async setUser(request: any): Promise<any> {
    //
    //let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setuser`,
    //  JSON.stringify(request),
    //  httpOptionsJSON).toPromise();
    //return result;

    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
    }
  async getUserDetail({ id }: { id: number; }): Promise<any> {
    if (!id || id==-1) {
      return undefined
    }
    
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,       
      httpOptionsJSON).toPromise();
    return result;
  }
  public async deleteUser(request: any[]): Promise<any> {    
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/deleteuser`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;

  }
  async getUserGroups(request:any[]): Promise<any> {
     
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/groups`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserGroups(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setgroups`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getNotificationSetting(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getnotificationsetting`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setNotificationSetting(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setnotificationsetting`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getUserRoles(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/roles`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserRoles(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setroles`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getUserSecurity(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/security`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserSecurity(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setpassword`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserToken(): Promise<any> {

    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/token/${localStorage.getItem("thememode") }`,
      httpOptionsJSON).toPromise();
    return result;
  }
  //getcurrentuser
  async GetCurrentUser(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getcurrentuser`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getLookup(request: any[]): Promise<any> {

    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/lookup`, 
      httpOptionsJSON).toPromise();
    return result;
  }
  async getMenus(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/menus`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getUserReports(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/userreports`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getTeams(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/teams`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async isSingleAccount(): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/issingleaccount`,
      httpOptionsJSON).toPromise();
    return result;
  }
  async getUserClient(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/client`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserClient(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setClient`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getUserPhysician(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/physicians`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async setUserPhysician(request: any[]): Promise<any> {

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setphysician`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

}
