<section class="edit-container-popup" *ngIf="!isFromInfo">
    <mat-card-title *ngIf="!isFromInfo">
        Template
    </mat-card-title>
    <form [formGroup]="formAdd" class="popup-form">
        <div fxLayout="column" fxLayoutGap="1em">

            <div class="row" style="width: 560px;">


                <div class="client-group" [class.col-lg-10]="!_isFromInfo" [class.col-lg-5]="_isFromInfo" style="position: relative; width:50%;">
                    <small id="name" class="form-text text-muted input-required">Client</small>
                    <app-multi-select (onSelectionChange)="onSelectionChangeForTemplate($event)" [dropdownList]="_clientlist" [singleSelection]="'true'" [placeholder]="'Client'" #clientlist></app-multi-select>

                </div>

                <div class="template-group" [class.col-lg-10]="!_isFromInfo" [class.col-lg-5]="_isFromInfo" style="position: relative; right: 13px; width: 50%;">
                    <small id="name" class="form-text text-muted  input-required">Template</small>
                    <app-multi-select (onSelectionChange)="onSelectionChangeForTemplate($event)" [dropdownList]="_templatelist" [singleSelection]="'true'" [placeholder]="'Template'" #templatelist></app-multi-select>

                </div>

            </div>
            <div class="row" style="width: 560px;" *ngIf="!isProviderInfo">
                <div class="template-group" [class.col-lg-10]="!_isFromInfo" [class.col-lg-5]="_isFromInfo" style="position: relative; width: 50%;">
                    <small id="name" class="form-text text-muted  input-required">Type</small>
                    <app-multi-select [dropdownList]="_cptTypelist" [singleSelection]="'true'" [placeholder]="'CPT Types'" #cptTypes></app-multi-select>

                </div>

            </div>

            <div class="row" style="width: 560px;" *ngIf="isProviderInfo">


                <div class="provider-group" [class.col-lg-10]="!_isFromInfo" [class.col-lg-5]="_isFromInfo" style="position: relative; width:70%;">
                    <small id="name" class="form-text text-muted">Service Provider<span style="color:red">*</span></small>
                    <app-multi-select (onSelectionChange)="onSelectionChangeForTemplate($event)" [dropdownList]="_providerlist" [singleSelection]="'true'" [placeholder]="'Service Provider'" #providerlist></app-multi-select>
                    <!--<mat-error *ngIf="formAdd.get('client')?.hasError('required') && formAdd.get('client')?.touched">
                      Client is required
                    </mat-error>-->
                </div>

            </div>




        </div>
    </form>
    <mat-divider></mat-divider>
    <div class="button-group">
        <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting" class="btn" (click)="onNextMapiing()">Next</button>
        <button mat-flat-button extended class="btn" *ngIf="!isFromInfo" (click)="doCancelEdit(false)" [disabled]="waiting">Cancel</button>
    </div>
</section>

<section class="table-container" *ngIf="isFromInfo">
    <mat-card-title *ngIf="isFromInfo">
        Mapping
    </mat-card-title>

    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" style="width: 600px; min-height: 230px;">

        <ng-container matColumnDef="template">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Template
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
                {{element.Headers}}
            </td>
        </ng-container>

        <ng-container matColumnDef="csv">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                CSV
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
                <div class="form-group" style="margin-top: 9px;" [class.col-lg-12]="!_isFromInfo" [class.col-lg-6]="_isFromInfo">
                    <!--<small id="name" class="form-text text-muted">Practice<span style="color:red">*</span></small>-->
                    <app-multi-select (onSelectionChange)="onSelectionChange($event,element)" [dropdownList]="_columnHeaderlist" [singleSelection]="'true'" [placeholder]="'column'" #template></app-multi-select>
                    <!--<mat-error *ngIf="formAdd.get('csvcolumn')?.hasError('required') && formAdd.get('csvcolumn')?.touched">
                      Column is required
                    </mat-error>-->
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the template</td>
        </tr>
    </table>
    <div class="button-group" style="position: relative; float: right; top: 97px; margin-right: 6px;">
        <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting" class="btn" (click)="onSaveMapiing()">Save</button>
        <button mat-flat-button extended class="btn" (click)="doCancelEdit(false)" [disabled]="waiting">Cancel</button>
    </div>
</section>

