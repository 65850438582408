<section class="table-container">

  <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">


    <ng-container matColumnDef="physicianmonthlypercentagerateid">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>
      </td>
    </ng-container>

    <ng-container matColumnDef="practicename">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Practice Name</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.practicename }}
      </td>
    </ng-container>

    <ng-container matColumnDef="effectivestartdate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-effective-date">Effective Start Date</th>
      <td mat-cell *matCellDef="let element">
        {{element.effectivestartdate | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="effectiveenddate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-effective-date">Effective End Date</th>
      <td mat-cell *matCellDef="let element">
        {{element.effectiveenddate | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month1">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 1</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month2">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 2</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month2 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month3">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 3</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month3 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month4">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 4</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month4 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month5">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 5</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month5 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month6">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 6</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month6 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month7">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 7</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month7 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month8">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 8</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month8 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month9">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 9</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month9 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month10">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 10</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month10 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month11">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 11</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month11 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="month12">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-month">Month 12</th>
      <td mat-cell *matCellDef="let element" class="text-transform-capital">
        {{element.month12 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdby">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">Created By</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdby }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdon">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">Created On</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="modifiedby">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">Modified By</th>
      <td mat-cell *matCellDef="let element">
        {{element.modifiedby }}
      </td>
    </ng-container>

    <ng-container matColumnDef="modifiedon">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">Modified On</th>
      <td mat-cell *matCellDef="let element">
        {{element.modifiedon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>
    </tr>
  </table>
</section>
<mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item *ngIf="hasMaturityCurve_Edit" (click)="doEditMaturityCurve(item)">Edit</button>
    <!--<button mat-menu-item (click)="doDeleteClient(item)"> Delete</button>-->
  </ng-template>
</mat-menu>
<!--<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menuFilter" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #menuFilter="matMenu">
  <ng-template matMenuContent let-item="item">
    <div mat-dialog-title (click)="$event.stopPropagation();">
      <app-custom-filter [masterData]="item" (onFilterApply)="onFilterApply($event)"></app-custom-filter>
    </div>
  </ng-template>
</mat-menu>-->
