<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="matMenuTheam" mat-icon-button class="m-r-5">
    <mat-icon>brightness_low</mat-icon>
    <!--  <mat-icon>perm_identity</mat-icon> -->
</button>
<button (click)="showFilter = !showFilter" cdkOverlayOrigin #trigger="cdkOverlayOrigin" mat-icon-button class="m-r-5">
    <mat-icon>notifications_none</mat-icon>
    <!--  <mat-icon>perm_identity</mat-icon> -->
</button>
<!-- <button (click)="loginRedirect()" mat-icon-button class="m-r-5">
    login
</button> -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> -->
    <app-avatar [username]="auth.currentUser.username" [userInitialclass]="'large'"></app-avatar>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!--  <button mat-menu-item>
         <mat-icon>settings</mat-icon> Settings
     </button> -->
    <a [routerLink]="['/Profile']" mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile
    </a>
    <!-- <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications
    </button> -->
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>
<mat-menu #matMenuNotifications="matMenu" class="mymegamenu">
    <div class="d-flex no-blcok" *ngFor='let activity of activities'>
        <div class="m-r-20" style="margin-left: 10px;">
            <app-avatar [username]="activity.fromuser"></app-avatar>
        </div>
        <div class="p-b-10 b-b m-b-10">
            <h4 class="m-0">
                {{activity.fromuser}}
                <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
            </h4>
            <div class="ellipses-on-line-1">
                <p *ngIf="activity.actionurlid ==''" class="text-muted" style="margin: 10px 0;">{{activity.notificationbody}}</p>
                <a *ngIf="activity.actionurlid !=''" [routerLink]="[activity.actionurlid]" style="margin: 10px 0;">{{activity.notificationbody}}</a>
            </div>

        </div>
    </div>

</mat-menu>
<ng-template cdkConnectedOverlay
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayOpen]="showFilter"
             [cdkConnectedOverlayHasBackdrop]="true"
             (detach)="showFilter = false"
             (backdropClick)="showFilter = false">
    <div class="filter-pane" style="width: 350px;">
        <section class="notifications-popup">
            <mat-card-title style="text-align:center">
                Notifications
            </mat-card-title>
            <mat-divider style="padding-bottom: 10px;"></mat-divider>
            <div class="d-flex no-blcok" *ngFor='let activity of activities'>
                <div class="m-r-20" style="margin-left: 10px;">
                    <app-avatar [username]="activity.fromuser"></app-avatar>
                </div>
                <div class="p-b-10 b-b m-b-10">
                    <h4 class="m-0">
                        {{activity.fromuser}}
                        <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
                    </h4>
                    <div class="ellipses-on-line-1">
                        <p *ngIf="activity.actionurlid ==''" class="text-muted" style="margin: 10px 0;">{{activity.notificationbody}}</p>
                        <a *ngIf="activity.actionurlid !=''" [routerLink]="[activity.actionurlid]" style="margin: 10px 0;">{{activity.notificationbody}}</a>
                    </div>

                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="button-group" style="text-align:center">
                <a [routerLink]="['/notifications']">More Notifications</a>
            </div>

        </section>
    </div>
</ng-template>
<mat-menu #matMenuTheam="matMenu" class="mymegamenu" xPosition="before">
    <button mat-menu-item (click)="toggleTheme(0)">
        <mat-icon>brightness_low</mat-icon> Light
    </button>
    <button mat-menu-item (click)="toggleTheme(1)">
        <mat-icon>brightness_high</mat-icon> Dark
    </button>

</mat-menu>


