import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { action } from '../action-master';
import * as Custom from '../../../Utility/CustomValidators.utilities'
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { ActionMasterService } from '../action-master.service';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-action-master-list',
  templateUrl: './action-master-list.component.html',
  styleUrls: ['./action-master-list.component.css']
})
export class ActionMasterListComponent {
  public filterData: any = {};
  public _actionList: action[] = [];
  public sortedData = new MatTableDataSource<action>([]);
  public sort!: Sort;
  public filterColumn: string = '';
  public hasActionMaster_Edit: boolean = false;
  public hasActionMaster_Delete: boolean = false;
  displayedColumns: string[] = ['actions','actionname','actiondescription' ,'isactive', 'createdby', 'createdon', 'modifiedby', 'modifiedon'];
  public pageSize = 10;
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  ngOnInit() {
    this.setPrivileges();
  }

  @Input() set actionList(actionList: action[]) {
    this.sortedData.data = actionList;
    this._actionList = actionList;
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }
  constructor(private dialog: MatDialog, private actionservice: ActionMasterService, private auth: AuthService) { }

  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();
  }
  sortAndFilterData() {
    let filteredData = this._actionList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;

    });

    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: action, b: action) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'actionname':
          return Custom.compare(a.Actionname, b.Actionname, isAsc);
        case 'description':
          return Custom.compare(a.Actiondescription, b.Actiondescription, isAsc);
        case 'isactive':
          return Custom.compare(a.isactive, b.isactive, isAsc);       
        case 'createdby':
          return Custom.compare(a.createdby, b.createdby, isAsc);
        case 'createdon':
          return Custom.compare(a.createdon, b.createdon, isAsc);
        case 'modifiedby':
          return Custom.compare(a.modifiedby, b.modifiedby, isAsc);
        case 'modifiedon':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        default:
          return 0;
      }
    });
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {    
   // if (this.hasClient_Edit) {
      this.onShowDetails.emit(item);
    //}
  }

  @Output() onEditAction = new EventEmitter();
  doEditAction(action: action) {    
    this.onEditAction.emit(action);
  }

  @Output() onDelete = new EventEmitter<action>();

  async doDeleteAction(action: action) {    
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      this.actionservice.deleteAction(action._id).then(res => {
        data.message = res?.message;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data);
        this.onDelete.emit();
      })
    }
  }

  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();
  }

  setPrivileges() {
    this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
    this.hasActionMaster_Delete = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Delete);
    if (!this.hasActionMaster_Edit && !this.hasActionMaster_Delete) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }
  }

}
