import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-step',
  templateUrl: './custom-step.component.html',
  styleUrls: ['./custom-step.component.css']
})
export class CustomStepComponent {

}
