import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../../Utility/CustomValidators.utilities';



@Injectable({
  providedIn: 'root'
})
export class CptDetailsService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'CPTDetails';
  public formData = new FormData();
  constructor(private http: HttpClient) {
   
  }

  public async getcptdetailsList(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }

  public async getcptDetails(id: string): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptions()).toPromise();
    return result;
  }

  async setCptDetails(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }
  async GetLookup(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/lookup`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }
  async getPatientLookup(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/patientlookup`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }

  public async deleteCptDetails(id: string): Promise<any> {
    let result = await this.http.delete(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptions()).toPromise();
    return result;
  }


  public async SendFile(file: string, calbasckfun: any): Promise<any> {
    
    this.formData.append('file', file);

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/inputfile`,
      this.formData).subscribe(res => {
        calbasckfun(res);
        return res;
      }
    )
    //return result;
  }

  public async gettemplateDetails(): Promise<any> {
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/template`,
      httpOptions()).toPromise();
    return result;
  }
  
   public async gettemplateHeaders(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/templateheader`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
   }

  public async setBulkUploading(request: any[]): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/bulkupload`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }

  
  
}
