<section class="table-container-inner" tabindex="0">     
    <div>
        <div fxLayout="column" fxLayoutGap="1em">
            <ng-container *ngFor="let item of practice;index as i">
                <div fxLayout="column" fxLayoutGap=".7em">
                    <div fxLayout="row" fxLayoutGap="1em">
                        <div fxFlex="5%"> <mat-icon (click)="item.expanded=!item.expanded" class="float-end">{{ item.expanded?'expand_less':'expand_more'}}</mat-icon></div>
                        <div fxFlex="2%"> <mat-checkbox class="example-margin" [checked]="item.assigned==1" (change)="onCheckboxChange($event,item,'all')"></mat-checkbox></div>
                        <div fxFlex="90%"> {{item.practicename?.toLocaleLowerCase()}}</div>
                    </div>
                    <div *ngIf="item.expanded" fxLayout="column" fxLayoutGap=".5em">
                        <ng-container *ngFor="let subitem of physicianList(item);index as i">
                            <div fxLayout="row" fxLayoutGap="1.5em">
                                <div fxFlex="7%"> </div>
                                <div fxFlex="2%"> <mat-checkbox class="example-margin" [checked]="subitem.assigned==1" (change)="onCheckboxChange($event,subitem,'all')"></mat-checkbox></div>
                                <div fxFlex="90%"> {{subitem.name?.toLocaleLowerCase()}}</div>
                            </div>
                        </ng-container>

                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</section>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
    <button mat-flat-button (click)="doSave()" color="primary" *ngIf="hasGroupClient_Edit">Save Changes</button>
    <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>

<app-data-loader [isLoading]="_isLoading"></app-data-loader>
