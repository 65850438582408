import { Directive } from '@angular/core';

@Directive({
  selector: '[appMaxRange]'
})
export class MaxRangeDirective {

  constructor() { }

}
