import { Component, EventEmitter, Inject, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { compare, FormatDate } from '../../../Utility/CustomValidators.utilities';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { PayrollService } from '../payroll.service';
import { PayrollPreviewListComponent } from './payroll-preview-list/payroll-preview-list.component';

@Component({
  selector: 'app-payroll-preview-dialog',
  templateUrl: './payroll-preview-dialog.component.html',
  styleUrls: ['./payroll-preview-dialog.component.css']
})
export class PayrollPreviewDialogComponent {
  //public isLoaded: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PayrollPreviewDialogComponent>, private dialog: MatDialog, private auth: AuthService
    , private payrollService: PayrollService
  ) {

  }
  @ViewChild('payrollpreviewlist') _payrollpreviewlist!: PayrollPreviewListComponent;
  public doYes() {
    this.dialogRef.close({ result: true, data: null });
  }

  public doNo() {
    this.dialogRef.close({ result: false });
  }
  isLoaded() {
    return (this.data) ? true : false;
  }
  public isWaiting: boolean = false;
  public doSave() {
    this.isWaiting = true;
    let data = this._payrollpreviewlist.payrollList.filter((w: any) => { return w.isDirty });
    if (data.length > 0) {
      data = data.map((w: any) => {
        w.massageddatacreatedby = this.auth.currentUser.username
        w.massageddatamodifiedby = this.auth.currentUser.username
        w.massageddatamodifiedon = moment.utc().format("MM/DD/YYYY HH:mm:ss")
        w.massageddatacreatedon = moment.utc().format("MM/DD/YYYY HH:mm:ss")
        w.providerbaseunits = (w.providerbaseunits) ? w.providerbaseunits : null;
        w.dob = (w.dob) ? w.dob : null;
        w.unit_charge = (w.unit_charge) ? w.unit_charge : null;
        w.units = (w.units) ? w.units : null;
        w.total_charges = (w.total_charges) ? w.total_charges : null;
        w.baseunits = (w.baseunits) ? w.baseunits : null;
        w.timeunits = (w.timeunits) ? w.timeunits : null;
        w.providerbaseunits = (w.providerbaseunits) ? w.providerbaseunits : null;
        w.providertimeunits = (w.providertimeunits) ? w.providertimeunits : null;
        w.rowtype = (w.rowtype == -1 || !w.rowtype) ? 3 : w.rowtype;
        return w;
      });
      this.payrollService.setPayrollData(data).then((res) => {
        let dialogdata: ConfirmDialogData = {} as ConfirmDialogData;

        this.isWaiting = false;
        this.dialogRef.close({ result: true, data: null });
        dialogdata.message =( res.data.table1 ? res.data.table1[0]?.errormessage : undefined) ?? "Information updated successfully.";
        dialogdata.title = 'Information!';
        dialogdata.showNo = false;
        dialogdata.showYes = true;
        dialogdata.noText = 'NO';
        dialogdata.yesText = 'OK';
        Confirmation(this.dialog, dialogdata);
      });
    }
    else {
      this.dialogRef.close({ result: false, data: null });
    }
  }

}
