import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { LookupEntityBase } from '../../entities/lookup-entity';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnChanges, AfterViewInit, OnInit {

  start = new FormControl();
  end = new FormControl();
  public customRanges: any[] = [
    { "description": 'Today', code: 't', active: true },
    { "description": 'Yesterday And Today', code: 'ty', active: true },
    { "description": 'Yesterday', code: 'y', active: true },
    { "description": 'Last 7 Days', code: 'l7', active: true, longCode: 'last7' },
    { "description": 'Last 30 Days', code: 'l30', active: true, longCode: 'last30' },
    { "description": 'Last Weeks', code: 'lw', active: true, longCode: 'lastweek' },
    { "description": 'This Weeks', code: 'tw', active: true },
    { "description": 'Last Month', code: 'lm', active: true, longCode:'lastmonth' },
    { "description": 'This Month', code: 'tm', active: true}];
  public cust =
    { "description": 'Custom Range', code: 'ct', active: true }
  public selectedRange: any = this.customRanges[0];
  public selectedValue: string = this.customRanges[0].description;
  public showCalander: boolean = false;
  @Output() onSelectionChange = new EventEmitter<any>();
  calendar!: MatCalendar<Date>;
  @ViewChild('calendar', { static: false }) set content(calendar: MatCalendar<Date>) {
    if (calendar) { // initially setter gets called with undefined
      this.calendar = calendar;
      //if (this.calendar && this.selectedDate)
      //  this.calendar?._goToDateInView(this.selectedDate, "month")
    }
  }

  @Input() set value(value: any) { 
    this.selectedRange = this.customRanges.find(w => {
      return w.code == value || w.description == value || w.longCode == value
    })
    if (!this.selectedRange) {
      this.selectedRange = this.cust;
      this.selectedValue = value;
    }
    else {
      this.selectedValue = this.selectedRange.description
    }

  }
  get value() {
    return this.selectedValue;

  }
  constructor() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  onChanges(type: string, item: any) {
    this.selectedValue = item.description
    this.showCalander = false;
  }
  onClose(item: any) {
    this.selectedValue = `${moment(this.start.value).format('MM/DD/YYYY')} - ${moment(this.end.value).format('MM/DD/YYYY')}`
    this.showCalander = false;
  }
  onCalendarSelect(item: any) {

  }
  ngAfterViewInit() {

  }
  ngOnInit() {

  }

  onShowCalander() {
    this.showCalander = !this.showCalander;

  }
}
