<div class="example-container">
  <form [formGroup]="formAdd">
    <div class="row" fxLayout="column" fxLayoutGap="1em">
      <div class="form-group col-lg-6 ">
        <div class="form-group" style="margin-top: 9px;" [class.col-lg-12]="!_isFromInfo" [class.col-lg-6]="_isFromInfo">
          <small id="name" class="form-text text-muted">Practice<span style="color:red">*</span></small>
          <app-multi-select (onSelectionChange)="onSelectionChange($event)" [dropdownList]="_lookupData.clients" [selectedItems]="selectedClients" [singleSelection]="'true'" [placeholder]="'practice'" formControlName="Practice" #clients></app-multi-select>
          <mat-error *ngIf="formAdd.get('practice')?.hasError('required') && formAdd.get('practice')?.touched">
            Practice is required
          </mat-error>

        </div>
      </div>
      <div class="form-group col-lg-6 ">
        <div class="form-group" style="margin-top: 9px;" [class.col-lg-12]="!_isFromInfo" [class.col-lg-6]="_isFromInfo">
          <small id="name" class="form-text text-muted">Provider<span style="color:red">*</span></small>
          <app-multi-select [dropdownList]="_lookupData.serviceProviderFiltered" [selectedItems]="selectedProviders" [singleSelection]="'true'" [placeholder]="'Provider'" formControlName="provider" #serviceProvider></app-multi-select>
          <mat-error *ngIf="formAdd.get('provider')?.hasError('required') && formAdd.get('provider')?.touched">
            Provider is required
          </mat-error>
        </div>
      </div>

      <div class="row col-lg-6" style="margin-bottom: 1em; padding-right: 0px;">
        <small id="name" class="form-text text-muted" style="width:100%">
          Blackout Start Time<span style="color:red">*</span>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('effectivestartdate')?.hasError('required') && formAdd.get('effectivestartdate')?.touched">
            Please Select date
          </mat-error>
        </small>

        <div class="form-group col-lg-6 ">
          <div style="position: relative;">
            <input type="text" [matDatepicker]="picker" class="form-control" readonly id="nametext" aria-describedby="startdate" placeholder="MM-dd-YYYY" formControlName="startdate">
            <mat-datepicker-toggle matIconSuffix [for]="picker" style="position: absolute; right: 0px; bottom: 0px; "></mat-datepicker-toggle>
            <mat-datepicker class="form-group-text" style="float:right;" #picker></mat-datepicker>
          </div>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('startdate')?.hasError('required') && formAdd.get('startdate')?.touched">
            Please Select date
          </mat-error>
        </div>

        <div class="form-group col-lg-6" style="padding-right: 0px;">
          <input type="time" class="form-control" [value]="physician?.blackoutstarttime??''" id="starttimetext" aria-describedby="starttime" placeholder="Blackout Start Time" formControlName="blackoutstarttime" />
          <mat-error *ngIf="formAdd.get('blackoutstarttime')?.hasError('required') && formAdd.get('blackoutstarttime')?.touched">
            Blackout Start Time is required
          </mat-error>
        </div>
      </div>


      <div class="row col-lg-6" style="margin-bottom: 1em; padding-right: 0px;">
        <small id="name" class="form-text text-muted" style="width:100%">
          Blackout End Time<span style="color:red">*</span>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('blackoutendtime')?.hasError('required') && formAdd.get('blackoutendtime')?.touched">
            Please Select date
          </mat-error>
        </small>

        <div class="form-group col-lg-6 ">
          <div style="position: relative;">
            <input type="text" [matDatepicker]="picker1" class="form-control" readonly id="nametext" aria-describedby="enddate" placeholder="MM-dd-YYYY" formControlName="enddate">
            <mat-datepicker-toggle matIconSuffix [for]="picker1" style="position: absolute; right: 0px; bottom: 0px; "></mat-datepicker-toggle>
            <mat-datepicker class="form-group-text" style="float:right;" #picker1></mat-datepicker>
          </div>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('enddate')?.hasError('required') && formAdd.get('enddate')?.touched">
            Please Select date
          </mat-error>
        </div>

        <div class="form-group col-lg-6" style="padding-right: 0px;">
          <input type="time" class="form-control" [value]="physician?.blackoutendtime??''" id="starttimetext" aria-describedby="starttime" placeholder="Blackout Start Time" formControlName="blackoutendtime" />
          <mat-error *ngIf="formAdd.get('blackoutendtime')?.hasError('required') && formAdd.get('blackoutendtime')?.touched">
            Blackout End Time is required
          </mat-error>
        </div>
      </div>


      <!--<div class="form-group col-lg-6 ">
    <small id="endtime" class="form-text text-muted">Blackout End Time<span style="color:red">*</span></small>
    <input type="time" class="form-control" [value]="physician?.blackoutendtime??''" id="endtimetext" aria-describedby="endtime" placeholder="Blackout End Time" formControlName="blackoutendtime" />
    <mat-error *ngIf="formAdd.get('blackoutendtime')?.hasError('required') && formAdd.get('blackoutendtime')?.touched">
      Blackout End Time is required
    </mat-error>
  </div>-->




      <div class="form-group col-lg-6">
        <div class="form-check form-switch">
          <input class="form-check-input" [checked]="action?.isactive" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="isActive">
          <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
        </div>
      </div>

      <div class="form-group col-lg-6">
        <!--<button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasGroup_Edit">Save Changes</button>-->
        <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')">Save Changes</button>
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
      </div>
    </div>

  </form>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
