import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { AuthService } from '../../../services/auth.service';
import { MultiSelectComponent } from '../../../shared/multi-select/multi-select.component';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { NetRevenueService } from '../net-revenue.service';

@Component({
  selector: 'app-net-revenue-edit',
  templateUrl: './net-revenue-edit.component.html',
  styleUrls: ['./net-revenue-edit.component.css']
})
export class NetRevenueEditComponent {

  constructor(private http: NetRevenueService, private auth: AuthService, private dialog: MatDialog) {

  }
  public _netrevenues: any = {};
  public _isLoading: boolean = false;
  public accountingMonthhidden = new FormControl('');
  @ViewChild('clients') _client!: MultiSelectComponent;
  formAdd: FormGroup = new FormGroup({
    accountingMonth: new FormControl('', [Validators.required]),
    accountingNetRevenue: new FormControl('', [Validators.required]),
    accountingAddition: new FormControl(0),
    accountingSubtraction: new FormControl(0),
    isActive: new FormControl(true),
  }, [(control: AbstractControl) => {
    const status = (this.selectedClients?.length ?? 0) > 0;
    return status ? null : { 'client': true };
  }]);
  @Input() set netRevenue(netrevenues: any) {
    if (netrevenues) {
      this._netrevenues = netrevenues;
      this.formAdd.controls.accountingMonth.setValue(this._netrevenues.fiscalyear);
      this.formAdd.controls.accountingNetRevenue.setValue(this._netrevenues.revenueamount);
      this.formAdd.controls.accountingAddition.setValue(this._netrevenues.addition);
      this.formAdd.controls.accountingSubtraction.setValue(this._netrevenues.subtraction);
      this.formAdd.controls.isActive.setValue(this._netrevenues.active == 1);
      this.selectedClients = [{
        code: this._netrevenues.uniquepracticeid,
        description: this._netrevenues.practicename,
        "warehouseuniqueidentifier": this._netrevenues.warehouseuniqueidentifier,
        "practiceid": this._netrevenues.practiceid
      }]

    }
  }

  _lookupData: any = {};
  selectedClients: any[] = [];
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit(false);
  }
  onSelectionChange(item: any[]) {
    this.selectedClients = item;
  }
  @Input() set lookupData(lookupData: any) {
    this._lookupData = lookupData;
  }
  async doSave(type: string) {
    if (this.formAdd.valid && this.selectedClients.length > 0) {

      this._isLoading = true;
      let request = [{
        "accountsrevenueid": this._netrevenues?.accountsrevenueid ?? -1
        , "uniquepracticeid": (this._client?.selectedItems ?? []).length == 0 ? '-1' : this._client?.selectedItems[0].code
        , "Warehouseuniqueidentifier": (this._client?.selectedItems ?? []).length == 0 ? '-1' : this._client?.selectedItems[0].warehouseuniqueidentifier
        , "practiceid": (this._client?.selectedItems ?? []).length == 0 ? '-1' : this._client?.selectedItems[0].practiceid
        , "fiscalyear": this.formAdd.controls.accountingMonth.value
        , "revenueamount": this.formAdd.controls.accountingNetRevenue.value
        , "addition": this.formAdd.controls.accountingAddition.value
        , "subtraction": this.formAdd.controls.accountingSubtraction.value
        , "active": this.formAdd.controls.isActive.value ? 1 : 0
        , "loginuserid": this.auth.currentUser.username
      }];
      this.http.SetNetRevenueList(request).then(res => {
        this._isLoading = false;
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.message = res[0].errormessage;
        data.title = 'Information!';
        data.showYes = false;
        data.noText = 'OK';
        data.showNo = false;
        data.showYes = true;
        data.yesText = 'YES';
        Confirmation(this.dialog, data);
        if (res[0].result == 1) {
          this.onCancelEdit.emit(true);
        }
      })
    }
    else {
      this.formAdd.markAllAsTouched();
    }
  }
  setMonthAndYear(normalizedMonthAndYear: any, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.getMonth());
    ctrlValue.year(normalizedMonthAndYear.getFullYear());
    ctrlValue.date(1);
    this.formAdd.controls.accountingMonth.setValue(ctrlValue.format("MMYYYY"));
    this.accountingMonthhidden.setValue(ctrlValue.format("MM/01/YYYY"));
    datepicker.close();
  }
  public ALL_CURRENCY_REGEXP = /^-?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})*(?:\.[0-9]{1})?$/;
  keyPress(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0;
    let newvalue = value.substring(0, event.currentTarget.selectionStart) + inputChar + value.substring(event.currentTarget.selectionStart);
    if ((inputChar == "-" && value.indexOf('-') > -1) ||
      (inputChar == "." && value.indexOf('.') > -1))
    {
      // invalid character, prevent input
      event.preventDefault();
    }
    else if (inputChar == "-" || inputChar == ".")
    {

    }
    else if (!this.ALL_CURRENCY_REGEXP.test(newvalue)) {
      event.preventDefault();
    }
    //else if (!(this.ALL_CURRENCY_REGEXP.test(inputChar) || inputChar == "." || inputChar == "-")) {
    //  // invalid character, prevent input
    //  event.preventDefault();
    //}
    //else if (!/^(?:\.)$/.test(inputChar) && !this.ALL_CURRENCY_REGEXP.test(newvalue)) {
    //  // invalid values, prevent input
    //  event.preventDefault();
    //}

    
  }
  onInputChange(event: any) {
    //
    let value = event.currentTarget.value

    if (!this.ALL_CURRENCY_REGEXP.test(value)) {
      value = "";
    }

    event.currentTarget.value = value;
  }

}
