<div class="button-group">
    <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" style="width: 100px; margin-left: -100px; z-index: 20000; margin-top: 10px; margin-right: 10px;">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
    </button>
</div>
<mat-tab-group class="mat-table-edit-box" [(selectedIndex)]="selectedTab">
    <mat-tab label="Group" *ngIf="hasGroup_View">
        <app-group-details [group]="group" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)"></app-group-details>
    </mat-tab>
    <mat-tab label="User" [disabled]='!group.isactive || group.groupid==-1'><app-group-user [group]="group" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)" (doNextEdit)="doNextEdit($event)"></app-group-user> </mat-tab>
    <mat-tab label="Client" *ngIf="hasGroupClient_View" [disabled]='!group.isactive || group.groupid==-1'>
        <app-group-client [group]="group" (onCancelEdit)="doCancelEdit($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-group-client>
    </mat-tab>
    <mat-tab label="Physician" *ngIf="hasGroupClient_View" [disabled]='!group?.isactive || user?.groupid==-1'>
        <app-group-physician [group]="group" (onCancelEdit)="doCancelEdit($event)" (onUpdate)="doUpdate($event)" (onShowLoading)="doShowLoading($event)" (doNextEdit)="doNextEdit($event)"></app-group-physician>
    </mat-tab>
</mat-tab-group>

