import * as moment from "moment";

 

export class ReportFilters {
  public static readonly CheckDate = 1;
  public static readonly CalenderDate = 2;  
  public static readonly Practice = 3;
  public static readonly Selector = 4;
  public static readonly TransactionDate = 5;
  public static readonly Servicelocation = 6;
  public static readonly ServiceProvider = 7;
  public static readonly PayorGroups = 8;
  public static readonly Payor = 9;
  public static readonly STransactionDate = 10;
  public static readonly RCheckDate = 11;
  public static readonly RTransactionDate = 12;
}

let DefaultValues: any[] = [
  {
    id: "ccde2cca-a18a-4a8d-968c-7f241895b7c3",
    defalutValue: {
      practiceIds: [],
      transactionstartDate: "last12Month",
      transactionendDate: "last12Month"
    }
  },
  {
    id: "6fb3a239-a861-4786-9984-ce42a4d785b3",
    defalutValue: {
      practiceIds: [],
      selectors: [{ code: 'DOE', description: 'DOE', active: 1 }],
      calenderStartDate: "last1Month",
      calenderEndDate: "last1Month"
    }
  },
  {
    id: "bbc66a7a-4762-4755-a262-2ce140a38114",
    defalutValue: {
      practiceIds: [],
      selectors: [{ code: 'DOE', description: 'DOE', active: 1 }],
      calendarRDate: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  },
  {
    id: "3b1d475f-ef48-4d60-88c2-d6f10e529123",
    defalutValue: {
      practiceIds: [],
      transactionDates: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  },
  {
    id: "dd7754fe-08b3-429f-9f35-8e5abeed7f97",
    defalutValue: {
      practiceIds: [],
      checkDate: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  },
  {
    id: "d9526abe-0d6a-4754-adde-794b557af4b1",
    defalutValue: {
      practiceIds: [],
      checkDate: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  },
  {
    id: "8b2e2dca-a4c6-441c-8520-bdda56015e3e",
    defalutValue: {
      practiceIds: [],
      checkDate: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  },
  {
    id: "1f097765-956a-4de1-808f-5356dcb147f6",
    defalutValue: {
      practiceIds: [],
      checkDate: {
        timeUnit: [{ code: '3', description: 'Months', active: 1 }],
        operator: [{ code: '0', description: 'Last', active: 1 }],
        value: 12
      }
    }
  }

]
export function getDefaultValues(reportId: string): any {
  let values: any = DefaultValues.find(w => { return w.id == reportId })?.defalutValue ?? { practiceIds: [] };
  let newValues: any = {};
  Object.keys(values).forEach((key: string) => {
    let value = values[key]
    switch (key.toLowerCase()) {
      case "transactionstartdate":
      case "calenderstartdate":
        {
          if (value == "last1Month")
            value = moment().subtract(1, 'M').startOf('month').format("YYYY-MM-DD");
          else if (value == "last12Month") {
            value = moment().subtract(12, 'M').startOf('month').format("YYYY-MM-DD");
          }
          else {
            value = moment().startOf('month').format("YYYY-MM-DD");
          }
          break;
        }
      case "transactionenddate":
      case "calenderenddate": {
        if (value == "last1Month" || value == "last12Month")
          value = moment().subtract(1, 'M').endOf('month').format("YYYY-MM-DD");
        else {
          value = moment().endOf('month').format("YYYY-MM-DD");
        }
        break;
      }
    }
    newValues[key] = value;
  });
  return newValues ?? { practiceIds: [] }

}
