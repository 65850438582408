<section class="table-container">
    <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="physicianperiod" sticky>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>
            </td>
        </ng-container>
        <ng-container matColumnDef="blackoutstarttime">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">
                Start Time
            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital">
              {{element.blackoutstarttime | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="blackoutendtime">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">End Time </th>
            <td mat-cell *matCellDef="let element">
              {{element.blackoutendtime | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A' }}
            </td>
        </ng-container>


        <ng-container matColumnDef="isactive">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Active </th>
            <td mat-cell *matCellDef="let element" [app-entity-status]="element.isactive" [app-entity-status-cell]="true">
            </td>
        </ng-container>
        <ng-container matColumnDef="createdby">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-by">
                Created By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-created-by ellipses-on-line-3" [title]="element.CreatedBy">
                {{element.createdby }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="createdon">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-created-on" style="width:45%">Created On </th>
            <td mat-cell *matCellDef="let element" class="cell-created-on text-right" style="width:45%">
                {{element.createdon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="modifiedby">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-by" style="width:45%">
                Modified By

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-modified-by ellipses-on-line-3" [title]="element.modifiedby">
                {{element.modifiedby }}
            </td>
        </ng-container>
        <ng-container matColumnDef="practicename">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-by" style="width:45%">
                practice

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-modified-by ellipses-on-line-3" [title]="element.modifiedby">
                {{element.practicename }}
            </td>
        </ng-container>
        <ng-container matColumnDef="pm_system_servicingprovidername">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-by" style="width:45%">
                provider

            </th>
            <td mat-cell *matCellDef="let element" class="text-transform-capital cell-modified-by ellipses-on-line-3" [title]="element.modifiedby">
                {{element.pm_system_servicingprovidername }}
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="modifiedon">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell-modified-on"> Modified On </th>
            <td mat-cell *matCellDef="let element" class="cell-modified-on"> {{element.modifiedon | datePipeSafe: 'MM/DD/YYYY hh:mm:ss A'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="doShowDetails(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>
        </tr>
    </table>
</section>
<mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item  (click)="doEditPhysicianPeriod(item)">Edit</button>
    <button mat-menu-item  (click)="doDeletePhysicianPeriod(item)"> Delete</button>
  </ng-template>
</mat-menu>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menuFilter" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #menuFilter="matMenu">
  <ng-template matMenuContent let-item="item">
    <div mat-dialog-title (click)="$event.stopPropagation();">
      <app-custom-filter [masterData]="item" (onFilterApply)="onFilterApply($event)"></app-custom-filter>
    </div>
  </ng-template>
</mat-menu>
