<section>
  <!--<mat-card-title>
    <span>Maturity Curve Form</span>
  </mat-card-title>
  <mat-divider></mat-divider>-->

  <form [formGroup]="formAdd">

    <div fxLayout="column" fxLayoutGap="1em">
      <input type="text" matInput formControlName="hiddenId" class="form-control" hidden />

      <div class="row col-6" fxLayout="column" fxLayoutGap="1em">
        <div class="form-group col-12">
          <small class="form-text text-muted">Practice</small>
          <app-multi-select (onSelectionChange)="onSelectionChange($event)" [dropdownList]="clientList" [selectedItems]="selectedPractice" placeholder="Client" [singleSelection]="'true'" #practice></app-multi-select>
        </div>
        <div class="form-group col-12">
          <small id="month" class="form-text text-muted">Maturity Month</small>
          <input type="number" formControlName="numberOfMonthsInput" min="0" class="form-control" (keyup)="addRows()" />
        </div>

        <div class="row">
          <small class="form-text text-muted">Effective Date</small>
          <div class="form-group col-6 position-relative">
            <input name="dateTypeStartDate"
                   [type]="'text'"
                   class="form-control"
                   placeholder="mm/dd/yyyy"
                   (click)="datetypestartdatepicker.open()"
                   formControlName="dateTypeStartDate"
                   [matDatepicker]="datetypestartdatepicker"
                   [min]="minStartDate"
                   (dateChange)="onDateTypeSTRDTChange()">
            <div class="position-absolute" style="right: 18px;top: 8px;">
              <mat-icon matSuffix class="form-input-date-icon" (click)="datetypestartdatepicker.open()">calendar_today</mat-icon>
            </div>
            <mat-datepicker class="form-group-text" style="float:right;" #datetypestartdatepicker></mat-datepicker>
          </div>
          <div class="form-group col-6 position-relative">
            <input name="dateTypeEndDate"
                   [type]="'text'"
                   class="form-control"
                   placeholder="mm/dd/yyyy"
                   (click)="datetypeenddatepicker.open()"
                   formControlName="dateTypeEndDate"
                   [matDatepicker]="datetypeenddatepicker"
                   [min]="minDateTypeEndDate">
            <div class="position-absolute" style="right: 18px;top: 8px;">
              <mat-icon matSuffix class="form-input-date-icon" (click)="datetypeenddatepicker.open()">calendar_today</mat-icon>
            </div>
            <mat-datepicker class="form-group-text" style="float:right;" #datetypeenddatepicker></mat-datepicker>
          </div>
        </div>

        <div class="col-12 months-div" *ngIf="numberOfRows.length > 0">
          <table class="width-100" mat-table [dataSource]="numberOfRows">
            <!--Position Column-->
            <ng-container matColumnDef="monthname">
              <th mat-header-cell *matHeaderCellDef> Month Name </th>
              <td mat-cell *matCellDef="let element" class="">
                {{element.monthName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="inputvalue">
              <th mat-header-cell *matHeaderCellDef> Value </th>
              <td mat-cell *matCellDef="let element" class="">
                <input type="text" matInput min="0" max="1000" [value]="element.inputValue" class="form-control w-100" [id]="element.monthId" (keyup)="calculateTotalPercentage($event)" [disabled]="element.monthId == 'TotalPercentage'" />
              </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" #credentialsRow></tr>

          </table>

        </div>

      </div>
    </div>
  </form>
  <!--<mat-divider></mat-divider>-->
  <div class="row" style="margin-top: 25px;">
    <div class="col-lg-6 col-md-6 col-sm-6" style="text-align:left;">
      <button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting || !canSave()" (click)="onSaveMaturityForm()"><span>Save Changes</span></button>
      <button mat-flat-button extended class="btn" style="margin-right:10px;" (click)="doReset()">Reset</button>
    </div>
  </div>
</section>
