<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title>
                Roles

            </mat-card-title>
            <mat-divider></mat-divider>

            <section class="edit-container">
                <div fxLayout="row" fxLayoutGap="1em" *ngIf="false">
                    <div fxFlex="20%">
                        <app-list>

                        </app-list>

                    </div>
                    <div fxFlex="80%">
                        <!-- component-b.component.html -->
                        <mat-list>

                            <mat-list-item role="listitem" class="mat-list-content-p-0"
                            *ngFor="let item of otherItems; index as i" [id]="i" cdkDrag
                            (CdkDragStart)="dragStart($event)" cdkDropList
                            [cdkDropListData]="otherItems" (cdkDropListDropped)="dropList($event)" cdkDropListSortingDisabled>
                                <mat-checkbox class="example-margin" (change)="onCheckboxChange($event,item)">
                                    {{ item }}
                                </mat-checkbox>

                                <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                                    {{this.selectedItem.length}}
                                </div>
                            </mat-list-item>


                        </mat-list>

                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="1em">
                    <div class="example-container" (dragover)="allowDrop($event)" fxFlex="40%">
                        <h2>To do</h2>


                        <mat-list cdkDropList #otherItemsList="cdkDropList" [cdkDropListData]="otherItems" [cdkDropListConnectedTo]="[doneList]" class="example-list" (cdkDropListDropped)="dropNew($event)" (CdkDragStart)="dragStart($event)" style="width: max-content;">

                            <mat-list-item role="listitem" class="mat-list-content-p-0 demo-section" *ngFor="let item of otherItems; index as i" [id]="i"
                                           cdkDrag (cdkDragStarted)="dragStarted($event)"
                                           (cdkDragEnded)="dragEnded($event)" [matBadge]="item.count" matBadgeOverlap="false">
                                {{item.Name}}
                            </mat-list-item>
                        </mat-list>

                    </div>

                    <div class="example-container" fxFlex="40%">
                        <h2>Done</h2>
                        <mat-list cdkDropList #doneList="cdkDropList" [cdkDropListData]="done" [cdkDropListConnectedTo]="[otherItemsList]" class="example-list" style="width: max-content;"
                                  (cdkDropListDropped)="dropNew($event)">
                            <mat-list-item role="listitem" class="example-box" *ngFor="let item of done" cdkDrag
                                           (cdkDragStarted)="dragStarted($event)"
                                           (cdkDragEnded)="dragEnded($event)">
                                <mat-checkbox class="example-margin" (change)="onCheckboxChange($event,item)">
                                    {{item.Name}}
                                </mat-checkbox>
                                <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                                    {{this.selectedItem.length}}
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>

            </section>
        </mat-card-content>
    </mat-card>
</div>



