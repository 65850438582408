import { Component } from '@angular/core';

@Component({
  selector: 'app-form-eliment',
  templateUrl: './form-eliment.component.html',
  styleUrls: ['./form-eliment.component.css']
})
export class FormElimentComponent {
  // On file Select
  onChange(event:any) {
  }
}
