import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { max } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent {
  public hourValue = new FormControl('');
  public minuteValue = new FormControl('');
  public meridiemValue = 'AM';
  public ALL_NUMBER_REGEXP = /^[0-9]/;
  allowNumberOnly(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    let value = event?.currentTarget?.value ?? 0
    if (!this.ALL_NUMBER_REGEXP.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onInputChange(event: any, type: string = 'txt') {
    //
    if (type == "meridiem") {
      this.meridiemValue = this.meridiemValue == 'AM' ? 'PM' : 'AM'
    }
    else {
      let value = event.currentTarget.value
      if (!this.ALL_NUMBER_REGEXP.test(value)) {
        value = "";
      }
      if (value) {
        if (type == "hr") {
          value = parseInt(value);
          if (value > 12) {
            value = value % 12;
          }
        }
        else if (type == "min") {
          value = parseInt(value);
          if (value > 59) {
            value = value % 59;
          }
        }

      }
      event.currentTarget.value = value;
    }
  }

  setNextValue(event: any, type: string = 'txt', keyCode: number = 38) {
    let increement: number = 0;
    let maxValue: number = 12;
    let value: any = 12;
    if (type == "hr") {
      maxValue = 12;
      value = this.hourValue.value;
    }
    else if (type == "min") {
      maxValue = 59;
      value = this.minuteValue.value;
    }
    if (!value) {
      value = 0
    }
    value = parseInt(value);
    if (value == NaN) {
      value = 0;
    }
    if (event.type == "keydown") {
      if (event.keyCode == 38 || event.keyCode == 40) { 
        if (event.keyCode == 38) {
          increement = - 1;
        }
        else if (event.keyCode == 40) {
          increement = 1;
        }         
        //event.currentTarget.value = value;
        event.preventDefault();
        
      }
    }
    else if (event.type == "click") {
      if (keyCode == 38) {
        increement = - 1;
      }
      else if (keyCode == 40) {
        increement = 1;
      } 
    }
    value = this.getNextValue(value, maxValue, increement); 
    if (type == "hr") {
      this.hourValue.setValue(value);
    }
    else if (type == "min") { 
      this.minuteValue.setValue(value);
    }
  }
  getNextValue(value: number, maxValue: number, increement: number) {
    value = value + increement;
    if (value > maxValue) {
      value = 0;
    }
    else if (value == -1) {
      value = maxValue;
    }
    return value ?? 0;
  }

}
