
<div class="row align-items-start">
    <mat-drawer-container class="example-container" autosize style="padding:0px !important;">
        <mat-drawer #drawer class="example-sidenav queue-label-sidenav" mode="side" [opened]="true" [ngStyle]="{'width': '300px','max-height': '55vh'}">
            <div style="padding: 10px 0px 0px 10px;">
                <ng-container *ngFor="let node of nodes">
                    <div *ngIf="node.canDrag" class="drag-drawflow" [title]="node.infos.desc" draggable="true" (dragstart)="onDrawflowEvent($event)" [id]="node.id">

                        <!--  <img [src]="node.infos.icon" *ngIf="node.infos.icon" /> -->
                        {{ node.infos.name }}
                    </div>
                </ng-container>
            </div>
        </mat-drawer>

        <div class="example-sidenav-content">
            <div id="drawflow"
                 (dragenter)="onDrawflowEvent($event)"
                 (dragleave)="onDrawflowEvent($event)"
                 (dragover)="onDrawflowEvent($event)"
                 (dragend)="onDrawflowEvent($event)"
                 (drop)="onDrawflowEvent($event)"></div>

            <div *ngIf="showLock" class="btn-lock" [ngClass]="locked ? 'locked' : ''">
                <i (click)="changeMode()" class="fas fa-lock-open" [ngStyle]="{ display: locked ? 'none' : 'block' }"></i>
                <i (click)="changeMode()" class="fas fa-lock" [ngStyle]="{ display: locked ? 'block' : 'none' }"></i>
            </div>
            <div class="bar-zoom" *ngIf="showLock">
                <i (click)="onZoomOut()" class="fas fa-search-minus"></i>
                <i (click)="onZoomReset()" class="fas fa-search"></i>
                <i (click)="onZoomIn()" class="fas fa-search-plus"></i>
            </div>
        </div>

        <mat-drawer #drawer class="example-sidenav queue-label-sidenav" mode="side" [opened]="opensettingpane" [ngStyle]="{'width': '400px','max-height': '55vh'}" position="end">
            <div style="padding: 10px 0px 0px 10px;">
                <app-payroll-input *ngIf="opensettingpane" (onCancel)="doCancel($event)" [data]="nodeData"></app-payroll-input>
            </div>
        </mat-drawer>
    </mat-drawer-container>
    <div style="visibility: hidden; position: fixed"
         [style.left]="contextMenuPosition.x"
         [style.top]="contextMenuPosition.y"
         [matMenuTriggerFor]="contextMenu2" #menuFilterTrigger="matMenuTrigger">
    </div>
    <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onEdit(item)">Edit</button>
            <button mat-menu-item (click)="onDelete(item)">Delete</button>
        </ng-template>
    </mat-menu>
</div>


