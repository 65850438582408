import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { physicianperiod } from '../physician-period';

@Component({
  selector: 'app-physician-period-edit',
  templateUrl: './physician-period-edit.component.html',
  styleUrls: ['./physician-period-edit.component.css']
})
export class PhysicianPeriodEditComponent {
  public _physician!: physicianperiod;
  _lookupData: any = {};
  public selectedTab!: number;
  public hasActionMaster_View: boolean = false;


  ngOnInit() {
    //this.setPrivileges();
  }

  @Input() set physician(physician: physicianperiod) {
    this._physician = physician;
  }
  @Input() set lookupData(lookupData: any) {    
    this._lookupData = lookupData;
  }
  constructor(private auth: AuthService) { }
  get physician() {
    return this._physician;
  }

  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(action: physicianperiod) {
    this.onUpdateEdit.emit(action);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(action: physicianperiod) {
    this.onShowLoading.emit(action);
  }
  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }

  //setPrivileges() {
  //  this.hasActionMaster_View = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_View);
  //}
}
