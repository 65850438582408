import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Custom from '../../Utility/CustomValidators.utilities';
import { httpOptions } from '../../Utility/CustomValidators.utilities';

@Injectable({
  providedIn: 'root'
})
export class NetRevenueService {
   

  constructor(private http: HttpClient) { }
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'netrevenue';
  public async getNetRevenueList(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/list`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }
  async SetNetRevenueList(request: any): Promise<any> {
    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}`,
      JSON.stringify(request),
      httpOptions()).toPromise();
    return result;
  }

}
