import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { Client } from '../client-management/clientinfo';
import { PrivilegeValues } from '../../entities/Privilege';
import { MaturityCurveService } from './maturity-curve.service';
import { MaturityCurve } from './maturitycurveinfo';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog';
import { Confirmation } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-maturity-curve',
  templateUrl: './maturity-curve.component.html',
  styleUrls: ['./maturity-curve.component.css']
})
export class MaturityCurveComponent {
  clientList: Client[] = [];
  isLoading: boolean = true;
  hasMaturityCurve_View: boolean = true; // todo make it false
  hasMaturityCurve_Edit: boolean = true;
  hasMaturityCurve_Add: boolean = true;
  formData!: any[];
  showEdit: boolean = false;
  maturityCurveList!: MaturityCurve[];
  maturityCurve!: MaturityCurve[];
  constructor(
    private clientService: ClientService,
    private auth: AuthService,
    private maturityCurveService: MaturityCurveService,
    private dialog: MatDialog
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getClients();
    this.getMaturityCurve(-1, false);
  }
  getClients() {
    const request = {}
    this.clientService.getlookup([request]).then(data => {
      //this.clientList = data;

      this.clientList = data.map((s: any) => ({
        code: s.clientid ?? s.code,
        description: s.clientname ?? s.description, active: true,
        "warehouseuniqueidentifier": s.warehouseuniqueidentifier,
        "practiceid": s.practiceid
      }));
      console.log(this.clientList)
      this.isLoading = false;
    });
  }

  setPrivileges() {
    //this.hasMaturityCurve_View = this.auth.hasPrivilege(PrivilegeValues.MaturityCurve_View);
    //this.hasMaturityCurve_Edit = this.auth.hasPrivilege(PrivilegeValues.MaturityCurve_Edit);
    //this.hasMaturityCurve_Add = this.auth.hasPrivilege(PrivilegeValues.MaturityCurve_Add);
  }

  saveFormData(data: any[]) {
    this.formData = data;
    //saveMaturityCurve

    this.isLoading = true;
    this.maturityCurveService.saveMaturityCurve(this.formData[0]).then(async (data: any) => {
      this.isLoading = false;
      console.log("data: ", data);

      let confirmationData: ConfirmDialogData = {} as ConfirmDialogData;
      confirmationData.showNo = true;
      confirmationData.message = data[0]?.errormessage;
      confirmationData.title = 'Information!';
      confirmationData.showYes = false;
      confirmationData.noText = 'OK';
      await Confirmation(this.dialog, confirmationData);
      this.showEdit = false;
      this.getMaturityCurve(-1, false);
    });
  }

  onAddMaturityCurve() {
    this.maturityCurve = [];
    this.showEdit = true;
  }

  backToMaturityCurve() {
    this.showEdit = false;
  }

  getMaturityCurve(P_id: number, P_IsEdit: boolean) {
    this.isLoading = true;
    const request = {
      "pagesize": "100",
      "pagestartrecord": "0",
      "id": P_id,
      "practiceid": "-1"
    };
    console.log("call edit for: " + P_id);
    this.maturityCurveService.getMaturityCurve(request).then(data => {
      if (!P_IsEdit) {
        this.maturityCurveList = data;
      } else if (P_IsEdit) {
        this.maturityCurve = data;
        this.showEdit = true;
        console.log("this.maturityCurve: ", this.maturityCurve);
      }
      
      this.isLoading = false;
    });
  }

  onEditMaturityCurve(maturityCurve: MaturityCurve) {
    this.getMaturityCurve(maturityCurve.physicianmonthlypercentagerateid, true);
  }
}
