import { AfterViewInit, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import { TaskService } from '../task.service';
import { map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { getSafeDateReturnMoment, transformDate } from '../../../Utility/common-functions';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as custom from '../../../Utility/CustomValidators.utilities'
import * as moment from 'moment';

@Component({
  selector: 'app-task-action',
  templateUrl: './task-action.component.html',
  styleUrls: ['./task-action.component.css']
})
export class TaskActionComponent implements AfterViewInit {
  todayDate: Date = new Date();
  public dropdownList: any[] = [];
  public selectedItems: any[] = [];
  public dropdownSettings: any = {
    singleSelection: false,
    idField: 'item_text',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 300,
    allowSearchFilter: true,
    closeDropDownOnSelection: false
  };
  public _users: LookupEntityBase[] = [];
  public Owner!: LookupEntityBase | undefined;
  @ViewChild('auto') _auto!: MatAutocomplete;

  public _isFromInfo: boolean = false
  public isError: boolean = false

  @Input() set users(users: any[]) {
    this._users = users;
    setTimeout(() => {
      this.setAssignee();
    }, 1000);

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.setAssignee();
    }, 1000);
  }
  setAssignee() {
    let Owner = this._auto?.options.toArray().find(w => w.value?.code == this.task?.CurrentAssigneeName || w.value?.description == this.task?.CurrentAssigneeName);
    this.assigneeName.setValue(Owner?.value);
  }
  get users() {
    return this._users;
  }
  @Input() set tags(tags: any[]) {
    if (tags && tags.length > 0) {
      this.dropdownList = tags.sort((a: any, b: any) => {
        return custom.compare(a.item_text?.toLocaleLowerCase() as string, b.item_text.toLocaleLowerCase() as string, true);
      });
    }
    else {
      this.dropdownList = [
        { item_text: 'Over Balance' },
        { item_text: 'Under Review' },
      ]
    }

  }

  @Input() set isFromInfo(isFromInfo: boolean) {
    this._isFromInfo = isFromInfo;
  }
  get isFromInfo() {
    return this._isFromInfo;
  }
  public _actions: LookupEntityBase[] = [];
  @Input() set actions(actions: LookupEntityBase[]) {
    this._actions = actions;
  }
  get actions() {
    return this._actions;
  }
  public tage_list: string[] = [];
  public tages: string[] = [];
  public activities: any[] = [];

  public _tasks: any[] = [];
  public task: any;
  @Input() set tasks(tasks: any[]) {
    this._tasks = tasks;
    if (this.tasks.length == 1) {
      this.task = tasks[0];
      this.task.followupdate = (this.task.followupdate) ? transformDate(this.task.followupdate, 'MM-DD-YYYY', true) : '';
      this.selectedItems = this.task.Tags?.find((w: any) => w.Username == this.auth.currentUser.username)?.UserTags.map((w: any) => { return { "item_text": w } }) ?? []
      this.lastAction.setValue(this.task?.CurrentAction);
    }
  }
  get tasks() {
    return this._tasks;
  }
  public waiting: boolean = false;
  filteredOptions: LookupEntityBase[] = [];
  assigneeName = new FormControl('');
  lastAction = new FormControl('');
  formAdd: FormGroup = new FormGroup({
    action: new FormControl(''),
    nextActionDate: new FormControl('', [custom.validateDate]),
    tages: new FormControl(''),
    assigneeName: this.assigneeName,
    notes: new FormControl('')
  });
  constructor(private taskService: TaskService,
    private dialog: MatDialog, private auth: AuthService) {
    this.dropdownList = [
      { item_text: 'Over Balance' },
      { item_text: 'Under Review' },
      { item_text: 'Atna' },
    ];

    this.assigneeName.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe();

  }


  onItemSelect(item: any) {
    this.dropdownList.map(w => {
      if (w.item_text == item.item_text && w.isCustom)
        w.isCustom = false;
      return w
    })
  }
  onFilterChange(item: any) {
    //if (this.dropdownList.findIndex(s => s.item_text == item) < 0) {

    this.dropdownList = this.dropdownList.filter(w => !w.isCustom);
    if (item != '')
      this.dropdownList.push({ item_text: item, isCustom: true })
    //}
  }
  onSelectAll(items: any) {

  }
  displayFn(user: LookupEntityBase) {
    return user && user.description ? user.description : '';
  }
  private _filter(value: string): LookupEntityBase[] {
    const filterValue = value ?? ''.toLowerCase();

    this.filteredOptions = this.users?.filter(user => { if (!user.description) return false; else return user.description.toLowerCase().includes(filterValue) });
    return this.filteredOptions;
  }
  async onSaveAction() {
    
    if (this.canSave()) {
      if (this.formAdd.valid) {
        
        let request = this.tasks.map(task => {
          return {
            id: task._id,
            action: this.formAdd.controls.action.value,
            nextActionDate: this.formAdd.controls.nextActionDate.value,
            notes: this.formAdd.controls.notes.value,
            tages: this.selectedItems.map(w => { return w.item_text }).join(','),
            assigneeName: this.formAdd.controls.assigneeName.value?.code ?? '',
            assigneeId: this.formAdd.controls.assigneeName.value?.value ?? '-1',
            currentAssigneeName: task.CurrentAssigneeName ?? ''
          }
        });
        if (request[0].action != '' || request[0].nextActionDate != '' || request[0].notes != '' || request[0].tages != '' || request[0].assigneeName != '' || request[0].currentAssigneeName != '') {
          this.waiting = true;
          this.formAdd.markAsUntouched();
          let res = await this.taskService.setActions(request);
          this.waiting = false;
          let data: ConfirmDialogData = {} as ConfirmDialogData;
          data.message = res.message;
          data.title = 'Information!';
          data.showNo = true;
          data.showYes = false;
          data.noText = 'Ok';
          data.yesText = 'YES';
          await Confirmation(this.dialog, data);
          this.doCancelEdit(true);
        }
        else {
          this.isError = true;        
        }
      }
      else {
        this.formAdd.markAsTouched();
      }
    }
    else {
      this.isError = true;
    }
  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit(item: boolean) {
    this.doReset()
    this.onCancelEdit.emit(item);
  }
  doReset() {
    this.formAdd.controls.notes.setValue("");
    this.formAdd.controls.action.setValue("");
    this.formAdd.controls.nextActionDate.setValue("");
    if (this.tasks.length == 1) {
      this.setAssignee();
    }
    else
      this.formAdd.controls.assigneeName.setValue("");
  }
  public hasTask_Action_Add: boolean = false;
  get hasAction_Add() {
    if (this.hasTask_Action_Add != this.auth.hasPrivilege(PrivilegeValues.Task_Action_Add)) {
      this.hasTask_Action_Add = this.auth.hasPrivilege(PrivilegeValues.Task_Action_Add);
      this.updateForm();
    }
    return this.hasTask_Action_Add;
  }
  get hasTask_Notes_Add() {
    return this.auth.hasPrivilege(PrivilegeValues.Task_Notes_Add);
  }
  updateForm() {
    //if (this.hasTask_Action_Add) {
    //  this.formAdd.controls.action = new FormControl('',[Validators.required]);
    //  this.formAdd.controls.nextActionDate = new FormControl('', [Validators.required]);
    //}
    //else {
    //  this.formAdd.controls.action = new FormControl('');
    //  this.formAdd.controls.nextActionDate = new FormControl('');
    //}
  }
  canSave() {
    
    let result = false;

    if (this.selectedItems.length > 0 && this.task) {
      let Items = this.task.Tags?.find((w: any) => w.Username == this.auth.currentUser.username)?.UserTags.map((w: any) => { return { "item_text": w } }) ?? []
      let missing = Items.filter((item: any) => this.selectedItems.findIndex(w => w.item_text == item.item_text) < 0);
      let missing1 = this.selectedItems.filter((item: any) => Items.findIndex((w: any) => w.item_text == item.item_text) < 0);

      if (missing.length > 0 || missing1.length > 0)
        return true;
    }
    for (var key of Object.keys(this.formAdd.controls)) {
      console.log(key);
      if (key == "assigneeName" && this.tasks.length == 1) {
        if (this.formAdd.controls[key].value?.toString().trim() != "" && this.task.CurrentAssigneeName != this.formAdd.controls.assigneeName.value?.code)
          return true;
      }
      else if (this.formAdd.controls[key].value?.toString().trim() != "") {
        return true;
      }
    }
    return result;
  }
  public onBlur(dp: any) {
    
    var date = moment(this.formAdd.controls.nextActionDate.value);
    const status = date.isValid();
    if (!status) {
      this.formAdd.controls.nextActionDate.setValue("");
    }
    //this.currentValue = +dp.input.currentValue.split('/')[0];
  }
}
