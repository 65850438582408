<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title>
                Form <button mat-mini-fab color="primary" style="float:right">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card-title>
            <mat-card-subtitle> Home &gt; Form</mat-card-subtitle>
            <mat-divider></mat-divider>
            <section class="table-container" tabindex="0" style="height:73vh;overflow:auto;padding:10px; margin:auto">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name">
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Middle Name</mat-label>
                        <input matInput placeholder="Middle Name">
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email@Email.com">
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Phone">
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Password</mat-label>
                        <input matInput type="password" placeholder="Password" autocomplete="off">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" style="width:70%;float:left;" placeholder="MM/dd/yyyy">
                        <mat-datepicker-toggle matIconSuffix [for]="picker" style="float: right;margin-top: -15px;">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>File</mat-label>
                        <input matInput style="width:70%;float:left;" readonly (change)="onChange($event)">
                        <button mat-icon-button matPrefix (click)="f_input.click()" style="float: right;margin-top: -15px;">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <input type="file" hidden #f_input (change)="onChange($event)" />
                    </mat-form-field>
                    <div fxFlex="0 0 25em">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1">Option 1</mat-radio-button>
                            <mat-radio-button value="2">Option 2</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
                    <section class="example-section" fxFlex="0 0 25em">
                        <mat-checkbox class="example-margin">Check me!</mat-checkbox>
                    </section>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>State</mat-label>
                        <mat-select>

                            <mat-option [value]="1">Test</mat-option>
                            <mat-option [value]="12">Test1</mat-option>
                            <mat-option [value]="13">Test2</mat-option>
                            <mat-option [value]="14">Test3</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="0 0 25em" [floatLabel]="'always'"
                                    [appearance]="'standard'" required>
                        <mat-label>Test Multi</mat-label>
                        <mat-select multiple>
                            <mat-option [value]="1">Test</mat-option>
                            <mat-option [value]="12">Test1</mat-option>
                            <mat-option [value]="13">Test2</mat-option>
                            <mat-option [value]="14">Test3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
                    <mat-form-field class="textarea-mat-field">
                        <mat-label>Text Content</mat-label>
                        <textarea matInput
                                  placeholder="Enter document text..."
                                  #newTextContent
                                  style="height:50px"></textarea>
                    </mat-form-field>
                </div>
            </section>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
    </mat-card>
</div>
