<section class="table-container" tabindex="0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 box-shadow-none">
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>
        <ng-container matColumnDef="tableName">
            <th mat-header-cell *matHeaderCellDef>Table</th>
            <td mat-cell *matCellDef="let element">
                {{element.tableName}}
                <!-- <input matInput placeholder="Name" [disabled]="true" autocomplete="off" [(value)]="element.tableName"> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
                <!-- <input matInput placeholder="Name" [disabled]="true" autocomplete="off" [(value)]="element.name"> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="sourceTable">
            <th mat-header-cell *matHeaderCellDef>Source Table</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="width:320px !important">
                    <mat-select required [(value)]="element.srcTableName">
                        <mat-option *ngFor="let type of tables" [value]="type">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <div class="task-title">{{element.condition}}</div> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="sourceColumn">
            <th mat-header-cell *matHeaderCellDef>Source Column </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field style="width:320px !important">
                    <mat-select required [(value)]="element.srcColumnName">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let column of selectedColumn" [value]="column.columnId">{{column.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="mat-condition-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="block-Row"></tr>

    </table>
    <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onContextAddCondition(item)">Add Condition</button>
        </ng-template>
    </mat-menu>
</section>
