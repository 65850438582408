import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { lookup } from 'dns';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { ClientService } from '../../../services/client.service';
import { MultiSelectComponent } from '../../../shared/multi-select/multi-select.component';
import { getConverted12HourTime } from '../../../Utility/common-functions';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { physicianperiod } from '../physician-period';
import { PhysicianPeriodService } from '../physician-period.service';


@Component({
  selector: 'app-physician-period-details',
  templateUrl: './physician-period-details.component.html',
  styleUrls: ['./physician-period-details.component.css']
})
export class PhysicianPeriodDetailsComponent {  
  public _physician!: physicianperiod;
  public _isLoading: boolean = false;
  public isEdit: boolean = false;
  public hasActionMaster_Edit: boolean = false;
  public _isFromInfo: boolean = false;
  _lookupData: any = {};
  public selectedClients: any[] = [];
  public selectedProviders: any[] = [];
  public _orgLookupData: any;
  public _filters: any = { practiceIds: [], owners: [], actions: [] };

  @ViewChild('clients') _client!: MultiSelectComponent;
  @ViewChild('serviceProvider') _serviceProvider!: MultiSelectComponent;

  formAdd: FormGroup = new FormGroup({
    blackoutstarttime: new FormControl('', [Validators.required]),
    blackoutendtime: new FormControl('', [Validators.required]),
    startdate: new FormControl('', [Validators.required]),
    enddate: new FormControl('', [Validators.required]),    
    isActive: new FormControl(true),
  });
  actionmasterService: any;

  @Input() set physician(physician: physicianperiod) {    
    if (physician.physicianblackoutperiodid != "- 1") {
      this.isEdit = true;
      console.log(this.isEdit)
    }
    else {
      this.isEdit = false
    }
    this._physician = physician;
    console.log(this._physician)
    if (physician?.physicianblackoutperiodid)
      this.getActionDetails();
  }

  @Input() set lookupData(lookupData: any) {    
    this._lookupData = lookupData;
    this._orgLookupData = lookupData;
    if (this._physician?.physicianblackoutperiodid)
      this.getActionDetails();
  }

  get action() {
    return this._physician;
  }

  ngOnInit(): void {
    //this.setPrivileges();
     
  }
  @Output() onUpdateEdit = new EventEmitter();
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    this.onCancelEdit.emit(false);
  }
  constructor(private dialog: MatDialog, private physicianperiodservice: PhysicianPeriodService, private auth: AuthService, private clientService: ClientService) { }
  @Output() doNextEdit = new EventEmitter();

  async doSave(type: string) {
    //debugger;
    
    if (this.formAdd.valid) {
      
      let selectitems: any = this.filtervalue();
      if (selectitems.practiceIds.length > 0) {
        let physiciaNew: any = {
          physicianblackoutperiodid: this.action?.physicianblackoutperiodid ?? -1,
          isactive: (this.action?.physicianblackoutperiodid ?? "-1") == "-1" ? "1" : this.formAdd.controls.isActive.value ? 1 : 0,
          uniquepracticeid: selectitems.practiceIds[0]?.code ?? '',
          uniqueproviderid: selectitems.serviceProviderIds[0].code ?? '',
          blackoutstarttime: this.formAdd.controls.blackoutstarttime.value ?? '',
          blackoutendtime: this.formAdd.controls.blackoutendtime.value ?? '',
          blackoutstartdate: moment(this.formAdd.controls.startdate.value).format('MM-DD-YYYY') ?? '',
          blackoutenddate: moment(this.formAdd.controls.enddate.value).format('MM-DD-YYYY') ?? '',
          loginuserid: this.auth.currentUser.username
        };

        //debugger;

        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.title = 'Information!';
        data.showYes = true;
        data.noText = 'NO';
        data.yesText = 'YES';
        let update = true;
        if (physiciaNew.isactive == 0 && this.action.isactive != physiciaNew.isactive) {
          data.message = 'Are you sure you want to in active this action?';
          update = await Confirmation(this.dialog, data);
        }

        if (update) {
          this.onShowLoading.emit(true);
          this.physicianperiodservice.setgetPhysicianPeriod([physiciaNew]).then(res => {
            if (res.length > 0) {
              //debugger;
              this.physician = res as physicianperiod;
              data.title = 'Information!';
              data.showYes = false;
              data.noText = 'OK';
              data.message = res[0].errormessage;
              //await Confirmation(this.dialog, data);
              Confirmation(this.dialog, data);
              //this.onUpdateEdit.emit();
              if (res.result != 0) {
                this.onCancelEdit.emit(true);
              }
              this._isLoading = false;
              this.onShowLoading.emit(false);

            }
          });
        }
        else {
          this.formAdd.markAllAsTouched();
          return;
        }
      }
    }
  }

  getPractices() {

    this.clientService.getlookup([]).then(data => {
      
    });

  }



  getActionDetails() {
    //debugger;
    let P_start = new Date(this._physician.blackoutstarttime);
    let StartDate = moment(P_start).format('MM-DD-YYYY');
    let P_starttime = getConverted12HourTime(P_start.getHours().toString(),
      P_start.getMinutes().toString(),
      true);
    let P_end = new Date(this._physician.blackoutendtime);
    let EndDate = moment(P_end).format('MM-DD-YYYY');
    let P_endtime = getConverted12HourTime(P_end.getHours().toString(),
      P_start.getMinutes().toString(),
      true);
    console.log(this._physician)
    this.formAdd.controls.blackoutstarttime.setValue(P_starttime);
    this.formAdd.controls.blackoutendtime.setValue(P_endtime);
    this.formAdd.controls.isActive.setValue(this._physician.isactive);

    this.formAdd.controls.startdate.setValue(this._physician.blackoutstarttime);
    this.formAdd.controls.enddate.setValue(this._physician.blackoutendtime);
        

    this.selectedClients = this._lookupData.clients?.filter((w: any) => { return w.code == this._physician.uniquepracticeid });
    this.onSelectionChange(this.selectedClients);
    this.selectedProviders = this._lookupData.serviceProvider?.filter((w: any) => { return w.code == this._physician.uniqueproviderid });

  }
  @Output() onShowLoading = new EventEmitter();

  filtervalue() {
    
    return {
      practiceIds: (this._client) ? this._client.selectedItems : [],            
      serviceProviderIds: (this._serviceProvider) ? this._serviceProvider.selectedItems : [],      
    };
  }
  get filters() {
    return this._filters;
  }

  onSelectionChange(item: any) {
    let selectedItems = (this._physician && item) ? item[0]?.code : '';
    if (item.length > 0) {      
      this._lookupData.serviceProviderFiltered = this._orgLookupData.serviceProvider.filter((w: any) => { return selectedItems == w.clientid })      
    }
    //else {
    //  this._lookupData.serviceLocationFiltered = this._orgLookupData.serviceLocation
    //  this._lookupData.serviceProviderFiltered = this._orgLookupData.serviceProvider
    //  this._lookupData.payorGroupsFiltered = this._orgLookupData.payorGroups
    //  this._lookupData.payorsFiltered = this._orgLookupData.payors

    //}
  }



  //setPrivileges() {
  //  this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
  //}
}
