import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent {
  public _dropdownList: any[] = [];
  public _selectedItems: any[] = [];
  public formControl: FormControl = new FormControl('')
  public _dropdownSettings: any = {
    singleSelection: false,
    addNewOption: false,
    idField: 'code',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'Select All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    closeDropDownOnSelection: false,
    placeholder: "Tags",
    disabled:false
  };

  @Output() onSelectionChange = new EventEmitter();
  @Input() set dropdownList(list: any[]) {
    this._dropdownList = list;
  }
  get dropdownList() {
    return this._dropdownList;
  }
  @Input() set selectedItems(list: any[]) {
    this._selectedItems = list;
  }
  @Input() set selectedValues(values: string) {
    values = (values) ? values.toString() : "";
    this._selectedItems = (this._dropdownList??[]).filter(w => {
      return values.split(',').indexOf(w.code?.toString()??"") > -1
    });

  }
  @Input() set singleSelection(singleSelection: boolean) {
    this._dropdownSettings.singleSelection = singleSelection;
  }
  @Input() set disbaleSelection(disabled: boolean) {
    this._dropdownSettings.disabled = disabled;
    if (disabled) {
      this._selectedItems = [];
    }
  }
  @Input() set allowSearchFilter(allowSearchFilter: boolean) {
    this._dropdownSettings.allowSearchFilter = allowSearchFilter;
  }
  @Input() set addNewOption(addNewOption: boolean) {
    this._dropdownSettings.addNewOption = addNewOption;
  }

  get selectedItems() {
    return this._selectedItems;
  }
  @Input() set dropdownSettings(list: any) {
    this._dropdownSettings = list;
  }
  get dropdownSettings() {
    return this._dropdownSettings;
  }
  onItemSelect(item: any) {
    console.log(this._selectedItems);
    this.onSelectionChange.emit(this._selectedItems);

    this.dropdownList = this.dropdownList.map(w => {
      if (w.code == item.code && w.isCustom) {
        w.isCustom = false;
        w.description = w.item;
        w.code = w.item;
      }
      return w
    });
    this._selectedItems = this._selectedItems.map(w => {
      if (w.code == 'addnew') {
        w.item = w.description.split('(')[1].toString().replace(")", "");
        w.description = w.item;
        w.code = w.item;
      }
      return w
    })
  }
  onFilterChange(item: any) {
    //this.onSelectionChange.emit(this._selectedItems);
    if (this.dropdownSettings.addNewOption) {
      this.dropdownList = this.dropdownList.filter(w => !w.isCustom);
      if (item != '')
        this.dropdownList.push({ code: 'addnew', description: `Add New (${item})`, isCustom: true, item: item })
    }
  }
  onSelectAll(items: any) {
    this.onSelectionChange.emit(this._selectedItems);
  }
  @Input() set placeholder(placeholder: string) {
    this._dropdownSettings.placeholder = placeholder;
  }
}
