<div class="page-body-ineer">
    <ng-container *ngIf="isEmbedded">
        <ng-container *ngIf="displayFilters()">
            <button mat-mini-fab color="primary" style="float:right;right: 135px;" class="filter-report" (click)="showFilter = !showFilter;" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                <mat-icon>filter_list</mat-icon>
            </button>
            <ng-template cdkConnectedOverlay
                         [cdkConnectedOverlayOrigin]="trigger"
                         [cdkConnectedOverlayOpen]="showFilter"
                         [cdkConnectedOverlayHasBackdrop]="true"
                         (detach)="showFilter = false"
                         (backdropClick)="showFilter = false">
                <div class="filter-pane" style="width: 450px; padding: 10px;">
                    <app-report-filter [lookupData]="lookupData" (onFilter)="doFilter($event)" [filters]="filters" [filterData]="filterData" [reportid]="reportId"></app-report-filter>
                </div>
            </ng-template>
        </ng-container>
        <ng-container>
            <button mat-mini-fab color="primary" style="float:right;" class="filter-report" (click)="onSaveRport()" cdkOverlayOrigin #trigger1="cdkOverlayOrigin">
                <mat-icon>comment</mat-icon>
            </button>
            <ng-template cdkConnectedOverlay
                         [cdkConnectedOverlayOrigin]="trigger1"
                         [cdkConnectedOverlayOpen]="showComments"
                         [cdkConnectedOverlayHasBackdrop]="true"
                         (detach)="showComments = false"
                         (backdropClick)="showComments = false">
                <div class="filter-pane" style="width: 450px; padding: 10px;">
                    <div style="height: 50vh;overflow: auto;" (scroll)="doScroll($event)" #scrollMe>
                        <mat-progress-bar *ngIf="_isLoadingMore" mode="indeterminate"></mat-progress-bar>
                        <app-report-comments [comments]="comments" (onOpenImage)="onOpenImage($event)"></app-report-comments>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="col-md-12 comment-box">
                        <small id="name" class="form-text text-muted" style="width:100%">
                            Comment:
                            <mat-icon style="height: 18px; margin-right: 5px;cursor: pointer;" (click)="f_input.click()">attachment</mat-icon>
                            <a *ngIf="fileName != ''" style="margin-right: 5px;cursor: pointer;" (click)="onOpenImage('')">{{fileName}}</a>
                            <mat-icon *ngIf="fileName != ''" style="margin-right: 5px;cursor: pointer; height: 18px;font-size: 14px;" (click)="removeImage()">remove_circle</mat-icon>
                            <input #f_input type="file" id="upload" class="account-file-input" hidden="" accept="image/png, image/jpeg" (change)="onChange($event)">
                        </small>
                        <div class="form-group">
                            <textarea id="txtarea_notes" rows="2" style="width: 100%; height: 102px;resize: none;" placeholder="Please enter note..." [formControl]="notes"></textarea>
                        </div>
                        <mat-error *ngIf="notes?.value=='' && notes?.touched">
                            Please enter comment Or select image
                        </mat-error>
                    </div>
                    <div class="button-group">
                        <!--<button mat-flat-button color="primary" style="margin-right:10px;" [disabled]="waiting || !canSave()" class="btn" (click)="onSaveAction()">Save</button>-->
                        <button mat-flat-button color="primary" style="margin-right:10px;" class="btn" (click)="onSaveComment()">Save</button>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <button mat-mini-fab color="primary" style="float:right" class="print-report " (click)="printReport()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap" #powerbiControl>
        </powerbi-report>
    </ng-container>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
