import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { LookupEntityBase } from '../../../entities/lookup-entity';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.css']
})
export class TaskFilterComponent {
  public _lookupData: any;
  public _filters: any = { practiceIds: [], owners: [], actions: [], searchOn: [{ code: 'encounternumber', description: 'Encounter#', active: true }] };
  //public practiceIds: any[] = [];
  //public owners: any[] = [];
  //public selectedActions: any[] = []
  public actions: LookupEntityBase[] = [];
  public searchOn: LookupEntityBase[] = [{ code: 'encounternumber', description: 'Encounter#', active: true, value: "" }, { code: 'patient_accountnumber', description: 'Patient Acct#', active: true, value: "" }, { code: 'PatientName', description: 'Patient Name', active: true, value: "" }];
  public searchOperator: any[] = [{ "name": "in", "code": "in", "description": "in" }, { "name": "notIn", "code": "notIn", "description": "not in" }, { "name": "contains", "code": "contains", "description": "contains" }, { "name": "doesNotContain", "code": "doesNotContain", "description": "does not contain" }];
  //{ "name": "beginsWith", "code": "beginsWith", "description": "begins with" }, { "name": "doesNotBeginWith", "code": "doesNotBeginWith", "description": "does not begin with" }, { "name": "endsWith", "code": "endsWith", "description": "ends with" }, { "name": "doesNotEndWith", "code": "doesNotEndWith", "description": "does not end with" }, { "code": "null", "description": "is Null" }, { "code": "Notnull", "description": "is not null" }
  public users: LookupEntityBase[] = [];
  public clientLookup!: LookupEntityBase[];
  @ViewChild('owner') _owner!: MultiSelectComponent;
  @ViewChild('action') _action!: MultiSelectComponent;
  @ViewChild('clients') _client!: MultiSelectComponent;
  @ViewChild('ctrlsearchOn') _searchOn!: MultiSelectComponent;
  @ViewChild('ctrlsearchOperator') _searchOperator!: MultiSelectComponent;
  public description = new FormControl('', [Validators.required]);
  public freezedControl = new FormControl("0");
  @Input() set lookupData(lookupData: any) {
    this._lookupData = lookupData;
    this.clientLookup = lookupData.clients;
    this.actions = lookupData?.actions;
    this.users = lookupData?.users;
  }
  get lookupData() {
    return this._lookupData;
  }
  @Input() set filters(filters: any) {
    this._filters = filters;
    this.description.setValue(filters.otherfilters[0]?.values); 
    this.freezedControl.setValue(filters.freezed);
  }
  get filters() {
    return this._filters;
  }
  public isVaildFilter: boolean = true;
  isValid(): boolean {
    this.isVaildFilter = true
    if ((this._searchOn.selectedItems?.length ?? 0) > 0) {

      if ((this._searchOperator.selectedItems?.length ?? 0) == 0) {
        this.isVaildFilter = false;
      }
      else if ((this._searchOperator.selectedItems as any[])[0]?.code != "null" && (this._searchOperator.selectedItems as any[])[0]?.code != "Notnull") {
        if (this.description.value == "") {
          this.isVaildFilter = false;
        }
      }
    }
    return this.isVaildFilter;
  }
  doFilter() {

    if (this.isValid()) {

      this._filters = {
        practiceIds: this._client.selectedItems,
        owners: this._owner.selectedItems,
        actions: this._action.selectedItems,
        otherfilters: [],
        freezed: this.freezedControl.value
      };
      if ((this._searchOperator.selectedItems?.length ?? 0) > 0) {
        this._filters.otherfilters = [{
          searchOn: this._searchOn.selectedItems ?? [],//?.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
          operator: this._searchOperator.selectedItems ?? [],//.map((w: any) => { return w.code }).filter((w: any) => w).join(',') ?? "",
          values: this.description.value
        }]
      }
      console.log(this._filters);
      this.onFilter.emit(this._filters);
    }
  };
  @Output() onFilter = new EventEmitter();

  doCancelFilter() {
    this._filters = {
      practiceIds: [], owners: [], actions: [],
      otherfilters: [],
      freezed:0
    };
    //this.practiceIds = [];
    //this.owners = [];
    //this.selectedActions = []
    this.onFilter.emit(this._filters);
    return this._filters;
  }
  onValChange(item: any) { 

  }
}
