<div class="button-group">
  <button mat-flat-button color="primary" class="btn float-end" (click)="doCancelEdit(null)" (onShowLoading)="doShowLoading($event)" style="width: 100px; margin-left: -100px; z-index: 20000; margin-top: 10px; margin-right: 10px;">
    <mat-icon>keyboard_arrow_left</mat-icon>
    Back
  </button>
</div>
<mat-tab-group class="mat-table-edit-box" [(selectedIndex)]="selectedTab">
  <mat-tab label="Physician Period">
    <app-physician-period-details [physician]="physician" [lookupData]="_lookupData" (onCancelEdit)="doCancelEdit($event)" (onShowLoading)="doShowLoading($event)" (onUpdateEdit)="doUpdateEdit($event)"></app-physician-period-details>
  </mat-tab>  
</mat-tab-group>
