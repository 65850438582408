<section class="table-container-inner" tabindex="0">
    <table sstyle="box-shadow:0px" *ngIf="false" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="first">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row"> </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell width="40%" *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let row"> {{(row.isGroup)?row.practicename?.toLocaleLowerCase():''}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell width="55%" *matHeaderCellDef> physician </th>
            <td mat-cell *matCellDef="let row"> {{(row.isGroup)?'':row.name?.toLocaleLowerCase()}} </td>
        </ng-container>
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="example-margin" [checked]="assignAll" (change)="onCheckboxChange($event,null,'all')"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="example-margin" [checked]="element.assigned==1" (change)="onCheckboxChange($event,element,'all')"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="collapse">
            <th mat-header-cell *matHeaderCellDef>

            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.isGroup">
                    <mat-icon (click)="element.expanded=!element.expanded" class="float-end">{{ element.expanded?'expand_less':'expand_more'}}</mat-icon>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'hidden-mat-row':!visible(row)}"></tr>
        <ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;"></tr>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{''}}"</td>
        </tr>
    </table>

    <div>
        <div fxLayout="column" fxLayoutGap="1em">
            <ng-container *ngFor="let item of practice;index as i">
                <div fxLayout="column" fxLayoutGap=".7em">
                    <div fxLayout="row" fxLayoutGap="1em">
                        <div fxFlex="5%"> <mat-icon (click)="item.expanded=!item.expanded" class="float-end">{{ item.expanded?'expand_less':'expand_more'}}</mat-icon></div>
                        <div fxFlex="2%"> <mat-checkbox class="example-margin" [checked]="item.assigned==1" (change)="onCheckboxChange($event,item,'all')"></mat-checkbox></div>
                        <div fxFlex="90%"> {{item.practicename?.toLocaleLowerCase()}}</div>
                    </div>
                    <div *ngIf="item.expanded" fxLayout="column" fxLayoutGap=".5em">
                        <ng-container *ngFor="let subitem of physicianList(item);index as i">
                            <div fxLayout="row" fxLayoutGap="1.5em">
                                <div fxFlex="7%"> </div>
                                <div fxFlex="2%"> <mat-checkbox class="example-margin" [checked]="subitem.assigned==1" (change)="onCheckboxChange($event,subitem,'all')"></mat-checkbox></div>
                                <div fxFlex="90%"> {{subitem.name?.toLocaleLowerCase()}}</div>
                            </div>
                        </ng-container>

                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</section>
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="margin-top:10px">
    <button mat-flat-button (click)="doSave()" color="primary" *ngIf="!profileEdit && hasUserGroup_Edit">Save Changes</button>
    <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
</div>

<app-data-loader [isLoading]="_isLoading"></app-data-loader>
