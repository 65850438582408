<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <!---->
            <full-calendar *ngIf="P_showCalendar" #calendarRef [options]="calendarOptions"></full-calendar>
        </mat-card-content>
    </mat-card>
</div>

<div id="contextMenu" *ngIf="contextMenuVisible" [ngStyle]="contextMenuPosition" class="context-menu">
    <ul>
        <li (click)="onAdhocRun($event)" class="cursor-pointer">Adhoc Re-run</li>
        <li (click)="onForceQuite($event)" class="cursor-pointer">Force Quit</li>
    </ul>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu2" #menuFilterTrigger="matMenuTrigger">
</div>
<mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngIf="item.event_status_id!=1 && item.event_status_id!=2" (click)="onAdhocRun(item)">Adhoc Re-run</button>
        <button mat-menu-item *ngIf="item.event_status_id==1" (click)="onForceQuite(item)">Force Quit</button>
        <button mat-menu-item *ngIf="_scheduleType==4 && item.event_status_id==2" (click)="onFreeze(item)">Freeze</button>
        <button mat-menu-item *ngIf="_scheduleType==4 && item.event_status_id==2" (click)="onPreview(item)">Preview</button>
        <button mat-menu-item *ngIf="item.event_status_id == 0" (click)="onDelete(item)">Delete</button>
    </ng-template>
</mat-menu>

<app-data-loader [isLoading]="isLoading"></app-data-loader>
