import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupEntityBase } from '../../../entities/lookup-entity';

@Component({
  selector: 'app-dialog-mapping',
  templateUrl: './dialog-mapping.component.html',
  styleUrls: ['./dialog-mapping.component.css']
})
export class DialogMappingComponent {
  
  public templatelist: LookupEntityBase[] = [];
  public clientlist: LookupEntityBase[] = [];
  public cptTypes: LookupEntityBase[] = [];
  public columnHeaderlist !: any[];
  public providerlist: LookupEntityBase[] = [];

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMappingComponent>) {    
    this.templatelist = data.templates;
    this.clientlist = data.clients;
    this.cptTypes = data.cptTypes;
    this.providerlist = data.providers;
    this.columnHeaderlist = data.columnHeader;
  }
  onCancelEdit(item: any) {
    this.dialogRef.close(item);
  }

}
