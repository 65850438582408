<div mat-dialog-title (click)="$event.stopPropagation();" *ngIf="_data.length>5">
    <input matInput style="margin-left: 10px;margin-top: 10px;width: 80%;border: .5px solid #adaeaf !important;" [(ngModel)]="filterValue" (keyup)="valuechange($event)" type="text" class="form-control" placeholder="search" />
</div>
<div class="mat-typography" style="max-height: 200px; min-height: 200px; overflow:auto" (click)="$event.stopPropagation();">
    <mat-list #filteredlist role="list">
        <mat-list-item role="listitem">
            <mat-checkbox class="example-margin" [checked]="this.selectAll" (change)="OptionSelect($event,{})">Select All</mat-checkbox>

        </mat-list-item>
        <mat-list-item *ngFor="let data of filteredRecords" role="listitem">
            <mat-checkbox class="example-margin" [checked]="data.selected" (change)="OptionSelect($event,data)">{{data.description}}</mat-checkbox>

        </mat-list-item>

    </mat-list>
</div>
<div align="end" (click)="$event.stopPropagation();">
    <button mat-button (click)="doNo()">Cancel</button>
    <button mat-button (click)="doYes()">Search</button>
</div>
