<input type="text" placeholder="Pick one" matInput [formControl]="myControl" [matAutocomplete]="auto">
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="doSelectionChange($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.code}} - {{option.description}}
    </mat-option>
</mat-autocomplete>
<!-- <div *ngIf="myControl.value">
    {{myControl.value?.code}} - {{myControl.value?.description}}
</div>
 -->
