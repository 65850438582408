import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { action } from '../action-master';

@Component({
  selector: 'app-action-master-edit',
  templateUrl: './action-master-edit.component.html',
  styleUrls: ['./action-master-edit.component.css']
})
export class ActionMasterEditComponent {
  public _action!: action;
  public selectedTab!: number;
  public hasActionMaster_View: boolean = false;


  ngOnInit() {
    this.setPrivileges();
  }

  @Input() set action(action: action) {    
    this._action = action;
  }
  constructor(private auth: AuthService) { }
  get action() {
    return this._action;
  }

  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit(item: any) {
    this.onCancelEdit.emit(item);
  }
  @Output() onUpdateEdit = new EventEmitter();
  doUpdateEdit(action: action) {
    this.onUpdateEdit.emit(action);
  }
  @Output() onShowLoading = new EventEmitter();
  doShowLoading(action: action) {
    this.onShowLoading.emit(action);
  }
  doNextEdit(item: any) {
    console.log("item: " + item);
    this.selectedTab = item;
  }
  setPrivileges() {
    this.hasActionMaster_View = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_View);   
  }

}
