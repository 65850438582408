<form [formGroup]="form" class="condition-form">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3em">
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='condition' [disabled]="condition.isStart">
                <mat-option value="AND">AND</mat-option>
                <mat-option value="OR">OR</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='fromColumn'>
                <mat-option *ngFor="let column of fromTable?.columns" [value]="column.id">{{column.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='oprator'>
                <mat-option value="1">Equal</mat-option>
                <mat-option value="2">Not Equal</mat-option>
                <mat-option value="3">Greater Than</mat-option>
                <mat-option value="4">Greater Than Or Equal</mat-option>
                <mat-option value="5">Less Than</mat-option>
                <mat-option value="6">Less Than Or Equal</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 0 10em">
            <mat-select required formControlName='toColumn'>
                <mat-option *ngFor="let column of toTable?.columns" [value]="column.id">{{column.name}}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
</form>
