import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { physicianperiod } from './physician-period';
import { PhysicianPeriodService } from './physician-period.service';
import * as custom from '../../Utility/CustomValidators.utilities'
import { debug } from 'console';
import { DialogMappingComponent } from '../cpt-details/dialog-mapping/dialog-mapping.component';
import { MatDialog } from '@angular/material/dialog';
import { CptDetailsService } from '../cpt-details/cpt-details.service';

@Component({
  selector: 'app-physician-period',
  templateUrl: './physician-period.component.html',
  styleUrls: ['./physician-period.component.css']
})
export class PhysicianPeriodComponent {
  public physicianList: physicianperiod[] = [];
  public physician!: physicianperiod;
  public isLoading: boolean = true;
  public filtervalue: string = "";
  public showEdit: boolean = false
  public hasPhysicianPeriod_View: boolean = false;
  public hasPhysicianPeriod_Edit: boolean = false;
  public hasPhysicianPeriod_Add: boolean = false;
  public practicelist !: any[];
  public providerlist !: any[];
  lookupData: any = {};
  public templates: any;
  public columnHeader: any;

  constructor(
    private physicianperiodservice: PhysicianPeriodService,
    private auth: AuthService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private cptdetailsservice: CptDetailsService
  ) { }
  ngOnInit() {
     //this.setPrivileges();
    this.getPhysicianList();
  }
  getPhysicianList() {    
    const request = {            
      pagesize: 10000,
      pagestartrecord: 0,
      PhysicianBlackoutPeriodID: -1,
      isactive: 1,
    }
    this.physicianperiodservice.getPhysicianPeriodList([request]).then(data => {
      this.physicianList = data;
      this.isLoading = false;
    });
    
  }

  async onEditPhysician(physician: physicianperiod) {

    this.isLoading = true;
    await this.getPacticesAndProviders();
    this.getPhysicianDetails(physician?.physicianblackoutperiodid);

  }
  onCancelEdit(item: any) {
    this.physician = {} as physicianperiod;
    this.showEdit = false;

    if (item)
      this.getPhysicianList();
  }
  async onAddPhysician() {
    this.isLoading = true;
    await this.getPacticesAndProviders();
    this.isLoading = false
    this.physician = {} as physicianperiod;
   // this.physician._id = "66b34d777f22a71296a85fa6";
    this.physician.isactive = 1;
    this.showEdit = true;
  }
  async getPhysicianDetails(id: string, showLoading = true) {
    console.log(id)
    if (showLoading)
      this.isLoading = true;
    await this.physicianperiodservice.getPhysicianPeriodDetails(id).then(res => {
      this.showEdit = true;
      if (res) {
        this.isLoading = false;
        this.physician = res[0] as physicianperiod;
        if (showLoading) {
          this.showEdit = true
        }
      }
    });    
  }

  doUpdateEdit() {
    this.getPhysicianList();    
  }
  doDelete() {
    this.getPhysicianList();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();

  }
  async onShowDetails(item: any) {
    item = this.getPhysicianDetails(item?._id);
    this.physician = item;
    this.showEdit = true;
  }

  async getPacticesAndProviders() {    
    if ((this.lookupData?.clients?.length ?? 0) == 0) {
      const providerRequest = [{
        clientid: '',
        uniqueservicingproviderid: -1,
        pagesize: 10000,
        pagestartrecord: 0,
      }]

      let lookups: any[] = [];
      lookups = await Promise.all([
        this.clientService.getlookup([]),
        this.clientService.getServicingProviders(providerRequest)
      ]);      
      this.lookupData = {
        clients: lookups[0]?.map((s: any) => { return { code: s.clientid ?? s.code, description: s.clientname ?? s.description, active: true } }),
        serviceProvider: lookups[1]?.filter((s: any) => { return s.servicingprovidername ?? s.description })?.map((s: any) => { return { code: s.uniqueservicingproviderid ?? s.code, description: s.servicingprovidername ?? s.description, active: true, clientid: s.clientid } })
      }

      this.lookupData.clients = (this.lookupData.clients ?? []).sort((a: any, b: any) => {
        return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
      });
      this.lookupData.serviceProvider = (this.lookupData.serviceProvider ?? []).sort((a: any, b: any) => {
        return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
      });
      console.log(this.lookupData);

    }
  }


  @ViewChild('f_input') f_input: any;

  public file: string = "";
  async onChange(event: any) {
    this.file = event.target.files[0];
    if (this.file) {

      this.cptdetailsservice.SendFile(this.file, (res: any) => {

        this.aftredownloadReport(res);

      });
      this.file = '';
    }
  }
  async aftredownloadReport(res: any) {
    this.isLoading = true;
    await this.getPacticesAndProviders(); //for clientlist
    this.columnHeader = res;
    await this.cptdetailsservice.gettemplateDetails().then(res => {
      if (res) {
        this.templates = res;
        this.isLoading = false;
      }
    });
  
    this.templates = this.templates?.map((s: any) => { return { code: s.FileName ?? s.code, description: s.FileName ?? s.description, active: true } })



    console.log(res);

    const dlgRefAction = this.dialog.open(DialogMappingComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          templates: this.templates,
          clients: this.lookupData.clients,
          providers: this.lookupData.serviceProvider,
          columnHeader: this.columnHeader
        },
        panelClass: 'confirm-dialog-container',
        //width: '30%',
        /*height: '500px',*/

      });

    var dialog_result = await dlgRefAction.afterClosed().toPromise();
    if (dialog_result) {
      this.isLoading = true;
      this.getPhysicianList();
      this.f_input.nativeElement.files = null;
      this.f_input.nativeElement.value = null;
    }
    else {
      this.f_input.nativeElement.files = null;
      this.f_input.nativeElement.value = null;
    }
  }

  //setPrivileges() {
  //  this.hasActionMaster_View = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_View);
  //  this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
  //  this.hasActionMaster_Add = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Add);
  //}


}
