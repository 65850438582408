import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};

@Injectable({
  providedIn: 'root'
})
export class MaturityCurveService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'maturitycurve';
  constructor(private http: HttpClient) { }

  public async saveMaturityCurve(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/save`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }

  public async getMaturityCurve(request: any): Promise<any> {
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/get`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
}
