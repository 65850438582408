import { Component, Inject, EventEmitter, Input, Output } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserInfo } from '../../entities/userInfo';
import { AuthService } from '../../services/auth.service';
import { AppState } from '../../store/app.states';
import { Md5 } from 'ts-md5';
import * as custom from '../../Utility/CustomValidators.utilities'
import { ThemeService } from '../../services/theme.service';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent {
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  public isLoading = false;
  public isSingleAccount = false;
  public remoteIpAddress = "";
  email = '';
  toBase64 = 'yrdrydry|tstrdrt'; 
  public showPassword!: boolean;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public auth: AuthService,
    public userService: UserService,

    private Auth: AuthService, private router: Router,
    private route: ActivatedRoute, private store: Store<AppState>,
    public themeService: ThemeService
  ) {
     
    if (this.route.queryParams) {
      let code = "";
      this.route.queryParams.forEach(w => {
        code = w.code;
        if (code) {
          this.login(code);
        }
        else {
          this.checkLogin();
          this.userService.isSingleAccount().then(s => {
            this.remoteIpAddress = s.data.ip;
            this.isSingleAccount = s.data.issingleaccount == "true" || s.data.issingleaccount == "True"
          })
        }
      });
    }
    else {
      this.userService.isSingleAccount().then(s => {
        this.isSingleAccount=s.data=="true"
      })
      this.checkLogin();
    }
  }
  submit() {
    //
    if (this.form.valid) {

      this.login("");
    }
  }
  async checkLogin() {
    this.isLoading = true;
    await this.auth.checkLogin();
    this.isLoading = false;
  }
  @Input() error: string = '';

  @Output() submitEM = new EventEmitter();

  login(code:string) {

    if (code != "") {
      localStorage.setItem("ssologin", "1");
    }
    else {
      localStorage.setItem("ssologin", "0");
    }


      let pass = this.hashKeyWebSafe(this.form.controls["password"].value);
      var deviceInformation = [{
        "deviceToken": this.createToken(),
        IPAddress: this.remoteIpAddress
      }];

      const User = [{
        "username": this.form.controls["username"].value,
        "password": pass,
        deviceinformation: JSON.stringify(deviceInformation),
        portalid: 1,
        authcode: code,
        tokenId: '',
        lointypeid: 2
      }]
      this.isLoading = true;
    this.Auth.getLogin(User).then(w => {
      
        let currentUser = w as UserInfo;
        if (currentUser.result == "1") {
          if (currentUser.token != "") {
            currentUser.isLoggedIn = true;
            this.store.dispatch({
              type: 'login',
              payload: currentUser
            });
            //localStorage.setItem("token", currentUser.token);
            location.href = currentUser.homeUrl;
            /*location.href = '/dashboard'*/
          }
        }
        else {
          this.error = currentUser.errorMessage;
        }
        this.isLoading = false;
      });
       
     
  }
  createToken() {
    return (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
  }
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  hashKeyWebSafe(str: string) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(str))
      .replace(/\//g, '_')
      .replace(/\+/g, '-');
  }

  async loginRedirect() {
    
    window.location.href = this.buildAuthUrl()
  }
  buildAuthUrl() {
    

    var authParams = {
      response_type: 'code',
      client_id: environment.msalConfig.auth.clientId,
      redirect_uri: location.href,
      scope: environment.apiConfig.scopes.join(" "),      
      response_mode: 'fragment'
    };
    //const Authurl = environment.msalConfig.auth.authority + $.param(authParams);
    const Authurl = `${environment.msalConfig.auth.authority}?client_id=${authParams.client_id}&scope=${authParams.scope}&response_type=code&redirect_uri=${authParams.redirect_uri}`;
    return Authurl;
  }

}
