<div>
    <ng-multiselect-dropdown [placeholder]="dropdownSettings.placeholder"
                             [settings]="dropdownSettings"
                             [data]="dropdownList"
                             [(ngModel)]="_selectedItems"
                             (onSelect)="onItemSelect($event)"
                             (onSelectAll)="onSelectAll($event)"
                             (onDeSelect)="onSelectAll($event)"
                             (onDeSelectAll)="onSelectAll($event)"
                             (onFilterChange)="onFilterChange($event)"
                             [ngModelOptions]="{standalone: true}"
                             [disabled]="_dropdownSettings.disabled"
                             [style]="{'pointer-events':((_dropdownSettings.disabled)?'none':'auto')}">
    </ng-multiselect-dropdown>
</div>
