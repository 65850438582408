
<div class="form-group col-lg-12">

    <section class="table-container">
        <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" matSortActive="encounterid" matSortDirection="asc">
            <ng-container matColumnDef="action" sticky>
                <th mat-header-cell mat-sort-header *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: element}"><mat-icon>more_vert</mat-icon></button>
                </td>
            </ng-container>
            <ng-container matColumnDef="practicename">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    practice
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital">
                    {{element.practicename }}
                    <!-- <app-multi-select (onSelectionChange)="onSelectionChange($event,element,'practicename',this)" [dropdownList]="this._data.lookupData.practices" [selectedValues]="element.uniquepracticeid" [singleSelection]="'true'" [placeholder]="'Client'" attr.id="Client{{element.encounterid}}" #clients></app-multi-select> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="encounterid">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Encounter#</th>
                <td mat-cell *matCellDef="let element" class="date-cell">
                    {{element.encounterid }}
                </td>
            </ng-container>


            <ng-container matColumnDef="patientaccountnumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>patient # </th>
                <td mat-cell *matCellDef="let element" class="date-cell">
                    <!--   {{element.patientaccountnumber }} -->
                    <app-autocomplete [display]="'code'" (onSelectionChange)="onAutoSelectionChange($event,element,'patientname')" [value]="{code:element.patientaccountnumber,description:element.patientname,display:element.patientaccountnumber}"></app-autocomplete>
                </td>
            </ng-container>

            <ng-container matColumnDef="patientname">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Patient Name
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital description-cell" [title]="element.patientname">
                    <app-autocomplete [display]="'code'" (onSelectionChange)="onAutoSelectionChange($event,element,'patientname')" [value]="{code:element.patientaccountnumber,description:element.patientname,display:element.patientname}"></app-autocomplete>
                    <!-- <input matInput autocomplete="off" [(value)]="element.patientname" (change)="onInputChange($event,element,'patientname')"> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="dob">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    DOB
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital date-cell">
                    <input matInput [matDatepicker]="dobpicker" [value]="getDateValue(element.dob)" (click)="dobpicker.open()" (dateInput)="setMonthAndYear($event, dobpicker,element,'dob')">
                    <mat-datepicker #dobpicker></mat-datepicker>
                </td>
            </ng-container>
            <ng-container matColumnDef="pm_system_providername">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    provider
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital description-cell">
                    <!-- {{element.pm_system_providername }} -->
                    <app-multi-select (onSelectionChange)="onSelectionChange($event,element,'uniqueproviderid',this)" [dropdownList]="this._data.lookupData.serviceProvider" [selectedValues]="element.uniqueproviderid" [singleSelection]="'true'" [placeholder]="'Provider'" attr.id="providername{{element.encounterid}}" #providernames></app-multi-select>
                </td>
            </ng-container>
            <ng-container matColumnDef="pm_system_servicelocationname">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Service Location
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital description-cell">
                    <!-- {{element.pm_system_servicelocationname }} -->
                    <app-multi-select (onSelectionChange)="onSelectionChange($event,element,'uniqueservicelocationid',this)" [dropdownList]="this._data.lookupData.serviceLocation" [selectedValues]="element.uniqueservicelocationid" [singleSelection]="'true'" [placeholder]="'Provider'" attr.id="location{{element.encounterid}}" #locations></app-multi-select>
                </td>
            </ng-container>
            <ng-container matColumnDef="cpt">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    CPT
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">

                    <input matInput type="text" autocomplete="off" [(value)]="element.cpt" (change)="onInputChange($event,element,'cpt','text')">
                </td>
            </ng-container>
            <ng-container matColumnDef="comments">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    comments
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">

                    <input matInput type="text" autocomplete="off" [(value)]="element.comments" (change)="onInputChange($event,element,'comments','text')">
                </td>
            </ng-container>
            <ng-container matColumnDef="cptordersequence">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    CPT order
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.cptordersequence }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.cptordersequence" (keypress)="allowNumberOnly($event)" (change)="onInputChange($event,element,'cptordersequence')">
                </td>
            </ng-container>
            <ng-container matColumnDef="dos">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    DOS
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital date-cell">
                    <input matInput [matDatepicker]="picker" [value]="getDateValue(element.dos)" (click)="picker.open()" (dateInput)="setMonthAndYear($event, picker,element,'dos')">
                    <mat-datepicker #picker></mat-datepicker>
                </td>
            </ng-container>
            <ng-container matColumnDef="starttime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Start Time
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital cell-created-on">
                    <!-- {{element.starttime | datePipeSafe: 'MM/DD/YYYY HH:mm:ss':true }} -->
                    <app-date-time-picker [value]="element.starttime" (onSelectionChange)="onDateTimeSelectionChange($event,element,'starttime')"></app-date-time-picker>
                </td>
            </ng-container>
            <ng-container matColumnDef="endtime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    End Time
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital cell-created-on">
                    <!-- {{element.endtime | datePipeSafe: 'MM/DD/YYYY HH:mm:ss':true }} -->
                    <app-date-time-picker [value]="element.endtime" (onSelectionChange)="onDateTimeSelectionChange($event,element,'endtime')"></app-date-time-picker>
                </td>
            </ng-container>
            <ng-container matColumnDef="total_charges">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Total Charges
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital">
                    <!-- <span [usd-money]="element?.total_charges" [usd-money-cell]="false"></span> -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.total_charges" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'total_charges','number')">
                </td>
            </ng-container>
            <ng-container matColumnDef="modifiers">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    modifiers
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.modifiers }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.modifiers" (change)="onInputChange($event,element,'modifiers','text')">
                </td>
            </ng-container>
            <ng-container matColumnDef="unit_charge">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    unit charge
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!--  {{element.unit_charge }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.unit_charge" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'unit_charge','number')">
                </td>
            </ng-container>
            <ng-container matColumnDef="units">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Units
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.units }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.units" (keypress)="allowNumberOnly($event)" (change)="onInputChange($event,element,'units')">
                </td>
            </ng-container>
            <ng-container matColumnDef="baseunits">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    base units
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.baseunits }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.baseunits" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'baseunits','number')">
                </td>
            </ng-container>
            <ng-container matColumnDef="timeunits">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    time units
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.timeunits }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.timeunits" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'timeunits','number')">
                </td>
            </ng-container>
            <ng-container matColumnDef="providerbaseunits">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    provider base units
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.providerbaseunits }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.providerbaseunits" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'providerbaseunits','number')">
                </td>
            </ng-container>
            <ng-container matColumnDef="providertimeunits">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    provider time units
                </th>
                <td mat-cell *matCellDef="let element" class="text-transform-capital ">
                    <!-- {{element.providertimeunits }} -->
                    <input matInput type="text" autocomplete="off" [(value)]="element.providertimeunits" (keypress)="keyPress($event)" (change)="onInputChange($event,element,'providertimeunits','number')">
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'payroll-dirty':row.isDirty}"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell-no-data" colspan="4" style="color: rgba(0, 0, 0, 0.87);font-size: 14px;">No data matching the filter</td>
            </tr>
        </table>
    </section>
    <mat-paginator #paginator [length]="payrollList.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true" (page)="doPageChange($event)">
    </mat-paginator>
    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="doAction(item,'add')">Add</button>
            <button mat-menu-item (click)="doAction(item,'copy')">Copy</button>
            <button mat-menu-item (click)="doAction(item,'refund')">Refund</button>
            <button mat-menu-item (click)="doAction(item,'delete')">Delete</button>
        </ng-template>
    </mat-menu>
    <div style="visibility: hidden; position: fixed"
         [style.left]="contextMenuPosition.x"
         [style.top]="contextMenuPosition.y"
         [matMenuTriggerFor]="menu" #menuFilterTrigger="matMenuTrigger">
    </div>     
</div>

