import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LookupEntitySelect } from '../../entities/lookup-entity';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.css']
})
export class CustomFilterComponent {
  public filteredRecords: LookupEntitySelect[] = [];
  public _data: LookupEntitySelect[] = [];
  public filterValue: string = '';
  public selectAll: boolean = false;
  @Input() set masterData(data: LookupEntitySelect[]) {
    this._data = data;
    this.filteredRecords = data;
  }
  OptionSelect(event: any, data: LookupEntitySelect) {
    if (data != null && !data.code) {
      this.selectAll = event.checked;
      this.filteredRecords = this.filteredRecords.map(w => { w.selected = event.checked; return w })
    }
    else {
      data.selected = event.checked;
    }
    this.selectAll = this.filteredRecords.filter(w => { return w.selected }).length == this.filteredRecords.length;
    console.log(data);
  }
  valuechange(e: any) {
    this.filteredRecords = this._data.filter(w => w.description?.toLowerCase().includes(this.filterValue?.toLowerCase()!));
  }
  @Output() onFilterApply = new EventEmitter<any[]>();

  doNo() {
    this.onFilterApply.emit([{ "apply": false }]);
  }
  doYes() {
    this.onFilterApply.emit(this._data.filter(w => { return w.selected }));
  }

}
