import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnChanges, AfterViewInit, OnInit {
  selectedDate: Date | null = new Date();
  selected: moment.Moment | null = moment();
  public hours: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public minutes: number[] = [];
  public selectedMinute: number = 2;
  public selectedHour: number = 6;
  public selectedMeridiem: string = "PM";
  public showCalander: boolean = false;
  @Output() onSelectionChange = new EventEmitter<any>();
  calendar!: MatCalendar<Date>;
  @ViewChild('calendar', { static: false }) set content(calendar: MatCalendar<Date>) {
    if (calendar) { // initially setter gets called with undefined
      this.calendar = calendar;
      if (this.calendar && this.selectedDate)
        this.calendar?._goToDateInView(this.selectedDate, "month")
    }
  }

  @Input() set value(value: any) {
    if (value) {
      this.selected = moment(value);
    }
    else {
      this.selected = moment();
    }
    this.selectedMinute = this.selected?.minute();
    this.selectedHour = this.selected?.hour();
    this.selectedMeridiem = this.selected?.format("A");
    this.selectedDate = new Date(this.selected?.format("MM/DD/YYYY"));
    
  }
  constructor() {
    for (let i: number = 0; i < 60; i++) {
      this.minutes.push(i)
    }
    //this.selected?.minute(this.selectedMinute);
    //this.selected?.hour(this.selectedHour);
    //this.selected?.m(this.selectedHour);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  onChanges(type: string, item: any) {
    switch (type) {
      case 'minute': {
        this.selected?.minute(this.selectedMinute);
        break;
      }
      case 'hour': {
        if (this.selectedMeridiem == "PM") {
          this.selectedHour = this.selectedHour == 12 ? 0 : this.selectedHour
          this.selected?.hour(this.selectedHour + 12);
          this.selectedHour = this.selectedHour == 0 ? 12 : this.selectedHour
        }
        else
          this.selected?.hour(this.selectedHour);
        break;
      }
      case 'meridiem': {
        if (this.selectedMeridiem != item) {
          this.selectedMeridiem = item
          let hour = this.selected?.hour() ?? 0;
          if (this.selectedMeridiem == "PM") {
            this.selectedHour = this.selectedHour == 12 ? 0 : this.selectedHour
            this.selected?.hour(hour + 12);
            this.selectedHour = this.selectedHour == 0 ? 12 : this.selectedHour
          }
          else {
            if (hour >= 12)
              this.selected?.hour(hour - 12);
            else
              this.selected?.hour(hour);
          }
        }
        break;
      }
    }

  }
  onCalendarSelect(item: any) {
    this.selected?.date(item.getDate());
    this.selected?.month(item.getMonth());
    this.selected?.year(item.getFullYear());
    this.onSelectionChange.emit(this.selected?.format("MM/DD/YYYY hh:mm A"))
  }
  ngAfterViewInit() {

  }
  ngOnInit() {

  }

  onShowCalander() {
    this.showCalander = !this.showCalander;
    
  }
}
