import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import * as custom from '../../Utility/CustomValidators.utilities'
import { NetRevenueService } from './net-revenue.service';

@Component({
  selector: 'app-net-revenue',
  templateUrl: './net-revenue.component.html',
  styleUrls: ['./net-revenue.component.css']
})
export class NetRevenueComponent implements OnInit {
  public showEdit: boolean = false;
  public isLoading: boolean = false;
  lookupData: any = {};
  filtervalue: string = "";
  netRevenueList: any = {};
  netRevenue: any = {};

  constructor(private clientService: ClientService,
    private netRevenueService: NetRevenueService) {
    this.getNetRevenueList();
  }
  async ngOnInit() {
    await this.getLookupData();
  }
  async applyFilter(item: any) {
    //debugger;
    this.filtervalue = "";
  }

  async getLookupData() {
    if ((this.lookupData?.clients?.length ?? 0) == 0) {
      let lookups: any[] = [];
      lookups = await Promise.all([
        this.clientService.getlookup([])
      ]);
      this.lookupData = {
        clients: lookups[0]?.map((s: any) => {
          return {
            code: s.clientid ?? s.code,
            description: s.clientname ?? s.description, active: true,
            "warehouseuniqueidentifier": s.warehouseuniqueidentifier,
            "practiceid": s.practiceid
          }
        }),
      }

      this.lookupData.clients = (this.lookupData.clients ?? []).sort((a: any, b: any) => {
        return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
      });

      console.log(this.lookupData);
    }
  }
  async getNetRevenueList() {
    this.isLoading = true;
    let request = {
      pagesize: 10000,
      pagestartrecord: 0,
      accountsrevenueid: -1,
      isactive: 1,
    }
    this.netRevenueService.getNetRevenueList(request).then(res => {
      this.netRevenueList = res;
      this.isLoading = false;
    });
  }
  async doUpdateEdit() {
    this.getNetRevenueList();
  }
  async onCancelEdit(item: any) {
    //debugger;
    this.showEdit = false;
    if (item)
      this.getNetRevenueList();
  }
  async onAddNetRevenue() {
    this.showEdit = true;
    this.netRevenue = {};
  }
  async onShowLoading(item: any) {
    this.isLoading = item;
  }
  async onEditNetRevenue(item: any) {
    this.netRevenue = item;
    this.showEdit = true;
  }
  async onShowDetails(item: any) {
    this.netRevenue = item;
    this.showEdit = true;
  }
}
