import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-physician',
  templateUrl: './user-physician.component.html',
  styleUrls: ['./user-physician.component.css']
})
export class UserPhysicianComponent {
  displayedColumns: string[] = ['collapse', 'checkbox', 'name', 'description'];

  physicians!: any[];
  dataSource = new MatTableDataSource<any>([]);
  public _user!: User;
  public assignAll: boolean = false;
  public _isLoading: boolean = false;
  public hasUserGroup_Edit: boolean = false;
  ngOnInit(): void {
    this.setPrivileges();
  }

  @Input() set user(user: User) {
    this._user = user;
    if (user && user.userid && user.userid != -1) {
      this.getUserPhysicians(user.userid)
    }
  }
  get user() {
    return this._user;
  }
  public _profileEdit: boolean = false;
  @Input() set profileEdit(profileEdit: boolean) {
    this._profileEdit = profileEdit;
    if (profileEdit == true) {
      this.dataSource.data = this.physicians.filter(w => { return w.assigned });
      this.displayedColumns = ['name', 'description'];
    }
  }
  get profileEdit() {
    return this._profileEdit;
  }

  get practice() {
    return (this.physicians ?? []).filter((data: any, idx: number) => {
      return data.isGroup
    });
  }

  constructor(private userService: UserService, private dialog: MatDialog,
    private auth: AuthService, private router: Router) {

  }
  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit() {
    if (this.profileEdit) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.onCancelEdit.emit();
    }
  }
  getUserPhysicians(userid: number) {
    this._isLoading = true;
    const request = [{ Physicianid: -1, userid: userid }]
    this.userService.getUserPhysician(request).then(res => {
      this.physicians = []
      res.filter((data: any, idx: number) => {
        return res.findIndex((s: any) => {
          return s.uniquepracticeid == data.uniquepracticeid
        }) == idx
      }).map((s: any, idx: number) => {
        let pys = res.filter((phy: any) => { return phy.uniquepracticeid == s.uniquepracticeid && (phy.physicianname || phy.physicianid == -1)   });
         
        this.physicians = [
          ...this.physicians,
          {
            id: idx + 1,
            physicianid: s.physicianid,
            userid: s.userid,
            name: s.physicianname,
            practicename: s.practicename,
            uniquepracticeid: s.uniquepracticeid,
            description: s.physiciandescription,
            assigned: pys.filter((phy: any) => { return phy.isassigned == 1 }).length == pys.length,
            isGroup: true
          },
          {
            id: `${idx}-${1}`,
            groupId: idx + 1,
            physicianid: -1,
            userid: s.userid,
            name: 'ALL',
            practicename: s.practicename,
            uniquepracticeid: s.uniquepracticeid,
            description: s.physiciandescription,
            assigned: pys.findIndex((phy: any) => { return phy.physicianid == -1 }) > -1,
            isGroup: false
          },
          ...res.filter((phy: any) => { return phy.uniquepracticeid == s.uniquepracticeid && phy.physicianname }).
            map((ss: any, idxs: number) => {
              return {
                id: `${idx}-${idxs + 2}`,
                groupId: idx + 1,
                physicianid: ss.physicianid,
                userid: ss.userid,
                name: ss.physicianname,
                practicename: ss.practicename,
                uniquepracticeid: ss.uniquepracticeid,
                description: ss.physiciandescription,
                assigned: ss.isassigned == 1,
                isGroup: false
              }
            })
        ]

        return {
          physicianid: s.physicianid,
          userid: s.userid,
          name: s.physicianname,
          description: s.physiciandescription,
          assigned: s.isassigned == 1
        } as any
      })
      //debugger
      this.assignAll = this.physicians.filter(w => { return w.assigned }).length == this.physicians.length;
      this.dataSource.data = this.physicians;
      this._isLoading = false;
    })
  }
  @Output() onUpdate = new EventEmitter();
  @Output() onShowLoading = new EventEmitter();
  //@Output() onUpdate = new EventEmitter<User>();
  async doSave() {
    let request = this.physicians.filter(w => { return w.assigned && !w.isGroup }).map(w => {
      return {
        physicianid: `${w.physicianid}`,
        userid: this.user.userid,
        clientid: w.uniquepracticeid,
        isactive: 1,
        loginuserid: -1
      }
    });
    //
    /*this._isLoading = true;*/
    this.onShowLoading.emit(true);
    this.userService.setUserPhysician(request).then(res => {
      this.onShowLoading.emit(false);

      let data: ConfirmDialogData = {} as ConfirmDialogData;
      data.showNo = true;
      data.message = res[0]?.errormessage;
      data.title = 'Information!';
      data.showYes = false;
      data.noText = 'OK';
      this.onUpdate.emit();
      Confirmation(this.dialog, data);
    });
  }
  onCheckboxChange(event: any, element: any | null) {
    if (element == null) {
      this.physicians.map(w => { w.assigned = event.checked; return w });
      this.dataSource.data = this.physicians;
    } else if (element.isGroup) {
      this.physicians.map(w => {
        if (w.groupId == element.id)
          w.assigned = event.checked;
        return w
      });
      this.dataSource.data = this.physicians;
    }
    else {
      element.assigned = event.checked;
    }
    if (!element.isGroup) {
      let parent = this.physicians.find(w => { return w.id == element.groupId });
      parent.assigned = this.physicians.filter(w => { return w.assigned && w.groupId == parent.id }).length == this.physicians.filter(w => { return w.groupId == parent.id }).length;
    }
    this.assignAll = this.physicians.filter(w => { return w.assigned }).length == this.physicians.length;
  }
  setPrivileges() {
    this.hasUserGroup_Edit = this.auth.hasPrivilege(PrivilegeValues.UserGroup_Edit);
  }

  isGroup = (index: any, item: any) => {
    return !item.isGroup;
  }
  physicianList(row: any) {
    return this.physicians.filter(w => { return w.groupId == row.id })
  }

  visible(row: any): boolean {
    if (row.isGroup) return row.isGroup
    let parent = this.physicians.find(w => { return w.id == row.groupId });

    if (parent)
      return parent.expanded;
    else return true;
  }
}
