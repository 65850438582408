<cdk-accordion class="example-accordion">
    <cdk-accordion-item #accordionItemAction="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        id="accordion-header-action"
                        [attr.aria-expanded]="accordionItemAction.expanded"
                        attr.aria-controls="accordion-body-action" #expPanels>
        <div class="example-accordion-item-header" (click)="accordionItemAction.toggle();doExpand()" matTooltip="Add Action" fxLayout="row" fxLayoutGap=".5em">
            <mat-icon>list</mat-icon>
            <span *ngIf="expanded">Action</span>
        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemAction.expanded ? '' : 'none'"
             id="accordion-body-action"
             aria-labelledby="accordion-header-action">
            <div fxLayout="column" fxLayoutGap="1em">
                <app-task-action [tags]="tags" [users]="users" [actions]="actions" [tasks]="tasks" (onCancelEdit)="onCancelEdit($event)" [isFromInfo]="'true'"></app-task-action>
            </div>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item #accordionItemLogs="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        id="accordion-header-logs"
                        [disabled]="tasks?.length>1 || tasks?.length==0"
                        [attr.aria-expanded]="accordionItemLogs.expanded"
                        attr.aria-controls="accordion-body-logs" #expPanels>
        <div class="example-accordion-item-header" [ngClass]="{'disabled':tasks?.length>1 || tasks?.length==0}" matTooltip="Logs" (click)="accordionItemLogs.toggle();doExpand()" fxLayout="row" fxLayoutGap=".5em">
            <mat-icon>history</mat-icon>

            <span *ngIf="expanded">Logs</span>
        </div>
        <div class="example-accordion-item-body"
             role="region"
             [style.display]="accordionItemLogs.expanded ? '' : 'none'"
             id="accordion-body-logs"
             aria-labelledby="accordion-header-logs">
            <div fxLayout="column" fxLayoutGap=".5em">
                <div class="form-group col-lg-12 ">
                    <div class="form-check form-switch float-end">
                        <input class="form-check-input" (change)="onHideSystemLog()" [(ngModel)]="showSystemLog" type="checkbox" id="flexSwitchCheckChecked">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Show System Log</label>
                    </div>
                </div>
                <div class="d-flex no-blcok" *ngFor='let activity of activities'>
                   <!--  <div class="row" *ngIf="activity.createdBy!=1 || showSystemLog"> -->
                    <ng-container *ngIf="activity.createdBy!=1 || showSystemLog">
                        <div class="m-r-20" style="margin-left: 10px;">
                            <app-avatar [username]="activity.createdBy" [userInitialclass]="'large'"></app-avatar>
                        </div>
                        <div class="p-b-10 b-b m-b-10">
                            <h6 class="m-0">
                                {{activity.createdBy}}
                                <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
                            </h6> 
                            <h4 class="m-0">
                                {{activity.logType}}                                 
                            </h4>
                            <p class="text-muted" style="margin: 10px 0;">{{activity.action}}</p>

                        </div>
                    </ng-container>
                        <!-- </div> -->
</div>
            </div>
        </div>
    </cdk-accordion-item>

</cdk-accordion>
