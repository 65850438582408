import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { cptdetails } from './cpt-details';
import * as custom from '../../Utility/CustomValidators.utilities'
import { CptDetailsService } from './cpt-details.service';
import { ThemeService } from '../../services/theme.service';
import { DialogMappingComponent } from './dialog-mapping/dialog-mapping.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegeValues } from '../../entities/Privilege';
import { PayrollService } from '../payroll/payroll.service';



@Component({
  selector: 'app-cpt-details',
  templateUrl: './cpt-details.component.html',
  styleUrls: ['./cpt-details.component.css']
})
export class CptDetailsComponent {
  public cptdetailsList: cptdetails[] = [];
  public cptdetails!: cptdetails;
  public isLoading: boolean = true;
  public filtervalue: string = "";
  public showEdit: boolean = false
  public hascptdetails_View: boolean = false;
  public hascptdetails_Edit: boolean = false;
  public hascptdetails_Add: boolean = false;
  public templates: any;
  public columnHeader: any;
  public clients: any;
  public practicelist !: any[];
  lookupData: any = {};

  constructor(
    private cptdetailsservice: CptDetailsService,
    private auth: AuthService,
    private clientService: ClientService,
    private payrollService: PayrollService,
    public themer: ThemeService,
    private dialog: MatDialog
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getCptDetailsList();
  }
  getCptDetailsList() {
    const request = {
      pagesize: 10000,
      pagestartrecord: 0,
      cptdefaultvalueid: -1,
      isactive: 1,
    }
    this.cptdetailsservice.getcptdetailsList([request]).then(data => {      
      this.cptdetailsList = data;
      this.isLoading = false;
    });

  }

  async onEditCptDetails(cptdetails: cptdetails) {    
    this.isLoading = true;
    await this.getPactices();
    this.getCptDetails(cptdetails?.cptdefaultvalueid);

  }
  onCancelEdit(item: any) {
    //debugger;
    this.cptdetails = {} as cptdetails;
    this.showEdit = false;

    if (item)
      this.getCptDetailsList();
  }
  async onAddCptDetails() {
    this.isLoading = true;
    await this.getPactices();
    this.isLoading = false
    this.cptdetails = {} as cptdetails;
    // this.physician._id = "66b34d777f22a71296a85fa6";
    this.cptdetails.isactive = 1;
    this.showEdit = true;
  }
  async getCptDetails(id: string, showLoading = true) {    
    console.log(id)
    if (showLoading)
      this.isLoading = true;
    await this.cptdetailsservice.getcptDetails(id).then(res => {
      this.showEdit = true;
      if (res) {
        this.isLoading = false;
        this.cptdetails = res[0] as cptdetails;
        if (showLoading) {
          this.showEdit = true
        }
      }
    });
  }

  doUpdateEdit() {
    this.getCptDetailsList();
  }
  doDelete() {
    this.getCptDetailsList();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();

  }
  async onShowDetails(item: any) {
    this.isLoading = true;
    await this.getPactices();
    item = this.getCptDetails(item?.cptdefaultvalueid);
    this.cptdetails = item;
    this.showEdit = true;
  }

  async getPactices() {
    if ((this.lookupData?.clients?.length ?? 0) == 0) {      

      let lookups: any[] = [];
      lookups = await Promise.all([
        this.clientService.getlookup([]),
        this.payrollService.GetCptTypesLookup([]),       
      ]);
      this.lookupData = {
        clients: lookups[0]?.map((s: any) => { return { code: s.clientid ?? s.code, description: s.clientname ?? s.description, active: true } }),
        cptTypes: lookups[1]?.filter((s: any) => { return s.cpttypedescription ?? s.description })?.map((s: any) => { return { code: s.cpttypeid ?? s.code, description: s.cpttypedescription ?? s.description, active: true } })
      }

      this.lookupData.clients = (this.lookupData.clients ?? []).sort((a: any, b: any) => {
        return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
      });
      this.lookupData.cptTypes = (this.lookupData.cptTypes ?? []).sort((a: any, b: any) => {
        return custom.compare(a.description?.toLocaleLowerCase() as string, b.description.toLocaleLowerCase() as string, true);
      });
      console.log(this.lookupData);

    }
  }

  

  @ViewChild('f_input') f_input: any;

  public file: string = "";
  UploadFile(event: any) {    
     this.file = event.target.files[0];
    if (this.file) {

      this.cptdetailsservice.SendFile(this.file, (res: any) => {

        this.aftredownloadReport(res);

      });
      this.file = '';
    }        
  }
  async aftredownloadReport(res: any) {
    this.isLoading = true;
    await this.getPactices(); //for clientlist
    this.columnHeader = res;
    await this.cptdetailsservice.gettemplateDetails().then(res => {     
      if (res) {        
        this.templates = res;
        this.isLoading = false;
      }
    });
    
    this.templates = this.templates?.map((s: any) => { return { code: s.FileName ?? s.code, description: s.FileName ?? s.description, active: true } })
    
    console.log(res);
    
    const dlgRefAction = this.dialog.open(DialogMappingComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          templates: this.templates,
          clients: this.lookupData.clients,
          cptTypes: this.lookupData.cptTypes,
          providers: "",
          columnHeader: this.columnHeader
        },
        panelClass: 'confirm-dialog-container',
        //width: '30%',
        /*height: '500px',*/

      });    
    var dialog_result = await dlgRefAction.afterClosed().toPromise();    
    if (dialog_result) {
      this.isLoading = true;
      this.getCptDetailsList();
      this.f_input.nativeElement.files = null;
      this.f_input.nativeElement.value = null;
    }
    else {      
      this.f_input.nativeElement.files = null;
      this.f_input.nativeElement.value = null;
    }
    
  }

  setPrivileges() {
    this.hascptdetails_View = this.auth.hasPrivilege(PrivilegeValues.CPT_View);
    this.hascptdetails_Edit = this.auth.hasPrivilege(PrivilegeValues.CPT_Edit);
    this.hascptdetails_Add = this.auth.hasPrivilege(PrivilegeValues.CPT_Add);
  }



}
