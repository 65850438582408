<div class="d-flex no-blcok" *ngFor='let activity of comments'>
    <div class="m-r-20" style="margin-left: 10px;">
        <app-avatar [username]="activity.createdby" [userInitialclass]="'large'"></app-avatar>
    </div>
    <div class="p-b-10 b-b m-b-10">
        <h4 class="m-0">
            {{activity.createdby}}
            <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
        </h4>
        <div>
            <p class="text-muted" style="margin: 10px 0;">{{activity.reportcomments}}</p>
        </div>
        <div fxLayout="row wrap" *ngIf="activity.attachments !==''">
            <div fxFlex.gt-sm="20" fxFlex="100">
                <img class="img-responsive rounded" [src]=activity.attachments alt="Image" (click)="doOpenImage(activity.attachments)">
            </div>
        </div>
    </div>
</div>
