import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { Menu, MenuItem, MenuItems } from '../../shared/menu-items/menu-items';
import { UserInfo } from '../../entities/userInfo';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AppState } from '../../store/app.states';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../../components/user/user.service';
import { request } from 'http';
import { ThemeService } from '../../services/theme.service';
import { ReportService } from '../../services/report.service';
import * as custom from '../../Utility/CustomValidators.utilities';
import { type } from 'os';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  public isLoggedIn: boolean = false;
  private _mobileQueryListener: () => void;
  public currentUser: UserInfo;
  private subscriptions: Subscription[] = [];
  public menuItem: MenuItem[] = [];
  public checkLoginTimeer: any;

  modulesList: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger: any;;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private themeService: ThemeService,
    private ren: Renderer2,
    private reportService: ReportService
  ) {
    //
    this.auth.checkLogin(); 
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    router.events.subscribe((val: any) => {       
      if (val.snapshot) {         
        if (this.menuItem.length > 0)
          if (this.menuItem.findIndex(w => { return (w.linkurl ?? "") == location.pathname }) < 0) {            
            //debugger;
            location.href = this.currentUser.homeUrl??'/login';
          }
      }
    })
    this.checkLoginTimeer = setInterval(() => { 
      this.auth.checkLogin(); 
    }, 100000);
    this.currentUser = {} as UserInfo;
    this.subscriptions.push(this.store.select(state => state.loginInfo)
      .subscribe((userInfo: any) => {

        this.currentUser = userInfo;
        this.isLoggedIn = userInfo.isLoggedIn;
      })
    );
    this.modulesList = [];
    this.getMenus();

  }

  async redirectHome(data: any) {
    if (data.privileges?.length > 0) {
      let filterdPrivledge = this.currentUser.privilege.filter((s: any) => { return data.privileges[0] == s });
      if (filterdPrivledge.length <= 0 && data.privileges[0] != "999V") {
        
        location.href = this.currentUser.homeUrl??'/login';
      }
    }

  }
  async getMenus() {
    const request = [{
      menuids: -1,
      loginuserid: -1,
      portalid: 1
    }];
    //this.userService.getMenus(request).then(res => {
    //  this.menuItem = res as MenuItem[];
    //});
    const lookups = await Promise.all([
      this.userService.getMenus(request),
      this.userService.setUserToken(),
      this.userService.getUserReports(request)
    ]);
    this.menuItem = lookups[0] as MenuItem[];

    //console.log("this.menuItem: ", this.menuItem);
    //this.menuItem.push({
    //  "externalsystem_guid": "",
    //  "imageclass": "",
    //  "imagepath": "",
    //  "linkurl": "/MaturityCurve",
    //  "menuid": 101,
    //  "menuname": "Maturity Curve",
    //  "menuparentid": 1,
    //  "sequence": 8
    //})

    this.reportService.accessToken = lookups[1].token ?? "";
    let reportdata: any[] = [];
    if (this.reportService.accessToken == "") {
      this.menuItem = this.menuItem.filter(w => {
        return (w.externalsystem_guid ?? "") == ""
      })
    }
    if (this.menuItem.findIndex(w => w.menuid == 9) > -1) {
      this.menuItem.filter(w => { return w.menuparentid == 9 }).forEach(pmenus => {
        pmenus.linkurl = pmenus.externalsystem_guid ? '' : pmenus.linkurl
        this.menuItem.filter(w => { return w.menuparentid == pmenus.menuid })
          .forEach(menus => {
            if (!menus.linkurl)
              menus.linkurl = `/report/groups/${pmenus.externalsystem_guid}/${menus.externalsystem_guid}`;
          });
        if (pmenus.externalsystem_guid && this.reportService.accessToken) {
          reportdata.push(this.reportService.getReports(pmenus.externalsystem_guid));
          reportdata.push(this.reportService.getdashboardEmbedURL(pmenus.externalsystem_guid));
        }
      });
    } 
    if (this.reportService.accessToken) {
      let reportLookups = await Promise.all(reportdata);
      reportdata = [];
      reportLookups.forEach(report => {
        report.value.map((w: any) => {
          let menu = this.menuItem.find(s => { return s.externalsystem_guid == w.id });
          if (!menu && w.reportType == "PowerBIReport") {
            //w.webUrl.split("reports")[1]
            if (w.webUrl.indexOf("d147ea95-fa4d-489e-99f2-9a8b0cea6c46") >= 0 && lookups[2].findIndex((usrReport: any) => { return usrReport.id == w.id }) >= 0 )
              this.menuItem.push({
                menuname: w.name,
                linkurl: `/report/groups${w.webUrl.split("groups")[1]?.replaceAll("/reports/", "/")}`,
                menuparentid: 10,
                menuid: w.id,
                sequence: this.menuItem.length + 1,
                imagepath: "",
                imageclass: "",
                externalsystem_guid: w.id
              });
          }


          reportdata.push({
            id: w.id,
            embedUrl: w.embedUrl,
            menuId: menu?.menuid ?? -1
          });
          return {
            id: w.id,
            embedUrl: w.embedUrl
          }
        });

      });
      localStorage.setItem("reportUrls", JSON.stringify(reportdata));
    }
    /*
    this.reportService.accessToken = lookups[1].token;
    if (this.reportService.accessToken != "" && this.menuItem.findIndex(w => w.menuid == 9) > -1) {
      this.reportService.getWorkspace().then(res => {
        if (res.value.length > 0) {
          let data = this.sortByName(res.value, 'name');
          data.forEach((w: any) => {
            this.menuItem.push({
              menuname: w.name,
              linkurl: '',
              menuparentid: 9,
              menuid: w.id,
              sequence: this.menuItem.length + 1,
              imagepath: "",
              imageclass: "",
            });            
            });
          })
        }
      });
    }
    */
    if (this.menuItem.findIndex(w => { return (w.linkurl ?? "") == location.pathname }) < 0) {
      if (!location.pathname.includes("login") && !location.pathname.includes('efd21645-4213-495d-ae74-9b10ef6b16b4') && !location.pathname.includes('ef9776c9-8f59-4b8c-98a9-fc530c02ed5c'))
        location.href = this.currentUser.homeUrl ?? '/login';
    }
    this.modulesList = this.menuItem;
  }
  sortByName(data: any, columnName: string) {
    data.sort((a: any, b: any) => {
      return custom.compare(a[columnName], b[columnName], true);
    });
    return data;
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }

  getMenuitem(menuparentid: number): MenuItem[] {
    return this.menuItem.filter(w => w.menuparentid == menuparentid);
  }
  getMenu() {
    return this.menuItem.filter(w => w.menuparentid == -1);
  }
  hasActiveRoute(menuparentid: number) {
    return this.menuItem.findIndex(w => w.menuparentid == menuparentid && this.router.url == w.linkurl) > 0;
  }



  menuenter() {

    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger: any, button: any) {

    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1: any, trigger2: any, button: any) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger: any, button: any) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }
}
