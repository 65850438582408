import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import Drawflow from 'drawflow';
import * as moment from 'moment-timezone';
import { ClientService } from '../../../services/client.service';
import { BotDashboardService } from '../../bot-dashboard/bot-dashboard.service';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { PayrollService } from '../payroll.service';

@Component({
  selector: 'app-payroll-details',
  templateUrl: './payroll-details.component.html',
  styleUrls: ['./payroll-details.component.css']
})
export class PayrollDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  _isLoading: boolean = false;
  @ViewChild('f_input') f_input: any;
  @Input()
  nodes: any[] = [];
  @Input()
  drawingData: string = '';
  @Input()
  lookupData: any = {};
  @Input()
  locked: boolean = false;
  @Input()
  showLock: boolean = false;
  @Input()
  showNodes: boolean = true;
  @Input()
  otherDetails: any = '';

  editor!: any;
  editDivHtml!: HTMLElement;
  editButtonShown: boolean = true;

  drawnNodes: any[] = [];
  selectedNodeId!: string;
  selectedNode: any = {};
  currentNode: any = {};

  lastMousePositionEv: any;

  nodeModal!: ElementRef;
  @ViewChild('content') set setNodeModal(el: ElementRef) {
    this.nodeModal = el;
  }
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;

  public _payroll: any = {};
  public scheduleInstanceId: number = -1;
  @Input() set payroll(payroll: any) {
    this._payroll = payroll;

    if (this._payroll && this._payroll.id) {
      //this.getPayrolldetails(this._payroll.id);
      this.scheduleInstanceId = this._payroll.id;
      this.bindPayrolldetails();
    }
  }
  public nodeData: any = {};
  constructor(
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private clientService: ClientService,
    private botDashboardService: BotDashboardService,
  ) {
    this.getNodes();

  }
  getNodes() {
    this.payrollService.getNodes([]).then(nodes => {
      this.nodes = nodes;
    });
  }

  // Private functions
  private initDrawFlow(dataToImport: any): void {
    const drawFlowHtmlElement = <HTMLElement>document.getElementById('drawflow');

    //const testEditor = new Drawflow(drawFlowHtmlElement);
    // testEditor.addConnection();
    if (!this.editor) {
      setTimeout(() => {
        this.editor = new Drawflow(drawFlowHtmlElement);


        this.editor.curvature = 0.5;
        this.editor.reroute_fix_curvature = true;
        this.editor.reroute_curvature = 0.5;
        this.editor.force_first_input = false;
        this.editor.line_path = 1;
        this.editor.editor_mode = 'edit';
        this.editor.reroute = true;
        this.editor.start();
        if (!this.locked) {
          this.addEditorEvents();
        }
      }, 1000);
    }
    else if (dataToImport) {
      this.editor.import(dataToImport);

      var exportvalue = null;
      exportvalue = this.editor.export();
      this.editor.import(exportvalue);
    }
    /*
    if (this.drawingData && Object.keys(JSON.parse(this.drawingData).drawflow.Home.data).length > 0) {
      console.log('this.drawingData :>> ', this.drawingData);
      this.editor.import(JSON.parse(this.drawingData));
    }
    */
  }

  private resetAllInputsOutputs() {
    this.nodes.forEach((node) => {
      node.inputs = 1;
      node.outputs = 1;
    });
  }

  private addEditorEvents() {
    // Events!
    this.editor.on('nodeCreated', (id: any) => {

    });

    this.editor.on('nodeRemoved', (id: any) => {
      console.log('Editor Event :>> Node removed ' + id);
    });

    this.editor.on('nodeSelected', (id: any) => {
      //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
      //this.open(this.selectedNode.html, this.selectedNodeId);
    });
    this.editor.on('dblclick', (e: any) => {
      console.log('Editor Event :>> Click :>> ', e);
      //console.log(this.selectedNode.html);
      //this.open(this.selectedNode.html, this.selectedNodeId);
    });
    this.editor.on('click', (e: any) => {
      //console.log('Editor Event :>> Click :>> ', e);
      //console.log(this.selectedNode.html);
      //this.open(this.selectedNode.html, this.selectedNodeId);
      //if (e.target.closest('.drawflow_content_node') != null || e.target.classList[0] === 'drawflow-node') {
      //  //if (e.target.closest('.drawflow_content_node') != null) {
      //  //  this.selectedNodeId = e.target.closest('.drawflow_content_node').parentElement.id;
      //  //} else {
      //  //  this.selectedNodeId = e.target.id;
      //  //}
      //  //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${this.selectedNodeId.slice(5)}`];
      //}

      //if (e.target.closest('#editNode') != null || e.target.classList[0] === 'edit-node-button') {
      //  // Open modal with Selected Node
      //  console.log(this.selectedNode.html);
      //  this.open(this.selectedNode.html, this.selectedNodeId);
      //}

      //if (e.target.closest('#editNode') === null) {
      //  this.hideEditButton();
      //}
    });

    this.editor.on('moduleCreated', (name: any) => {
      console.log('Editor Event :>> Module Created ' + name);
    });

    this.editor.on('moduleChanged', (name: any) => {
      console.log('Editor Event :>> Module Changed ' + name);
    });

    this.editor.on('connectionCreated', (connection: any) => {
      console.log('Editor Event :>> Connection created ', connection);
      const node_in = document.getElementsByClassName('node_in_node-' + connection.input_id)[0]
      node_in?.addEventListener('dblclick', (s: any) => {

      });
    });

    this.editor.on('connectionRemoved', (connection: any) => {
      console.log('Editor Event :>> Connection removed ', connection);
    });

    this.editor.on('contextmenu', (e: any) => {

      if (this.editor.connection_selected) {
        //this.onContextMenu(e, this.selectedNode);
        //this.editor.removeConnection();
        var t = document.createElement("div");
        t.classList.add("drawflow-delete"),
          t.innerHTML = "x",
          this.editor.connection_selected && this.editor.connection_selected.parentElement.classList.length > 1 && (t.style.top = e.clientY * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - this.editor.precanvas.getBoundingClientRect().y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) + "px",
            t.style.left = e.clientX * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - this.editor.precanvas.getBoundingClientRect().x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) + "px",
            this.editor.precanvas.appendChild(t))
      }
      else {

        this.selectedNode = this.editor.drawflow.drawflow.Home.data[e.target.id.replaceAll("node-", "")];
        if (!this.selectedNode) {
          this.selectedNode = this.editor.drawflow.drawflow.Home.data[e.target.offsetParent.id.replaceAll("node-", "")];
        }
        if (this.selectedNode) {
          this.onContextMenu(e, this.selectedNode);
        }
      }
      e.preventDefault()
    });

    this.editor.on('zoom', (zoom: any) => {
      console.log('Editor Event :>> Zoom level ' + zoom);
    });

    this.editor.on('addReroute', (id: any) => {
      console.log('Editor Event :>> Reroute added ' + id);
    });

    this.editor.on('removeReroute', (id: any) => {
      console.log('Editor Event :>> Reroute removed ' + id);
    });

    // this.editor.on('mouseMove', (position: any) => {
    //   console.log('Editor Event :>> Position mouse x:' + position.x + ' y:' + position.y);
    // });

    // this.editor.on('nodeMoved', (id: any) => {
    //   console.log('Editor Event :>> Node moved ' + id);
    // });

    // this.editor.on('translate', (position: any) => {
    //   console.log(
    //     'Editor Event :>> Translate x:' + position.x + ' y:' + position.y
    //   );
    // });
  }

  private initDrawingBoard() {
    this.initDrawFlow(null);

  }

  private showEditButton() {
    this.editButtonShown = true;
    this.editDivHtml = document.createElement('div');
    this.editDivHtml.id = 'editNode';
    this.editDivHtml.innerHTML = '<mat-icon>edit</mat-icon>';
    this.editDivHtml.style.display = 'block';
    this.editDivHtml.style.position = 'absolute';
    this.editDivHtml.className = 'edit-node-button';

    const selectedNodeHtml = document.getElementById(this.selectedNodeId);
    selectedNodeHtml?.append(this.editDivHtml);
  }

  private hideEditButton() {
    this.editButtonShown = false;
    this.editDivHtml = document.getElementById('editNode') as HTMLElement;
    if (this.editDivHtml) {
      this.editDivHtml.remove();
    }
  }

  async open(content: any, nodeId: string) {
    this.hideEditButton();

    // const oldNodeIdNumber = parseInt(nodeId.slice(5));
    //this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${oldNodeIdNumber}`];
    //const oldNodeStringified = JSON.stringify(this.selectedNode);
    // const { inputsCount, outputsCount } = this.countInOutputsOfNode(JSON.parse(oldNodeStringified));
    /*
    const dlgRef = this.dialog.open(PayrollDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          node: this.selectedNode,
          lookupData: this.lookupData
        },
        width: '40%',
      });
    let item = await dlgRef.afterClosed().toPromise()
    {
      
      if (item  ) {
        this.selectedNode.data.filters = item;
      }

    }
    */

    if (this.selectedNode.data.type != 4) {
      this.opensettingpane = true;
      this.nodeData = {
        node: this.selectedNode,
        lookupData: this.lookupData
      }
    }

  }

  onKeyEvent(e: any) {
    // this.nameAlreadyUsed = this.checkIfNameIsUsed(e.target.value, this.selectedNode.id);
  }

  private countInOutputsOfNode(node: any) {
    let inputsCount = 0;
    let outputsCount = 0;

    Object.keys(node.inputs).forEach((inputKey) => {
      if (node.inputs[`${inputKey}`].connections.length > 0) {
        inputsCount++;
      }
    });

    Object.keys(node.outputs).forEach((outputKey) => {
      if (node.outputs[`${outputKey}`].connections.length > 0) {
        outputsCount++;
      }
    });

    return { inputsCount, outputsCount };
  }

  private checkIfNameIsUsed(name: string, id: number) {
    for (let i = 0; i < Object.keys(this.editor.drawflow.drawflow.Home.data).length; i++) {
      const nodeId = Object.keys(this.editor.drawflow.drawflow.Home.data)[i];
      if (nodeId != `${id}`) {
        if (this.editor.drawflow.drawflow.Home.data[`${nodeId}`].name == name) {
          return true;
        }
      }
    }
    return false;
  }

  private reestablishOldConnections(oldNode: any, newNodeId: number) {
    Object.keys(oldNode.inputs).forEach((inputKey) => {
      oldNode.inputs[`${inputKey}`].connections.forEach((connection: any) => {
        this.editor.addConnection(connection.node, newNodeId, connection.input, inputKey);
      });
    });

    Object.keys(oldNode.outputs).forEach((outputKey) => {
      oldNode.outputs[`${outputKey}`].connections.forEach((connection: any) => {
        this.editor.addConnection(newNodeId, connection.node, outputKey, connection.output);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes :>> ', changes);

    if (
      changes['drawingData'] &&
      changes['drawingData'].currentValue &&
      changes['drawingData'].currentValue.length > 0 &&
      Object.keys(JSON.parse(changes['drawingData'].currentValue).drawflow.Home.data).length > 0
    ) {
      this.editor.import(JSON.parse(changes['drawingData'].currentValue));
    }
  }

  ngOnInit(): void {
    // this.initDrawingBoard();
    // this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
    //this.getPayrolldetails(66);
  }

  ngAfterViewInit(): void {
    this.initDrawingBoard();
    //this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
  }

  onDrawflowEvent(e: any) {
    switch (e.type) {
      case 'dragstart':
        // console.log('Drawflow Event: DragStart :>> e :>> ', e);
        this.currentNode = [...this.nodes].find((node) => node.id == parseInt(e.target.id));
        //this.selectedNode.data = JSON.parse(
        //  JSON.stringify([...this.nodes].find((node) => node.infos.name === e.target.outerText))
        //);
        break;
      case 'dragenter':
        // console.log('Drawflow Event: DragEnter :>> e :>> ', e);
        break;
      case 'dragover':
        // console.log('Drawflow Event: DragOver :>> e :>> ', e);
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'dragleave':
        console.log('Drawflow Event: DragLeave :>> e :>> ', e);
        break;
      case 'drop':
        // console.log('Drawflow Event: Drop :>> e :>> ', e);
        e.preventDefault();
        let data = this.editor.export().drawflow.Home.data

        let newData: any[] = [];
        Object.keys(data).forEach(w => { newData.push(data[w]) })
        if (newData.findIndex(w => { return w.data.id == this.currentNode.id }) == -1 || this.currentNode.allowMultiple) {
          this.addNodeToDrawBoard(e.clientX, e.clientY);
        }
         
        // this.resetAllInputsOutputs();
        break;

      default:
        // console.log('Other Drawflow Event :>> e :>> ', e);
        break;
    }
  }

  // Drawflow Editor Operations
  addNodeToDrawBoard(pos_x: number, pos_y: number) {
    if (this.editor.editor_mode === 'edit') {
      pos_x =
        pos_x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom));

      pos_y =
        pos_y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom));

      //console.log('addNodeToDrawBoard :>> this.selectedNode :>> ', this.selectedNode);

      let htmlTemplate = `<div id='${this.currentNode.id}'>${this.currentNode.infos.name}</div>`;


      var node = this.editor.addNode(
        this.currentNode.name,
        this.currentNode.inputs,
        this.currentNode.outputs,
        pos_x,
        pos_y,
        this.currentNode.infos.name,
        this.currentNode,
        htmlTemplate,
        false
      );
      document.getElementById('node-' + node)?.addEventListener('dblclick', (s: any) => {
        let id = s.currentTarget.id?.replaceAll("node-", '');
        if (id) {
          this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
          this.open(this.selectedNode.html, this.selectedNodeId);
        }
      });
    }
  }


  onClear() {
    this.editor.clear();
  }

  changeMode() {
    this.locked = !this.locked;
    this.editor.editor_mode = this.locked != null && this.locked == false ? 'edit' : 'fixed';
  }

  onZoomOut() {
    this.editor.zoom_out();
  }

  onZoomIn() {
    this.editor.zoom_in();
  }

  onZoomReset() {
    this.editor.zoom_reset();
  }

  exportDrawingData() {
    return this.editor.export();
  }

  onSubmit() {
    this.drawingData = this.exportDrawingData();
  }
  public requestdata: any[] = []
  runFlow() {
    var isvalid = true;
    let data = this.editor.export().drawflow.Home.data
    this.requestdata = [];
    let flowId = (new Date()).getTime().toString();
    Object.keys(data).map((w, idx) => {
      let node = document.getElementById('node-' + data[w].id);
      if (!data[w].data.filters && Object.keys(data[w].data.inputdata).findIndex(s => { return data[w].data.inputdata[s] == 1 }) > -1) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'Select valid inputs'
        }
      }
      else {

        let newData: any = {};
        newData.name = data[w].data.name
        newData.inputdata = data[w].data.inputdata;
        newData.pos_x = data[w].pos_x;
        newData.pos_y = data[w].pos_y;
        newData.id = data[w].id;
        newData.nodeId = data[w].data.id;
        newData.input = data[w].inputs ?? {};
        newData.output = data[w].outputs ?? {};
        if (((data[w].data.inputs == 1 && newData.input != -1) || data[w].data.inputs == 0) && ((data[w].data.outputs == 1 && newData.output != -1) || data[w].data.outputs == 0)) {
          if (node) {
            node.style.color = 'var(--dfNodeTextColor)'
            node.title = '';
          }
          Object.keys(newData.inputdata).forEach(s => {
            if (newData.inputdata[s] == 2) {
              newData.inputdata[s] = (this.requestdata[idx - 1] != undefined) ? this.requestdata[idx - 1].inputdata[s] : newData.inputdata[s];
            }
            else if (data[w].data.filters[s]?.constructor === Array) {
              newData.inputdata[s] = data[w].data.filters[s].map((ss: any) => { return ss.code }).join(',')
            }
            else if (s == "flowId") {
              newData.inputdata[s] = flowId
            }
            else if (s == "Isfirststep") {
              newData.inputdata[s] = newData.input == 1;
            }
            else if (data[w].data.filters[s]) {
              newData.inputdata[s] = data[w].data.filters[s];
            }
            else {
              newData.inputdata[s] = "";
            }
          });
        }
        else {
          isvalid = false;
          if (node) {
            node.style.color = 'red';
            node.title = 'Select valid input/output connections'
          }
        }

        this.requestdata.push(newData);
      }
    });
    if (isvalid) {
      let request = [
        {
          "EventId": "-1",
          "ScheduleInstanceId": this.scheduleInstanceId,
          "CreatedById": "-1",
          "ScheduleTypeId": 4,
          "EmailProfileId": -1,
          "ScheduleName": "payroll 1",
          "ScheduleDetails": "payroll 1",
          "IsActive": 1,
          "StartTime": "",
          "EndTime": "",
          "OccurenceRule": "RRULE:FREQ=DAILY;INTERVAL=1;UNTIL=20240823T240000Z",
          "ScheduleDescription": JSON.stringify(this.requestdata)
        }
      ]
      this.botDashboardService.addUpdateInstance(request).then(res => {
        let data: ConfirmDialogData = {} as ConfirmDialogData;
        data.showNo = true;
        data.title = 'Information!';
        data.showYes = true;
        data.noText = 'NO';
        data.yesText = 'YES';
        data.message = res[0]?.message;
        data.showYes = false;
        data.noText = 'OK';
        Confirmation(this.dialog, data)
      });
    }
  }
  get isvalidOld() {
    var isvalid = true;
    let data = this.editor.export().drawflow.Home.data
    this.requestdata = []
    let flowId = (new Date()).getTime().toString();
    if (!data) {
      isvalid = false
    }
    else if (Object.keys(data).length == 0) {
      isvalid = false
    }

    Object.keys(data).map((w, idx) => {
      let node = document.getElementById('node-' + data[w].id);
      if (!data[w].data.filters) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'Select valid inputs'
        }
      } else if (data[w].data.inputs == 1 && data[w].inputs.input_1 != undefined && data[w].inputs.input_1.connections.length == 0) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'input conntion is missing'
        }
      }
      else if (data[w].data.outputs == 1 && data[w].outputs.output_1 != undefined && data[w].outputs.output_1.connections.length == 0) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'output conntion is missing'
        }
      }
      else {

        let newData: any = {};
        newData.name = data[w].data.name
        newData.inputdata = data[w].data.inputdata;
        newData.pos_x = data[w].pos_x;
        newData.pos_y = data[w].pos_y;
        newData.id = data[w].id;
        newData.nodeId = data[w].data.id;
        newData.input = data[w].inputs ?? {};
        newData.output = data[w].outputs ?? {};
        if (((data[w].data.inputs == 1 && newData.input != -1) || data[w].data.inputs == 0) && ((data[w].data.outputs == 1 && newData.output != -1) || data[w].data.outputs == 0)) {
          if (node) {
            node.style.color = 'var(--dfNodeTextColor)'
            node.title = '';
          }
          Object.keys(newData.inputdata).forEach(s => {
            if (newData.inputdata[s] == 2) {
              newData.inputdata[s] = this.requestdata[idx - 1].inputdata[s];
            }
            else if (data[w].data.filters[s]?.constructor === Array) {
              newData.inputdata[s] = data[w].data.filters[s].map((ss: any) => { return ss.code }).join(',')
            }
            else if (s == "flowId") {
              newData.inputdata[s] = flowId
            }
            else if (s == "Isfirststep") {
              newData.inputdata[s] = newData.input == 1;
            }
            else if (data[w].data.filters[s]) {
              newData.inputdata[s] = data[w].data.filters[s];
            }
            else {
              newData.inputdata[s] = "";
            }
          });
        }
        else {
          isvalid = false;
          if (node) {
            node.style.color = 'red';
            node.title = 'Select valid input/output connections'
          }
        }

        this.requestdata.push(newData);
      }
    });
    //this.requestdata = this.editor.export();
    return isvalid;
  }
  async download(res: any) {
    let blob = await res.blob();
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = "output.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  onChange(event: any) {
    //this.file = this.f_input.nativeElement.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.f_input.nativeElement.files[0]);
    reader.onload = (e) => {

      //this.file = reader?.result as string;
    };

  }
  public opensettingpane: boolean = false;
  doCancel(item: any) {
    this.opensettingpane = false;
    if (item) {
      this.selectedNode.data.filters = item;
    }
  }

  getPayrolldetails(id: number) {
    let request: any = {
      schedule_instance_id: id,
      eventid_google: "-1",
      scheduletypeid: 4,
      schedulename: "",
      isactive: 1,
      createdby: -1,
      pagesize: 1000,
      pagestartrecord: 0,
      fromdate: moment(this._payroll.start).format("YYYY-MM-DD"),
      todate: moment(this._payroll.end).add(1, 'd').format("YYYY-MM-DD")
    };
    this._isLoading = true;
    this.botDashboardService.getAutomationSummary([request]).then(data => {
      this._isLoading = false;

      this.payroll = data[0];
      this.bindPayrolldetails();

    })
  }
  async bindPayrolldetails() {
    await this.getNodes();
    let dataToImport = {
      "drawflow": {
        "Home": {
          "data": {
          }
        }
      }
    }
    let newdata: any = {};
    let payrolldata = JSON.parse(this._payroll.description)[0].payrolldata;
    payrolldata.forEach((node: any, indx: number) => {
      //if (indx <= 1)
      {
        var currentNode = this.nodes.find(s => { return s.id == node.nodeId })
        let htmlTemplate = `<div id='${currentNode.id}'>${currentNode.infos.name}</div>`;

        (node.inputdata.cptCodes ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.excludeCpts.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.excludeCpts.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.excludecptCodes ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.excludeCpts.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.excludeCpts.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.asaCodes ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.asacodes.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.asacodes.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.workingDays ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.workingDays.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.workingDays.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.excludeModifiers ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.modifiers.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.modifiers.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.includeModifiers ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.modifiers.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.modifiers.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.excludeoccurances ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.occurances.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.occurances.push({ code: w, description: w, isCustom: false });
          }
        });
        (node.inputdata.includeoccurances ?? '').split(',').map((w: any) => {
          if (w && this.lookupData.occurances.findIndex((s: any) => { return s.code == w }) < 0) {
            this.lookupData.occurances.push({ code: w, description: w, isCustom: false });
          }
        });


        currentNode.filters = {
          tablename: node.inputdata.tablename,
          uniquepracticeid: this.lookupData.practices.filter((w: any) => { return node.inputdata.uniquepracticeid?.split(',').includes(w.code) }),
          datetype: this.lookupData.dateType.filter((w: any) => { return node.inputdata.datetype?.split(',').includes(w.code) }),
          runOn: node.inputdata.runOn,
          reducepercentage: node.inputdata.reducepercentage,
          cptCodes: this.lookupData.excludeCpts.filter((w: any) => { return node.inputdata.cptCodes?.split(',').includes(w.code) }),
          asaCodes: this.lookupData.asacodes.filter((w: any) => { return node.inputdata.asaCodes?.split(',').includes(w.code) }),
          providerOrderSequence: node.inputdata.providerOrderSequence,
          providerTypeId: this.lookupData.providerType.filter((w: any) => { return node.inputdata.providerTypeId?.split(',').includes(w.code) }),
          isanesthesia: node.inputdata.isanesthesia, /* 1 for anesthesia  and 0 for non anesthesia rows */
          defaultunits: node.inputdata.defaultunits, /* should be used when we need to update hardcode value */
          excludemodifiers: this.lookupData.modifiers.filter((w: any) => { return node.inputdata.excludeModifiers?.split(',').includes(w.code) }),
          includemodifiers: this.lookupData.modifiers.filter((w: any) => { return node.inputdata.includeModifiers?.split(',').includes(w.code) }),
          excludeoccurances: this.lookupData.occurances.filter((w: any) => { return node.inputdata.excludeoccurances?.split(',').includes(w.code) }),
          includeoccurances: this.lookupData.occurances.filter((w: any) => { return node.inputdata.includeoccurances?.split(',').includes(w.code) }),
          excludecptCodes: this.lookupData.excludeCpts.filter((w: any) => { return node.inputdata.excludecptCodes?.split(',').includes(w.code) }),
          serviceLocation: node.inputdata.uniqueServicelocationid == "" ? [] : this.lookupData.serviceLocation.filter((w: any) => { return node.inputdata.uniqueServicelocationid?.split(',').includes(w.code) }),
          workingDays: this.lookupData.workingDays.filter((w: any) => { return node.inputdata.workingDays?.split(',').includes(w.code) }),
          distributeTimeUnitsFactor: node.inputdata.distributeTimeUnitsFactor,
          RegulardistributeTimeUnitsFactor: node.inputdata.RegulardistributeTimeUnitsFactor,
          OverTimedistributeTimeUnitsFactor: node.inputdata.OverTimedistributeTimeUnitsFactor,
          OverTimeStart: node.inputdata.OverTimeStart,
          OvertimeEnd: node.inputdata.OvertimeEnd,
          useWorkingdaysfromform: node.inputdata.useWorkingdaysfromform,
          baseunitsroundoffvalue: node.inputdata.baseunitsroundoffvalue,
          timeunitsroundoffvalue: node.inputdata.timeunitsroundoffvalue,
          providertimeunitsroundoffvalue: node.inputdata.providertimeunitsroundoffvalue,
          providerbaseunitsroundoffvalue: node.inputdata.providerbaseunitsroundoffvalue,
          distributetimeunitsusingcapping: node.inputdata.distributetimeunitsusingcapping ?? "",
          usecpttypeidasfilter: node.inputdata.usecpttypeidasfilter,
          includecpttypeid: this.lookupData.CPTtypes.filter((w: any) => { return node.inputdata.includecpttypeid?.split(',').includes(w.code) }),
          excludecpttypeid: this.lookupData.CPTtypes.filter((w: any) => { return node.inputdata.excludecpttypeid?.split(',').includes(w.code) }),
          comments: node.inputdata.comments ?? "",
          defaultpercentage: node.inputdata.defaultpercentage ?? ""
        }

        newdata[node?.id?.toString()] = {
          "id": node?.id,
          "name": currentNode.name,
          "data": currentNode,
          "class": currentNode.infos.name,
          "html": htmlTemplate,
          "typenode": false,
          "inputs": node.input,
          "outputs": node.output,
          "pos_x": node.pos_x,
          "pos_y": node.pos_y,
        }
        /*
        var newnode = this.editor.addNode(
          currentNode.name,
          currentNode.inputs,
          currentNode.outputs,
          node.pos_x,
          node.pos_y,
          currentNode.infos.name,
          currentNode,
          htmlTemplate,
          false
        );
        //this.editor.addConnection(node.id, node.input, 'input_' + indx, 'output' + indx);
        var oldNode = this.editor.drawflow.drawflow.Home.data[newnode];
        if (oldNode)
          Object.keys(oldNode.inputs).forEach((inputKey) => {
            this.editor.addConnection(newnode,node.output, 'input_' + indx, inputKey);
            //[
            //  {
            //    "node": "2",
            //    "output": "input_1"
            //  }
            //]
            //oldNode.inputs[`${inputKey}`].connections.forEach((connection: any) => {
            //  this.editor.addConnection(connection.node, newnode, connection.input, inputKey);
            //});
          });
        document.getElementById('node-' + newnode)?.addEventListener('dblclick', (s: any) => {
          let id = s.currentTarget.id?.replaceAll("node-", '');
          if (id) {
            this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
            this.open(this.selectedNode.html, this.selectedNodeId);
          }
        });*/
      }
    })
    dataToImport.drawflow.Home.data = newdata;
    //this.editor.start();
    //this.editor.import(dataToImport);

    setTimeout(() => {
      this.initDrawFlow(dataToImport);

      payrolldata.forEach((node: any, indx: number) => {
        //this.editor.updateConnectionNodes('node-' + node.id);
        document.getElementById('node-' + node.id)?.addEventListener('dblclick', (s: any) => {
          let id = s.currentTarget.id?.replaceAll("node-", '');
          if (id) {
            this.selectedNode = this.editor.drawflow.drawflow.Home.data[`${id}`];
            this.open(this.selectedNode.html, this.selectedNodeId);
          }
        });
      });
    }, 1000);
  }
  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
  onDelete(item: any) {
    this.editor.removeNodeId(`node-${item.id}`)
  }
  onEdit(item: any) {
    this.open(item.html, item.id);
  }

  get isvalid() {
    var isvalid = true;
    let data = this.editor.export().drawflow.Home.data
    this.requestdata = []
    let nextnode = Object.keys(data).find(w => { return data[w].data.id == 1 });
    let nextnodeinx = nextnode?.toString() ?? Object.keys(data)[0];
    let flowId = (new Date()).getTime().toString();
    if (!data) {
      isvalid = false
    }
    else if (Object.keys(data).length == 0) {
      isvalid = false
    }
    else if (!nextnodeinx) {
      isvalid = false
    }

    do {
      //Object.keys(data).map((w, idx) => {
      nextnode = data[nextnodeinx];
      let node = document.getElementById('node-' + data[nextnodeinx].id);
      if (!data[nextnodeinx].data.filters) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'Select valid inputs'
        }
      } else if (data[nextnodeinx].data.inputs == 1 && data[nextnodeinx].inputs.input_1 != undefined && data[nextnodeinx].inputs.input_1.connections.length == 0) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'input conntion is missing'
        }
      }
      else if (data[nextnodeinx].data.outputs == 1 && data[nextnodeinx].outputs.output_1 != undefined && data[nextnodeinx].outputs.output_1.connections.length == 0) {
        isvalid = false;
        if (node) {
          node.style.color = 'red'
          node.title = 'output conntion is missing'
        }
      }
      else {

        let newData: any = {};
        newData.name = data[nextnodeinx].data.name
        newData.inputdata = data[nextnodeinx].data.inputdata;
        newData.pos_x = data[nextnodeinx].pos_x;
        newData.pos_y = data[nextnodeinx].pos_y;
        newData.id = data[nextnodeinx].id;
        newData.nodeId = data[nextnodeinx].data.id;
        newData.input = data[nextnodeinx].inputs ?? {};
        newData.output = data[nextnodeinx].outputs ?? {};
        if (((data[nextnodeinx].data.inputs == 1 && newData.input != -1) || data[nextnodeinx].data.inputs == 0) && ((data[nextnodeinx].data.outputs == 1 && newData.output != -1) || data[nextnodeinx].data.outputs == 0)) {
          if (node) {
            node.style.color = 'var(--dfNodeTextColor)'
            node.title = '';
          }

          newData.output = data[nextnodeinx].outputs ?? {};
          newData.parameters = Object.keys(newData.inputdata).map(w => { return "p_" + w }).join(',');
          Object.keys(newData.inputdata).forEach(s => {
            if (s == "tablename") {
              newData.inputdata[s] = "payroll"
            }
            else if (newData.inputdata[s] == 2 && this.requestdata.length > 0) {
              newData.inputdata[s] = this.requestdata[this.requestdata.length - 1].inputdata[s];
              let i = 1;
              do {
                newData.inputdata[s] = this.requestdata[this.requestdata.length - i].inputdata[s];
                i++;
              }
              while (!newData.inputdata[s] && this.requestdata.length - i >= 0)
            }
            else if (data[nextnodeinx].data.filters[s]?.constructor === Array) {
              newData.inputdata[s] = data[nextnodeinx].data.filters[s].map((ss: any) => { return ss.code }).join(',')
            }
            else if (s == "flowId") {
              newData.inputdata[s] = flowId
            }
            else if (s == "Isfirststep") {
              newData.inputdata[s] = newData.input == 1;
            }
            else if (data[nextnodeinx].data.filters[s]) {
              newData.inputdata[s] = data[nextnodeinx].data.filters[s];
            }
            else {
              newData.inputdata[s] = "";
            }
          });
        }
        else {
          isvalid = false;
          if (node) {
            node.style.color = 'red';
            node.title = 'Select valid input/output connections'
          }
        }

        this.requestdata.push(newData);
      }
      //});
      //this.requestdata = this.editor.export();
      nextnodeinx = data[nextnodeinx].outputs?.output_1?.connections[0]?.node;
    }
    while (nextnodeinx);

    let index = this.requestdata.findIndex(w => { return w.name == 'Payroll_setPayrollData' });
    let providersummaryIndex = this.requestdata.findIndex(w => { return w.name == "payroll_setPayrollprovidersummary" });
    let setPayroll = this.requestdata.find(w => { return w.name == 'Payroll_setPayrollData' });
    let setPayrollprovidersummary = this.requestdata.find(w => { return w.name == 'payroll_setPayrollprovidersummary' });

    let validIndex = this.requestdata.length - 2;

    if (providersummaryIndex > -1) {
      validIndex = this.requestdata.length - 3;
    }


    if (index != validIndex) {
      if (setPayroll) {
        let node: any = document.getElementById('node-' + setPayroll.id);
        if (node) {
          node.style.color = 'red'
          node.title = 'this rule should be last rule.'
          isvalid = false;
        }

      }
    }
    else {
      if (setPayroll) {
        let node: any = document.getElementById('node-' + setPayroll.id);
        if (node) {
          node.style.color = 'var(--dfNodeTextColor)'
          node.title = '';
        }
      }
    }
    if ((providersummaryIndex > -1 && providersummaryIndex != this.requestdata.length - 2)) {
      if (setPayrollprovidersummary) {
        let node: any = document.getElementById('node-' + setPayrollprovidersummary.id);
        if (node) {
          node.style.color = 'red'
          node.title = 'this rule should be last rule.'
          isvalid = false;
        }
      }
    }
    else {
      if (setPayrollprovidersummary) {
        let node: any = document.getElementById('node-' + setPayrollprovidersummary.id);
        if (node) {
          node.style.color = 'var(--dfNodeTextColor)'
          node.title = '';
        }
      }
    }



    return isvalid;
  }
}
