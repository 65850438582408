import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as custom from '../Utility/CustomValidators.utilities';

const httpOptionsJSON = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Authorization": "Bearer " + localStorage.getItem("token")
  })
};
@Injectable({
  providedIn: 'root'
})

export class ClientService {
  readonly baseUrl: string = environment.apibaseUrl;
  readonly baseApi: string = 'api';
  readonly baseQuery: string = 'odata';
  readonly service: string = 'Client';
  constructor(private http: HttpClient) {

  }
  async getlookup(request: any): Promise<any> { 
    if (!request) {
      request = []
    }
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/lookup`,
    custom.httpOptions()).toPromise();
 
    return result;
  }
  public async getClient(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getclientsdetails`,
      custom.httpOptions()).toPromise();
    return result;

    //let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getclientsdetails`,
    //  JSON.stringify(request),
    //  httpOptionsJSON).toPromise();
    //return result;
  }
  public async getClientDetails(id: number): Promise<any> {
 
 
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${id}`,
      httpOptionsJSON).toPromise();
    return result;
  }
  async setClient(request: any): Promise<any> {

    if (!request) {
      return null
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/setclientsdetails`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getreportfilter(request: any[]): Promise<any> {
    
    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/reportfilter`,
     // custom.httpOptions()).toPromise();

    //let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/${reportid}`,
      //httpOptionsJSON).toPromise();

    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/getreportfilter`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getselectors(request: any): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/selectors`,
      custom.httpOptions()).toPromise();
    return result;
  }
  async getServiceLocations(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }    
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/servicelocations`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async getPracticeManagementLookup(request: any): Promise<any> {
    if (!request) {
      request = [{
        "id": -1
      }]
    }
    let result = await this.http.get(`${this.baseUrl}/${this.baseApi}/${this.service}/pmlookup`,
    custom.httpOptions()).toPromise();
    //console.log("pmlookup: ", JSON.stringify(result));

    //let result = [{ "id": 1, "name": "kareo" }, { "id": 2, "name": "vertex" }, { "id": 3, "name": "allscript" }, { "id": 4, "name": "amd" }, { "id": 5, "name": "centricity" }, { "id": 6, "name": "collab" }, { "id": 7, "name": "pdm" }, { "id": 8, "name": "ecw" }, { "id": 9, "name": "drchrono" }, { "id": 10, "name": "phygenesis" }];
    return result;
  }
  async getServicingProviders(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/servicingproviders`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async GetPrimaryPayorGroups(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/primarypayorgroups`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();
    return result;
  }
  async GetPrimaryPayors(request: any[]): Promise<any> {
    if (!request) {
      request = []
    }
    let result = await this.http.post(`${this.baseUrl}/${this.baseApi}/${this.service}/primarypayors`,
      JSON.stringify(request),
      httpOptionsJSON).toPromise();

    return result;
  }
}



