import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LookupEntityBase } from '../../../entities/lookup-entity';
import { AuthService } from '../../../services/auth.service';
import { MultiSelectComponent } from '../../../shared/multi-select/multi-select.component';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { PhysicianPeriodService } from '../../physician-period/physician-period.service';
import { cptdetails } from '../cpt-details';
import { CptDetailsService } from '../cpt-details.service';
import { DialogMappingComponent } from '../dialog-mapping/dialog-mapping.component';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.css']
})
export class MappingComponent {

  public _templatelist: LookupEntityBase[] = [];
  public _clientlist: LookupEntityBase[] = [];
  public _cptTypelist: LookupEntityBase[] = [];
  public _providerlist: LookupEntityBase[] = [];
  public _columnHeaderlist !: any[];
  public _isFromInfo: boolean = false;
  public isFromInfo: boolean = false;
  public fileName!: string;
  public waiting: boolean = false;
  public templateHeaderlist !: any[];
  public selectedclinic: any;
  public selectedprovider: any;
  public selectedcptType: any;
  public bulkData: any;
  public sortedData = new MatTableDataSource<cptdetails>([]);
  public isProviderInfo: boolean = false;
  @ViewChild('clientlist') _client!: MultiSelectComponent;
  @ViewChild('templatelist') _template!: MultiSelectComponent;
  @ViewChild('providerlist') _provider!: MultiSelectComponent;
  @ViewChild('cptTypes') _cptTypes!: MultiSelectComponent;
  displayedColumns: string[] = ['template', 'csv'];
  @ViewChild('template') _templateName!: MultiSelectComponent;

  @Input() set templatelist(templatelist: any[]) {
    this._templatelist = templatelist;    
  }

  get actions() {
    return this._templatelist;
  }
  @Input() set cptTypes(cptTypes: any[]) {
    this._cptTypelist = cptTypes;
  }
  get cptTypes() {
    return this._cptTypelist
  }
  @Input() set clientlist(clientlist: any[]) {
    this._clientlist = clientlist;
  }
  
  get clients() {
    return this._clientlist
  }

  @Input() set providerlist(providerlist: any[]) {    
    if (providerlist.length != 0) {
      this.isProviderInfo = true
      this._providerlist = providerlist;
    }
  }

  get providers() {
    return this._providerlist
  }

  @Input() set columnHeaderlist(columnHeaderlist: any[]) {    
    
    if (columnHeaderlist.length != 0) {
      this._columnHeaderlist = columnHeaderlist;
      this.fileName = columnHeaderlist[0].FileName;
      this._columnHeaderlist = this._columnHeaderlist?.map((s: any) => { return { code: s.Headers ?? s.code, description: s.Headers ?? s.description, active: true } })
    }
  }

  get columnHeaders() {
    return this._columnHeaderlist
  }

  public doYes() {
    this.dialogRef.close(true);
  }

  public doNo() {
    this.dialogRef.close(false);
  }

  constructor(
    private cptdetailsservice: CptDetailsService,
    private physicianperiodservice: PhysicianPeriodService,
    private auth: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogMappingComponent>
  ) { }

  formAdd: FormGroup = new FormGroup({
    template: new FormControl(''),
    client: new FormControl('')
  });

  async onNextMapiing() {    
    let selectitems: any = this.filtervalue();
    this.selectedclinic = selectitems.practiceIds[0];
    this.selectedprovider = selectitems.Providers[0];
    this.selectedcptType = selectitems.cptTypes[0];
    let request: any = {
      templatename: selectitems.templates[0].description
    };
    //let cli = this.formAdd.controls.client.value;
 
    await this.cptdetailsservice.gettemplateHeaders([request]).then(res => {
      if (res) {        
        this.templateHeaderlist = res;
        this.sortedData.data = this.templateHeaderlist;
        this.isFromInfo = true;
        
      }
    });
  }

  async onSaveMapiing() {

    console.log(this.selectedclinic);
    this.waiting = true;
    if (this.isProviderInfo) {   // for physician
      //debugger;
      this.bulkData = {
        uniquepracticeid: this.selectedclinic.code,
        uniqueproviderid: this.selectedprovider.code ?? '',
        filename: this.fileName,
        data: this.sortedData.data,
        loginuserid: this.auth.currentUser.username
      };
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      await this.physicianperiodservice.setBulkUploading(this.bulkData).then(res => {
        this.waiting = false;
        if (res) {

          data.message = res[0].errormessage;
          data.title = 'Information!';
          data.showYes = true;
          data.yesText = 'OK';
          Confirmation(this.dialog, data);
          this.dialogRef.close(true);          
        }
      });
    }
    else {                       // for cpt
      this.bulkData = {
        uniquepracticeid: this.selectedclinic.code,        
        filename: this.fileName,
        data: this.sortedData.data,
        loginuserid: this.auth.currentUser.username,
        cptTypeid: this.selectedcptType?.code ?? ''
      };
      let data: ConfirmDialogData = {} as ConfirmDialogData;
      await this.cptdetailsservice.setBulkUploading(this.bulkData).then(res => {
        this.waiting = false;
        if (res) {
          data.message = res[0].msg;
          data.title = 'Information!';
          data.showYes = true;
          data.yesText = 'OK';
          Confirmation(this.dialog, data);
          this.dialogRef.close(true);
          //this.onCancelEdit.emit(true);
        }
      });
    }
  }

  filtervalue() {
    return {
      practiceIds: (this._client) ? this._client.selectedItems : [],
      templates: (this._template) ? this._template.selectedItems : [],
      Providers: (this._provider) ? this._provider?.selectedItems : [],
      cptTypes: (this._cptTypes) ? this._cptTypes?.selectedItems : [],
    };
  }

  filtervaluefortemplate() {
    return {
      templateNames: (this._templateName) ? this._templateName.selectedItems : [],      
    };
  }

  @Output() onCancelEdit = new EventEmitter();
  doCancelEdit(item: boolean) {    
    this.onCancelEdit.emit(item);
  }

  onSelectionChange(item: any,element : any) {
    let selectedItems = (this._columnHeaderlist && item) ? item[0]?.code : '';
    element.csv = (this._columnHeaderlist && item) ? item[0]?.code : '';
  }

  onSelectionChangeForTemplate(item: any) {
    let selectedItems = (this._templatelist && item) ? item[0]?.code : '';
    let selectedItem = (this._clientlist && item) ? item[0]?.code : '';

    if (this.isProviderInfo) {
      let selectedItem = (this._providerlist && item) ? item[0]?.code : '';
    }
  }
}
