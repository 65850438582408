<div class="example-container">
  <form [formGroup]="formAdd">
    <div class="row" fxLayout="column" fxLayoutGap="1em">
      <div class="form-group col-lg-6 ">
        <small id="name" class="form-text text-muted">Name <span style="color:red">*</span></small>
        <input type="text" class="form-control" [value]="action?.Actionname??''" id="nametext" aria-describedby="Name" placeholder="Name" formControlName="name" />
        <mat-error *ngIf="formAdd.get('name')?.hasError('required') && formAdd.get('name')?.touched">
          Name is required
        </mat-error>
      </div>
      <div class="form-group col-lg-6">
        <small id="description" class="form-text text-muted">Description</small>
        <textarea type="text" rows="3" [maxLength]="250" [value]="action?.Actiondescription??''" class="form-control" id="descriptiontext" aria-describedby="Description" placeholder="Description" formControlName="description"></textarea>
        <mat-error *ngIf="formAdd.get('description')?.hasError('maxLength') && formAdd.get('description')?.touched">
          Cannot Insert more than 250 characters
        </mat-error>
      </div>
      <div class="form-group col-lg-6">
        <div class="form-check form-switch">
          <input class="form-check-input" [checked]="action?.isactive" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="isActive">
          <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
        </div>
      </div>

      <div class="form-group col-lg-6">
        <!--<button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasGroup_Edit">Save Changes</button>-->
        <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasActionMaster_Edit">Save Changes</button>
        <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
      </div>
    </div>

  </form>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
