
<div class="body-wrapper">
    <mat-card class="main-card">
        <mat-card-content>
            <mat-card-title>
                Notifications
            </mat-card-title>
            <mat-card-subtitle> Home &gt; Notifications</mat-card-subtitle>
            <mat-divider></mat-divider>
            <section class="edit-container" style="padding: 10px;" (scroll)="onScroll($event)">
                <div class="d-flex no-blcok" *ngFor='let activity of activities'>
                    <div class="m-r-20" style="margin-left: 10px;">
                        <app-avatar [username]="activity.fromuser" [userInitialclass]="'large'"></app-avatar>
                    </div>
                    <div class="p-b-10 b-b m-b-10">
                        <h6 class="m-0">
                            {{activity.fromuser}}
                            <small class="text-muted">({{getUserTime(activity.createdon)}})</small>
                        </h6>
                        <h4 class="m-0">
                            {{activity.notificationtitle}}
                        </h4>
                        <div>
                            <p *ngIf="activity.actionurlid ==''" class="text-muted" style="margin: 10px 0;">{{activity.notificationbody}}</p>
                            <a *ngIf="activity.actionurlid !=''" [routerLink]="[activity.actionurlid]" style="margin: 10px 0;">{{activity.notificationbody}}</a>
                        </div>

                    </div>
                </div>
            </section>
            <mat-progress-bar *ngIf="_isLoadingMore" mode="indeterminate"></mat-progress-bar>
        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="isLoading"></app-data-loader>

