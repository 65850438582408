<div class="example-container">
    <form [formGroup]="formAdd">
      <div class="row" fxLayout="column" fxLayoutGap="1em">

        <div class="form-group col-lg-6 ">
          <div class="form-group" style="margin-top: 9px;" [class.col-lg-12]="!_isFromInfo" [class.col-lg-6]="_isFromInfo">
            <small id="name" class="form-text text-muted">Practice</small>
            <app-multi-select (onSelectionChange)="onSelectionChange($event)" [dropdownList]="_lookupData.clients" [selectedItems]="selectedClients" [singleSelection]="'true'" [placeholder]="'practice'" #clients></app-multi-select>
            <!-- <mat-error *ngIf="(_client?.selectedItems ?? []).length==0 && formAdd?.touched">
          Practice is required
      </mat-error> -->

          </div>
        </div>
        <div class="form-group col-lg-6 ">
          <div class="form-group" style="margin-top: 9px;" [class.col-lg-12]="!_isFromInfo" [class.col-lg-6]="_isFromInfo">
            <small id="name" class="form-text text-muted input-required">Type</small>
            <app-multi-select [dropdownList]="_lookupData.cptTypes" [selectedValues]="_cptdetails?.cpttypeid??''" [singleSelection]="'true'" [placeholder]="'Type'" #cptTypes></app-multi-select>
            <mat-error *ngIf="(_cptTypes?.selectedItems ?? []).length==0 && formAdd?.touched">
              Type is required
            </mat-error>

          </div>
        </div>

        <div class="form-group col-lg-6 ">
          <small id="name" class="form-text text-muted input-required">CPT</small>
          <input type="text" class="form-control" [value]="cptdetails?.cpt??''" id="nametext" aria-describedby="CPT" placeholder="CPT" formControlName="cpt" />
          <mat-error *ngIf="formAdd.get('cpt')?.hasError('required') && formAdd.get('cpt')?.touched">
            CPT is required
          </mat-error>
        </div>

        <div class="form-group col-lg-6 ">
          <small id="name" class="form-text text-muted input-required">Default value</small>
          <input type="text" class="form-control" [value]="cptdetails?.defaultvalue??''" id="nametext" aria-describedby="Default value" placeholder="Default value" formControlName="defaultvalue" (keypress)="allowNumberOnly($event)" (change)="onInputChange($event,1)" />
          <mat-error *ngIf="formAdd.get('defaultvalue')?.hasError('required') && formAdd.get('defaultvalue')?.touched">
            Default value is required
          </mat-error>
        </div>
        <div class="form-group col-lg-6 ">
          <small id="name" class="form-text text-muted input-required">Default Amount</small>
          <input type="text" class="form-control" [value]="cptdetails?.defaultamount??''" id="nametext" aria-describedby="Default value" placeholder="Default Amount" formControlName="defaultamount" (keypress)="allowAmountOnly($event)" (change)="onInputChange($event,0)" />
          <mat-error *ngIf="formAdd.get('defaultamount')?.hasError('required') && formAdd.get('defaultamount')?.touched">
            Default value is required
          </mat-error>
        </div>
<!-- New column-->
<div class="form-group col-lg-6 ">
  <small id="name" class="form-text text-muted input-required">Modifiers</small>
  <input type="text" class="form-control" [value]="cptdetails?.modifiers??''" id="nametext" aria-describedby="Default value" placeholder="Modifiers" formControlName="modifiers" (change)="onInputChange($event,0)" />
  <mat-error *ngIf="formAdd.get('modifiers')?.hasError('required') && formAdd.get('modifiers')?.touched">
    Modifiers is required
  </mat-error>
</div>
<!-- -->
        <div class="form-group col-lg-7">
          <small id="name" class="form-text text-muted input-required" style="width:100%">
            Effective Start Date
            <mat-error style="display: inline-block;" *ngIf="formAdd.get('effectivestartdate')?.hasError('required') && formAdd.get('effectivestartdate')?.touched">
            </mat-error>
          </small>
          <div style="width: 95%;">
            <input type="text" [matDatepicker]="picker" style="width:90%;float: left;" class="form-control" readonly id="nametext" aria-describedby="effectivestartdate" placeholder="MM-dd-YYYY" formControlName="effectivestartdate">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker class="form-group-text" style="float:right;" #picker></mat-datepicker>
          </div>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('effectivestartdate')?.hasError('required') && formAdd.get('effectivestartdate')?.touched">
            Please Select date
          </mat-error>
        </div>

        <div class="form-group col-lg-7">
          <small id="name" class="form-text text-muted" style="width:100%">
            Effective end Date<span style="color:red">*</span>
            <mat-error style="display: inline-block;" *ngIf="formAdd.get('effectiveenddate')?.hasError('required') && formAdd.get('effectiveenddate')?.touched">
            </mat-error>
          </small>
          <div style="width: 95%;">
            <input type="text" [matDatepicker]="picker1" style="width:90%;float: left;" class="form-control" readonly id="nametext" aria-describedby="effectiveenddate" placeholder="MM-dd-YYYY" formControlName="effectiveenddate">
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker class="form-group-text" style="float:right;" #picker1></mat-datepicker>
          </div>
          <mat-error style="display: inline-block;" *ngIf="formAdd.get('effectiveenddate')?.hasError('required') && formAdd.get('effectiveenddate')?.touched">
            Please Select date
          </mat-error>
        </div>
        
        <div class="form-group col-lg-6">
          <div class="form-check form-switch">
            <input class="form-check-input" [checked]="action?.isactive" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="isActive">
            <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
          </div>
        </div>

        <div class="form-group col-lg-6">
          <!--<button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasGroup_Edit">Save Changes</button>-->
          <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="doSave('save')" *ngIf="hasCPT_Edit">Save Changes</button>
          <button mat-flat-button extended class="btn" (click)="doCancelEdit()">Cancel</button>
        </div>
      </div>

    </form>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
