import { Component } from '@angular/core';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent {
  items = ['Item 1', 'Item 2', 'Item 3'];
  drop(event: CdkDragDrop<string[]>) { // handle the drop event
    console.log('Drop event in Component A:', event);
    if (event.previousContainer === event.container) { // Handle moving within the same list
    } else {
      //debugger;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
