import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaturityCurve } from '../maturitycurveinfo';
import * as Custom from '../../../Utility/CustomValidators.utilities';
import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog';
import { Confirmation } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { LookupEntitySelect } from '../../../entities/lookup-entity';
import { MaturityCurveService } from '../maturity-curve.service';
import { PrivilegeValues } from '../../../entities/Privilege';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-maturity-curve-list',
  templateUrl: './maturity-curve-list.component.html',
  styleUrls: ['./maturity-curve-list.component.css']
})
export class MaturityCurveListComponent {
  public filterData: any = {};
  public filterColumn: string = '';
  @ViewChild('menuFilterTrigger') menuFilter!: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  public sortedData = new MatTableDataSource<MaturityCurve>([]);
  displayedColumns: string[] = ['physicianmonthlypercentagerateid', 'practicename', 'effectivestartdate', 'effectiveenddate', 'month1', 'month2', 'month3', 'month4', 'month5', 'month6', 'month7', 'month8', 'month9', 'month10', 'month11', 'month12', 'createdby', 'createdon', 'modifiedby', 'modifiedon'];
  public pageSize = 10;
  public sort!: Sort;
  public _maturityCurveList: MaturityCurve[] = [];
  public hasMaturityCurve_Edit: boolean = false;
  public hasMaturityCurve_Delete: boolean = false;
  @Output() onEditMaturityCurve = new EventEmitter<Number>();
  @Output() onDelete = new EventEmitter<MaturityCurve>();

  ngOnInit() {
    this.setPrivileges();
  }
  @Input() set maturityCurveList(maturityCurveRecords: MaturityCurve[]) {
    this.sortedData.data = maturityCurveRecords;
    this._maturityCurveList = maturityCurveRecords;

    console.log("this.sortedData.data: ", this.sortedData.data);
  }
  @Input() set filtervalue(filtervalue: string) {
    /*this.sortedData.filter = filtervalue.trim().toLowerCase();*/
    this.sortedData.filter = filtervalue?.trim().toLowerCase() ?? '';
  }
  get maturityCurveList() {
    return this._maturityCurveList;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.sortedData.paginator = mp;
  }
  sortData(sort: Sort) {
    this.sort = sort;
    this.sortAndFilterData();

  }
  constructor(private maturityCurveService: MaturityCurveService,
    private dialog: MatDialog, private auth: AuthService) {
    this.sortedData.data = this.maturityCurveList;
  }
  sortAndFilterData() {
    let filteredData = this.maturityCurveList.filter((w: any) => {
      let status = true;
      for (let key in Object.keys(this.filterData)) {
        let column = Object.keys(this.filterData)[key];
        if (!((this.filterData[column] as any[]).findIndex(s => s.code == w[column]?.toString() && s.selected) >= 0 || (this.filterData[column] as any[]).length == 0)) {
          status = false;
          break;
        }
      }
      return status;
    });

    if (!this.sort) {
      this.sortedData.data = filteredData;
      return;
    }
    else if (!this.sort.active || this.sort.direction === '') {
      this.sortedData.data = filteredData;
      return;
    }

    this.sortedData.data = filteredData.sort((a: MaturityCurve, b: MaturityCurve) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        //case 'name':
        //  return Custom.compare(a.clientname.toLowerCase(), b.clientname.toLowerCase(), isAsc);
        //case 'practicemangementname':
        //  return Custom.compare(a.practicemangementname.toLowerCase(), b.practicemangementname.toLowerCase(), isAsc);
        case 'createdBy':
          return Custom.compare(a.createdby, b.createdby, isAsc);
        case 'createdon':
          return Custom.compare(a.createdon, b.createdon, isAsc);
        case 'modifiedBy':
          return Custom.compare(a.modifiedby, b.modifiedby, isAsc);
        case 'modifiedOn':
          return Custom.compare(a.modifiedon, b.modifiedon, isAsc);
        //case 'isActive':
        //  return Custom.compare(a.isactive, b.isactive, isAsc);
        default:
          return 0;
      }

    });

  }

  DateFormate(DateString: string, formateString: string) {
    return Custom.FormatDate(DateString, formateString);
  }
  async doDeleteClient(client: MaturityCurve) {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    data.message = 'Are you sure you want to delete this record?';
    data.title = 'Confirmation!';
    data.showNo = true;
    data.showYes = true;
    data.noText = 'NO';
    data.yesText = 'YES';
    if (await Confirmation(this.dialog, data)) {
      console.log("maturity curve record deleted")
    }
  }
  onFilterApply(items: any[]) {
    if (items[0]?.apply != false) {
      this.filterData[this.filterColumn] = items;
      this.sortAndFilterData();
    }
    this.menuFilter.closeMenu();
  }
  onContextMenu(event: MouseEvent, item: string) {
    this.filterColumn = item;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    let items = this.getFiterData(item);
    this.menuFilter.menuData = { 'item': items };
    //this.contextMenu.menu.focusFirstItem('mouse');
    this.menuFilter.openMenu();
  }
  getFiterData(columnName: string) {
    let data = []
    if (columnName == "isemailverified" || columnName == 'isactive') {
      data = Custom.booleanLookups(this.filterData[columnName]);
    } else {

      data = this.maturityCurveList
        .map((item: any) => {
          return {
            "description": item[columnName],
            "code": item[columnName]?.toString(),
            active: true,
            selected: this.filterData[columnName] && (this.filterData[columnName] as any[]).findIndex(w => w.code == item[columnName]?.toString()) >= 0
          }
        }
        )
        .filter(
          (value, index, current_value) => {
            return current_value.findIndex(val => val.code == value.code) == index
          }
        );
    }
    return data as LookupEntitySelect[];
  }

  @Output() onShowDetails = new EventEmitter();
  doShowDetails(item: any) {
    if (this.hasMaturityCurve_Edit) {
      this.onShowDetails.emit(item);
    }
  }
  setPrivileges() {
    this.hasMaturityCurve_Edit = this.auth.hasPrivilege(PrivilegeValues.ClientManage_Edit);
    //this.hasMaturityCurve_Delete = this.auth.hasPrivilege(PrivilegeValues.ClientManage_Delete);
    if (!this.hasMaturityCurve_Edit) {
      let index = this.displayedColumns.indexOf('actions');
      let elementsToRemove = 1;
      this.displayedColumns.splice(index, elementsToRemove);
    }

  }

  doEditMaturityCurve(maturityCurveId: Number) {
    this.onEditMaturityCurve.emit(maturityCurveId);
  }
}

