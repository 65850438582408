import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { LookupEntityBase } from '../../../../entities/lookup-entity';
import { TaskService } from '../../task.service';

@Component({
  selector: 'app-task-action-add',
  templateUrl: './task-action-add.component.html',
  styleUrls: ['./task-action-add.component.css']
})
export class TaskActionAddComponent {

  constructor(private taskService: TaskService) {

  }


  @ViewChild("accordionItemAction") accordionItemAction!: CdkAccordionItem;
  @ViewChild("accordionItemLogs") accordionItemLogs!: CdkAccordionItem;
  public tasks: any[] = [];
  get expanded() {
    return this.accordionItemLogs?.expanded || this.accordionItemAction?.expanded;
  }
  @Output() onExpand = new EventEmitter();
  public activities: any[] = [];
  public users: LookupEntityBase[] = [];
  public tags: any[] = [];
  public actions: LookupEntityBase[] = [];
  @Input() set lookupData(lookupData: any) {
    if (lookupData) {
      this.actions = lookupData.actions
      this.users = lookupData.users
      this.tags = lookupData.myTags;
    }

  }

  doExpand() {
    if (this.expanded)
      this.onExpand.emit('25%');
    else
      this.onExpand.emit('60px');
  }
  public _task: any = null;
  @Output() onRefreshInforamtion = new EventEmitter();
  @Input() set task(_task: any) {
    this._task = _task;
    this.tasks = [_task];
    if (_task?._id)
      this.getTaskLog();
  }
  get task() {
    return this._task;
  }
  onCancelEdit(item: any) {
    if (item) {
      this.getTaskLog();
      this.onRefreshInforamtion.emit(item);
    }
  }
  getTaskLog() {
    this.taskService.getTaskLogs(this._task._id).then(res => {
      this.activities = res;
    });

  }
  public showSystemLog = false;
  onHideSystemLog() {
    
  }
  getUserTime(date: string) {
    var m1 = moment(date).local();
    return m1.fromNow();
  }
}
