import { Component } from '@angular/core';
import { PrivilegeValues } from '../../entities/Privilege';
import { AuthService } from '../../services/auth.service';
import { action } from './action-master';
import { ActionMasterService } from './action-master.service';

@Component({
  selector: 'app-action-master',
  templateUrl: './action-master.component.html',
  styleUrls: ['./action-master.component.css']
})
export class ActionMasterComponent {
  public actionList: action[] = [];
  public action!: action;
  public isLoading: boolean = true;
  public filtervalue: string = "";
  public showEdit: boolean = false
  public hasActionMaster_View: boolean = false;
  public hasActionMaster_Edit: boolean = false;
  public hasActionMaster_Add: boolean = false;

  constructor(
    private actionmasterService: ActionMasterService,
    private auth: AuthService
  ) { }
  ngOnInit() {
    this.setPrivileges();
    this.getActinMasterList();
  }
  getActinMasterList() {   
    const request = {
      id: -1,
      statusid: -1,
      pagesize: 500,
      pagestartrecord: 0
    }
    this.actionmasterService.getActinMasterList([request]).then(data => {
      this.actionList = data;
      this.isLoading = false;     
    });
  }

  onEditAction(action: action) {  
    this.getActionDetails(action._id);
  }
  onCancelEdit(item: any) {    
    this.action = {} as action;
    this.showEdit = false;

    if (item)
      this.getActinMasterList();
  }
  onAddAction() {
    this.action = {} as action;
    this.action._id = "66b34d777f22a71296a85fa6";
    this.action.isactive = 1;
    this.showEdit = true;
  }
  async getActionDetails(id: string, showLoading = true) {    
    console.log(id)
    if (showLoading)
      this.isLoading = true;
    this.actionmasterService.getActionDetails(id).then(res => {
      this.showEdit = true;
      if (res) {
        this.isLoading = false;
        this.action = res[0] as action;
        if (showLoading) {
          this.showEdit = true
        }
      }
    });
  }

  doUpdateEdit() {
    
    this.getActinMasterList();
   // this.getActionDetails(action._id, false);
  }
  doDelete() {
    this.getActinMasterList();
  }
  onShowLoading(_loading: boolean) {
    this.isLoading = _loading;
  }
  applyFilter(event: Event) {    
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtervalue = filterValue.trim().toLowerCase();
    
  }
  async onShowDetails(item: any) {    
    item = this.getActionDetails(item?._id);
    this.action = item;
    this.showEdit = true;
  }

  setPrivileges() {
    this.hasActionMaster_View = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_View);
    this.hasActionMaster_Edit = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Edit);
    this.hasActionMaster_Add = this.auth.hasPrivilege(PrivilegeValues.ActionMaster_Add);

  }



}

