<div class="body-wrapper">
    <mat-card class="main-card" style="min-height:75vh">
        <mat-card-content>
            <mat-card-title>
                <div class="row" style="margin: auto;">
                    <div class="form-group col-lg-2">
                        <h3>
                            Payroll
                        </h3>
                        <h5>Home &gt; Payroll</h5>

                    </div>
                    <div class="form-group col-lg-10" *ngIf="!showEdit">
                        <div class="form-group float-end">
                            <button mat-mini-fab color="primary" style="float:right" (click)="onAddNew()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <div class="form-group col-lg-2" style="float:right">
                            <input matInput class="form-control" (keyup)="applyFilter($event)" placeholder="Search" style="margin-left: -28px; font-size: small;" [value]="filtervalue">
                        </div>
                    </div>
                    <div class="form-group col-lg-10" *ngIf="showEdit">
                        <div class="form-group float-end" fxLayout="row" fxLayoutGap=".5em">
                            <button mat-flat-button extended class="btn" (click)="showEdit=false">Cancel</button>
                            <button mat-flat-button extended class="btn" (click)="onClear()">reset</button>
                            <button mat-flat-button style="margin-right:10px;" class="btn" color="primary" (click)="runFlow()">Save</button>
                        </div>
                    </div>

                </div>

            </mat-card-title>
            <mat-divider></mat-divider>
            <app-payroll-details *ngIf="showEdit" [lookupData]="lookupData" [payroll]="payroll" #payrollDetails></app-payroll-details>
            <app-payroll-list *ngIf="!showEdit" [filtervalue]="filtervalue" [payrollList]="payrollList" (onEditpayroll)="onEditpayroll($event)"></app-payroll-list>
        </mat-card-content>
    </mat-card>
</div>
<app-data-loader [isLoading]="_isLoading"></app-data-loader>
